import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    unit: "Unit 9",
    id: "SW9-2024-U9-P69-E1",
    audio: "Audios/Page69/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='111' src='Audios/Page69/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page69/E1/1.jpg" }],
      [
        {
          url: "img/FriendsPlus/Page69/E1/2.jpg",
          audioUrl: "Audios/Page69/flute.mp3",
        },
        {
          url: "img/FriendsPlus/Page69/E1/3.jpg",
          audioUrl: "Audios/Page69/you.mp3",
        },
        {
          url: "img/FriendsPlus/Page69/E1/4.jpg",
          audioUrl: "Audios/Page69/foot.mp3",
        },
        {
          url: "img/FriendsPlus/Page69/E1/5.jpg",
          audioUrl: "Audios/Page69/new.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 9",
    id: "SW9-2024-U9-P69-E2",
    audio: "Audios/Page69/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='112' src='Audios/Page69/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page69/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 9",
    id: "SW9-2024-U9-P69-E3",
    audio: "",
    video: "",
    isHiddenCheck: true,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page69/E3/Key/answerKey.png",
    component: T6,
    maxLength: 9,
    inputSize: 122,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the chant again. Say the words with u_e, ew, ou and oo. Write. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='height:12cm'  src='img/FriendsPlus/Page69/E2/1.jpg'/>
        <div style='text-align:center'>#,#,#,#,#<br>#,#,#,#,#</div>
        
        `,
        answer: [
          "look",
          "June",
          "few",
          "books",
          "new",
          "flute",
          "would",
          "you",
          "balloons",
          "fruit",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 9",
    id: "SW9-2024-U9-P69-E4",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page69/E4/Key/answerKey.png",
    stylesTextInput: { borderBottom: "dotted" },
    inputSize: 700,
    maxLength: 200,
    checkUppercase: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Look, match and say. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "34px",
              left: "67px",
              width: "70px",
              height: "50px",
              borderRadius: "10px",
              // border: "1px solid #2eb6e1",
              background: "none",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "54px",
              left: "346px",
              width: "70px",
              height: "50px",
              borderRadius: "10px",
              // border: "1px solid #2eb6e1",
              background: "none",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "149px",
              left: "430px",
              width: "70px",
              height: "50px",
              borderRadius: "10px",
              // border: "1px solid #2eb6e1",
              background: "none",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "600px",
              width: "70px",
              height: "50px",
              borderRadius: "10px",
              // border: "1px solid #2eb6e1",
              background: "none",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "217px",
              left: "77px",
              width: "70px",
              height: "50px",
              borderRadius: "10px",
              // border: "1px solid #2eb6e1",
              background: "none",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "258px",
              left: "351px",
              width: "70px",
              height: "50px",
              borderRadius: "10px",
              // border: "1px solid #2eb6e1",
              background: "none",
            },
          }, // 5
        ],
        answers: ["0-3", "1-2", "4-5"],
        initialValue: [],
      },
      Layout: `
        <div>
          <img src='img/FriendsPlus/Page69/E4/1.jpg'>
          <input id='0' type= 'boxMatch' />    
          <input id='1' type= 'boxMatch' />    
          <input id='2' type= 'boxMatch' />    
          <input id='3' type= 'boxMatch' />    
          <input id='4' type= 'boxMatch' />    
          <input id='5' type= 'boxMatch' />    
        </div>
      `,
    },
  },
  5: {
    unit: "Unit 9",
    id: "SW9-2024-U9-P69-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page69/E5/1.jpg" }]],
  },
};

export default json;

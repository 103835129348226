import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P54-E1",
    exerciseKey: "/img/FriendsPlus/Page54/key/e1-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 150,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "not chase",
          "<s>describe</s>",
          "help",
          "not leave",
          "tell",
          "drive",
          "make",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `  Read the article. Complete the sentences
        with the names Brittany, Charlie and Elise.

`,
        left: 50,
        star: 1,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          
                <div style='margin-top:20px;'>
                    <span > One day, <b><sup>1</sup></b>
                    <input id=0 />
                    had a serious accident. <br>
                    When <b><sup>2</sup></b>
                    <input id=1 />
                     first arrived on the scene, she did some first aid, and
                    <b><sup>3</sup></b> <input id=2 />  called the emergency services.
                </div>

                <img src='/img/FriendsPlus/Page54/9.jpg' style='width:1000px' />

        </div>
      
        `,
        answer: ["Charlie", "Brittany", "Elise"],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P54-E2",
    exerciseKey: "/img/FriendsPlus/Page54/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 50,
      paddingTop: 10,
      maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "not chase",
          "<s>describe</s>",
          "help",
          "not leave",
          "tell",
          "drive",
          "make",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `  Read the text again. Match paragraphs
        A–F with headings 1–5.

`,
        left: 50,
        star: 2,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                  <span> The importance of first aid  </span> 
                   <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> A </span>
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span> 
                    <span > Brittany does the right thing <input id=0 /> </span>
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 2 </span> 
                    <span > A terrible accident <input id=1 /> </span>
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 3 </span> 
                    <span > A big ‘thank you’ for the girls <input id=2 /> </span>
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 4 </span> 
                    <span > Useful lessons in first aid <input id=3 /> </span>
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 5 </span> 
                    <span > An emergency phone call <input id=4 /> </span>
                </div>

                <img src='/img/FriendsPlus/Page54/9.jpg' style='width:1000px' />


        </div>
      
        `,
        answer: ["D", "C", "F", "B", "E"],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P54-E3",
    exerciseKey: "/img/FriendsPlus/Page54/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 50,
      paddingTop: 10,
      maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "not chase",
          "<s>describe</s>",
          "help",
          "not leave",
          "tell",
          "drive",
          "make",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `  Read the text again. Put the story in the
        correct order. Number the sentences 1–8.

`,
        left: 50,
        star: 2,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;position:relative'>
                <img src='/img/FriendsPlus/Page54/10.jpg' style='width:600px' />
                <div style='position:absolute;top:10px;left:44px'> <input id=0 /> </div>
                <div style='position:absolute;top:153px;left:44px'> <input id=1 /> </div>
                <div style='position:absolute;top:190px;left:44px'> <input id=2 /> </div>
                <div style='position:absolute;top:225px;left:44px'> <input id=3 /> </div>
                <div style='position:absolute;top:262px;left:44px'> <input id=4 /> </div>
                <div style='position:absolute;top:297px;left:44px'> <input id=5 /> </div>
                <div style='position:absolute;top:332px;left:44px'> <input id=6 /> </div>
          


             
                <img src='/img/FriendsPlus/Page54/9.jpg' style='width:1000px' />


        </div>
      
        `,
        answer: ["4", "6", "5", "8", "2", "7", "3"],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P54-E4",
    exerciseKey: "/img/FriendsPlus/Page54/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 1000,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "not chase",
          "<s>describe</s>",
          "help",
          "not leave",
          "tell",
          "drive",
          "make",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `  Answer the questions. Write complete
        sentences.

`,
        left: 50,
        star: 3,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: ",",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                  <span> How old are Brittany, Elise and Charlie?  </span> <br>
                   <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> Brittany is 13, and Elise and Charlie are 11.</span>
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span> 
                    <span > What did the girls learn at school?  </span> <br>
                    <input id=0 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 2 </span> 
                    <span > How was Charlie injured?  </span> <br>
                    <input id=1 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 3 </span> 
                    <span > What injuries did he have?  </span> <br>
                    <input id=2 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 4 </span> 
                    <span > What did the girls get after the accident?  </span> <br>
                    <input id=3 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 5 </span> 
                    <span > Would you like to learn more about first aid?
                    Why / Why not?  </span> <br>
                    <input id=4 />
                </div>

        </div>
      
        `,
        answer: [
          "They learned what to do when a person is badly injured.",
          "He fell off his bike.",
          "He had serious cuts, he was unconscious and there was a lot of blood in his mouth.",
          "They got special awards.",
          "",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P54-E5",
    exerciseKey: "/img/FriendsPlus/Page54/key/e5-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 150,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: ` VOCABULARY PLUS Complete the
        sentences with the words.

`,
        left: 50,
        star: 2,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
       <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                  <span> Don’t go down that big hill on your skateboard. It’s very  </span> 
                   <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> risky </span>
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span> 
                    <span > ‘I thought the end of that story was really silly.’ ‘Yeah, it was   </span>
                    <input id=0 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 2 </span> 
                    <span > ‘Are you  </span> 
                    <input id=1 />
                    <span > about the extreme sports day at school?’ ‘Yes! I’m not   </span> 
                    <input id=2 />
                    of trying the sports.’
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 3 </span> 
                    <span > ‘Look! James Bond is falling down the mountain.’ ‘Yes, but I think he’s still  </span>
                    <input id=3 />
                    <span > ! It doesn’t look like a  </span>
                    <input id=4 /> accident.’
                </div>

        </div>
      
        `,
        answer: ["ridiculous", "excited", "frightened", "alive", "fatal"],
      },
    ],
  },
};

export default json;

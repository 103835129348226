import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 5",
    id: "SW9-2024-U5-P36-E1",
    exerciseKey: "/img/FriendsPlus/Page36/key/e1-key.png",
    audio: "/Audios/1.12.mp3",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=12 colorImg=#f0ab9c></headphone> Read and listen to the online article.
What do you think these words mean? Choose the
correct answers.
`,
        left: -50,
        star: 1,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          // fontSize: 19,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "person&nbspwho&nbspvisits&nbspa&nbspfestival / organiser&nbspof&nbspa&nbspfestival",
          "recycled / made&nbspfrom&nbspliving&nbspthings",
          "expensive / cheap",
          "try&nbspto&nbspfind / plan&nbspto&nbspuse",
        ],
        answers: ["0-0", "1-4", "2-4", "3-0"],
        initialValue: [],
      },
      Layout: `
       <div style='margin-top:20px;position:relative'>
          put away – <b>throw away</b> / <span style='border: 2px solid rgb(0, 174, 239); font-weight: bold; border-radius: 50%' > <b>put in its usual place</b> </span>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 1 </span>
            <span > festival goer – </span>
            <input id=0 type=Circle /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 2 </span>
            <span > organic – </span>
            <input id=1 type=Circle /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 3 </span>
            <span > low-cost – </span>
            <input id=2 type=Circle /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 4 </span>
            <span > look out for – </span>
            <input id=3 type=Circle /> 
          </div>
          <img src='/img/FriendsPlus/Page36/9.jpg' style='width:600px;margin-left:200px;margin-top:40px' />
       </div>
      `,
    },
  },
  2: {
    unit: "Unit 5",
    id: "SW9-2024-U5-P36-E2",
    exerciseKey: "/img/FriendsPlus/Page36/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 145,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "become", "jump", "<s>look</s>", "not fall", "not stay"],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: ` Read the article again. Write true, false or don’t know if
          the article doesn’t mention it.

`,
        left: 50,
        star: 2,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          Abandoned tents at festivals account for over 400 tonnes of rubbish a year in the UK. 
          <span style='border-bottom:1px dotted;font-weight:bold'>true</span>


                  
          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 1 </span>
            <span > Amanda is studying design at university </span>
            <input id=0 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 2 </span>
            <span > Amanda only created one type of tent </span>
            <input id=1 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 3 </span>
            <span > Amanda intends to make all the tents by hand in future. </span>
            <input id=2 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 4 </span>
            <span > Amanda’s university gave her a prize for her invention.</span>
            <input id=3 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 5 </span>
            <span > Amanda plans to make other biodegradable products. </span>
            <input id=4 /> 
          </div>
          <img src='/img/FriendsPlus/Page36/9.jpg' style='width:600px;margin-left:200px;margin-top:40px' />

        </div>
      
        `,
        answer: ["don't know", "false", "false", "true", "don't know"],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SW9-2024-U5-P36-E3",
    exerciseKey: "/img/FriendsPlus/Page36/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 1000,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "become", "jump", "<s>look</s>", "not fall", "not stay"],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: ` Answer the questions. Write complete sentences.

`,
        left: 50,
        star: 3,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: "/",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          How did Amanda know about the tent problem?
          <span style='border-bottom:1px dotted;font-weight:bold'>She worked at festivals in the holidays</span>
                  
          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 1 </span>
            <span > What two reasons does the article give for people leaving their tents behind? </span> <br>
            <input id=0 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 2 </span>
            <span > What was wrong with Amanda’s first design? </span> <br>
            <input id=1 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 3 </span>
            <span > Why are the first Comp-A-Tents expensive? </span> <br>
            <input id=2 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 4 </span>
            <span > Do you think the Comp-A-Tent is a good idea? Why / Why not?</span> <br>
            <input id=3 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 5 </span>
            <span > Do you think many people will buy the Comp-A-Tent? Why / Why not? </span> <br>
            <input id=4 /> 
          </div>

        </div>
      
        `,
        answer: [
          "They don't want to put the tent away and they can buy another cheap tent next year.|They do not want to put the tent away and they can buy another cheap tent next year.",
          "The tent was too heavy to carry.",
          "Because Amanda is making them by hand.",
          "",
          "",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SW9-2024-U5-P36-E4",
    exerciseKey: "/img/FriendsPlus/Page36/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "encourage",
          "equip",
          "investigate",
          "<s>popular</s>",
          "react",
          "similar",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: ` VOCABULARY PLUS Make nouns with -ment, -tion or -ity using these words from the article.
          Then use the nouns to complete the sentences.


`,
        left: 50,
        star: 2,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='margin-top:20px;'>
          The band’s  
          <span style='border-bottom:2px dotted;font-weight:bold'>popularity</span> increased at the festival.
                  
          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 1 </span>
            <span > My tent is nearly the same as yours. Can you see the
            <input id=0 /> ?
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 2 </span>
            <span > She created a type of camping </span> 
            <input id=1 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 3 </span>
            <span > She did a careful </span>
            <input id=2 /> into the type of materials she needed to use.

          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 4 </span>
            <span > People gave her help and </span> 
            <input id=3 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 5 </span>
            <span > We heard an excited </span> 
            <input id=4 /> from the crowd.
          </div>

        </div>
      
        `,
        answer: [
          "similarity",
          "equipment",
          "investigation",
          "encouragement",
          "reaction",
        ],
      },
    ],
  },
};

export default json;

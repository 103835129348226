import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "LFP-Starter Unit 8",
    id: "SW9-2024-LF-U8-P68-E1",
    exerciseKey: "/img/FriendsPlus/Page68/Key/E1answerkey.jpg",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontWeight: "bold",
      width: 250,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#4E3E94",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "herself",
          "himself",
          "itself",
          "<s>myself</s>",
          "myself",
          "ourselves",
          "themselves",
          "yourself",
          "yourselves",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the reported speech sentences with the
correct form of the verbs.`,
        left: 50,
        star: 0,
        color: "#4E3E94",
        colorStar: "#4E3E94",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    questions: [
      {
        title: `
        
        ‘I won’t do it again,’ promised Clara.<br>
        Clara promised that she <u style='text-decoration-style: dotted'>&emsp;wouldn’t do&emsp;</u>. it again.
        <div style='display:flex'>
            <b>1</b> 
            <div style='margin-left:15px;width:22cm'>
              ‘I can run after the robber!’ said Jack.
              Jack said that he # after the robber.
            </div>
          </div>
          <div style='display:flex'>
            <b>2</b> 
            <div style='margin-left:15px;width:22cm'>
              ‘They took all the money,’ explained Maya.
              Maya explained that they # all the money.
            </div>
          </div>
          <div style='display:flex'>
            <b>3</b> 
            <div style='margin-left:15px;width:22cm'>
              ‘They aren’t coming,’ announced Tom.
              Tom announced that they  #.
            </div>
          </div>
          <div style='display:flex'>
            <b>4</b> 
            <div style='margin-left:15px;width:22cm'>
              ‘I must find the criminals,’ said the woman.
              The woman said that she # the criminals.
            </div>
          </div>
          <div style='display:flex'>
            <b>5</b> 
            <div style='margin-left:15px;width:22cm'>
              ‘The burglar hasn’t broken the door,’ said Ann.
              Ann said that the burglar # the door.
            </div>
          </div>
          <div style='display:flex'>
            <b>6</b> 
            <div style='margin-left:15px;width:22cm'>
              ‘I have to catch the vandals,’ said the man.
              The man said that he # the vandals.
            </div>
          </div>
          <div style='display:flex'>
            <b>7</b> 
            <div style='margin-left:15px;width:22cm'>
              ‘I’ll explain about the shoplifting,’ Jason agreed.
              Jason agreed that he # about the shoplifting.
            </div>
          </div>
          <div style='display:flex'>
            <b>8</b> 
            <div style='margin-left:15px;width:22cm'>
              ‘We feel in danger,’ said the boys.
              The boys said that they # in danger.
            </div>
          </div>
          
   
        `,
        answer: [
          "could run",
          "'d taken",
          "weren't coming",
          "had to find",
          "hadn't broken",
          "had to catch",
          "would explain",
          "felt",
        ],
      },
    ],
  },
  2: {
    unit: "LFP-Starter Unit 8",
    id: "SW9-2024-LF-U8-P68-E2",
    exerciseKey: "/img/FriendsPlus/Page68/Key/E2answerkey.jpg",
    audio: "",
    video: "",
    component: Circle_Write,
    // textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 20,
      // fontWeight: "bold",
      width: 800,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#4E3E94",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<s>break</s>", "buy", "join", "lose", "play", "read", "wait"],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the correct form of
<i>say</i> or <i>tell</i>, then choose the correct words.`,
        left: -30,
        width: "27cm",
        // star: 0,
        color: "#4E3E94",
        // colorStar: "#4E3E94",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          textAlign: "center",
          background: "none",
          fontSize: 24,
          // borderBottom: "none",
          borderBottom: "2px dotted",
          width: 100,
          height: 32,
          paddingTop: 2,
        },
        answers: ["told", "told", "said", "said", "told", "told", "said"],
        initialValue: [],
      },
      Circle: {
        // TickRong: true,
        initialWordStyle: {
          // marginLeft: 10,
          padding: "1px 3px",
          border: "2px solid",
          borderRadius: 5,
          // color: "transparent",
          borderColor: "white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 3px",
          // borderRadius: 5,
          borderRadius: "50%",
          color: "black",
          // textDecoration: "line-through",
          border: "2px solid",
          borderColor: "black",
        },
        limitSelect: 1,
        listWords: [
          "’d / ’ve / ’s",
          "had_been / was / were",
          "won’t / ’ll / ’d",
          "’s / ’d / ’ve",
          "’s / were / was",
          "had_to / have_to / must",
          "could / can / ’ll",
          "enjoys / enjoyed / enjoy",
        ],
        answers: ["1-0", "2-8", "3-4", "4-8", "0-0", "5-0", "6-0", "7-4"],
        initialValue: ["0-0"],
      },
      Layout: `
 
      
          
      The thieves <u style='text-decoration-style: dotted'>&emsp;said&emsp;</u> that they <input id=0 type=Circle /> stolen two bags last week.
<div style='display:flex'>
  <b>1</b>
  <div style='margin-left:15px;width:24cm'>
      The policeman # him that there <input id=1 type=Circle /> more crime recently.
  </div>
</div>
<div style='display:flex'>
  <b>2</b>
  <div style='margin-left:15px;width:24cm'>
      I # our neighbours that I <input id=2 type=Circle /> speak to the police about the problem.
  </div>
</div>
<div style='display:flex'>
  <b>3</b>
  <div style='margin-left:15px;width:24cm'>
      Gemma # yesterday that she <input id=3 type=Circle /> lost her new sunglasses.
  </div>
</div>
<div style='display:flex'>
  <b>4</b>
  <div style='margin-left:15px;width:24cm'>
      The detective # that he <input id=4 type=Circle /> working on an important crime last summer.
  </div>
</div>
<div style='display:flex'>
  <b>5</b>
  <div style='margin-left:15px;width:24cm'>
      Dmitry # me that he <input id=5 type=Circle /> complain about the vandalism.
  </div>
</div>
<div style='display:flex'>
  <b>6</b>
  <div style='margin-left:15px;width:24cm'>
      I # my friend, Lale that she <input id=6 type=Circle /> borrow my new fantasy novel.
  </div>
</div>
<div style='display:flex'>
  <b>7</b>
  <div style='margin-left:15px;width:24cm'>
      Arthur Conan Doyle always # that he <input id=7 type=Circle /> writing the Sherlock Holmes novels.
  </div>
</div>
            

  `,
    },
  },
  3: {
    unit: "LFP-Starter Unit 8",
    id: "SW9-2024-LF-U8-P68-E3",
    exerciseKey: "/img/FriendsPlus/Page68/Key/E3answerkey.jpg",
    audio: "",
    video: "",

    stylesTextInput: {
      // textAlign: "center",
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 20,
      // fontWeight: "bold",
      width: 250,
      paddingTop: 10,
      // marginLeft: 20,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#4E3E94",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<s>break</s>", "buy", "join", "lose", "play", "read", "wait"],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Order the words to complete the questions.`,
        left: 50,
        star: 0,
        color: "#4E3E94",
        // colorStar: "#4E3E94",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 100,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["ND", "D"],
    component: T6,
    // hideBtnFooter: true,
    character: "/",
    questions: [
      {
        title: `
Have the burglars gone?
had / the / if / burglars / gone<br>
They asked us <u style='text-decoration-style: dotted'>&emsp;&emsp;&emsp;if the burglars had gone&emsp;&emsp;&emsp;</u>.

        
        <div style='width:24cm'>
        <div style='display:flex'>
            <b>1</b> 
            <div style='margin-left:15px'>
              Do you want any help?
              wanted / if / any / help / we<br>
              Our neighbours asked us<input width=300px />.
            </div>
          </div>
          <div style='display:flex'>
            <b>2</b> 
            <div style='margin-left:15px'>
              Why are you running?
              running / was / I / why<br>
              The man asked me<input width=369px />.
            </div>
          </div>
          <div style='display:flex'>
            <b>3</b> 
            <div style='margin-left:15px'>
              Did you tell the police?
              police / had / the / told / I / if<br>
              She asked me<input width=425px />.
            </div>
          </div>
          <div style='display:flex'>
            <b>4</b> 
            <div style='margin-left:15px'>
              What are you going to do?
              what / were / we / do / to / going<br>
              They asked us<input width=421px />.
            </div>
          </div>
          <div style='display:flex'>
            <b>5</b> 
            <div style='margin-left:15px'>
              Can you look for the camera?
              could / if / look / camera / the / for / I<br>
              Leah asked me<input width=410px />. 
            </div>
          </div>
          </div>

            
      
        
        `,
        answer: [
          "if we wanted any help",
          "why I was running",
          "if I had told the police",
          "what we were going to do",
          "if I could look for the camera",
        ],
      },
    ],
  },
  4: {
    unit: "LFP-Starter Unit 8",
    id: "SW9-2024-LF-U8-P68-E2",
    exerciseKey: "img/FriendsPlus/Page68/Key/E4answerkey.jpg",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences with the correct form of the
verbs in the box.`,
        color: "#4E3E94",
        width: "27cm",
        left: 50,
        // star: 2,
        // colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#4D3C98",
      justifyContent: "center",
      width: "11cm",
      marginBottom: 10,
      marginLeft: 50,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "cook",
          "<s>describe</s>",
          "play",
          "not talk",
          "not wake up",
          "watch",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      // marginLeft: 20,
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 200,
      paddingTop: 10,
      textAlign: "center",
    },
    component: T6,

    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        

        The police asked us <u style='text-decoration-style: dotted'>&emsp;to describe&emsp;</u> the mugger.
        <div style='width:24cm'>
            <b>1</b>&ensp;Lucy suggested that we#tennis later.<br>
            <b>2</b>&ensp;My dad asked me#the dinner.<br>
            <b>3</b>&ensp;The teacher asked us#in the exam.<br>
            <b>4</b>&ensp;Martin suggested that we#a film.<br>
            <b>5</b>&ensp;My mum told me#late.<br>

        </div>
        
        `,
        answer: ["play", "to cook", "not to talk", "watch", "not to wake up"],
      },
    ],
  },
  5: {
    unit: "LFP-Starter Unit 8",
    id: "SW9-2024-LF-U8-P68-E2",
    exerciseKey: "img/FriendsPlus/Page68/Key/E5answerkey.jpg",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "5",
        title: `Choose the correct time conjunction.`,
        color: "#4E3E94",
        width: "27cm",
        left: -30,
        // star: 2,
        // colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#4E3E94",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      marginLeft: 140,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "doesn’t have to",
          "don’t have to have to",
          "must",
          "mustn’t",
          "should",
          "shouldn’t",
        ],
      },
    ],
    textareaStyle: {
      width: 700,
      marginBottom: -24,
      resize: "none",
      paddingTop: 3,
    },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 700,

      paddingTop: 10,
      // textAlign: "center",
    },
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          textAlign: "center",
          background: "none",
          fontSize: 24,
          // borderBottom: "none",
          borderBottom: "2px dotted",
          width: 100,
          height: 32,
          paddingTop: 2,
        },
        answers: ["told", "told", "said", "said", "told", "told", "said"],
        initialValue: [],
      },
      Circle: {
        // TickRong: true,
        initialWordStyle: {
          // marginLeft: 10,
          padding: "1px 3px",
          border: "2px solid",
          borderRadius: 5,
          // color: "transparent",
          borderColor: "white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 3px",
          // borderRadius: 5,
          borderRadius: "50%",
          color: "black",
          // textDecoration: "line-through",
          border: "2px solid",
          borderColor: "black",
        },
        limitSelect: 1,
        listWords: [
          "When / Until",
          "until / as_soon_as",
          "before / as_soon_as",
          "when / as_soon_as",
          "after / until",
          "until / as_soon_as",
          "",
          "",
        ],
        answers: ["0-0", "5-0", "6-0", "7-4", "1-4", "2-4", "3-0", "4-0"],
        initialValue: ["0-0"],
      },
      Layout: `
 
      
      <div style='line-height:47px'>
        <input id=0 type=Circle /> it rains, the flowers in the garden thrive.<br>
        <b>1</b>&ensp;It rained cats and dogs <input id=1 type=Circle /> I got home.<br>
        <b>2</b>&ensp;I'll let you know <input id=2 type=Circle /> I hear back from the boss.<br>
        <b>3</b>&ensp;They were watching a movie <input id=3 type=Circle /> the power went out.<br>
        <b>4</b>&ensp;She always enjoys a cup of tea <input id=4 type=Circle /> having breakfast.<br>
        <b>5</b>&ensp;I didn't realise it was him <input id=5 type=Circle /> he took his hat off.<br>
      </div>    
            

  `,
    },
  },
  6: {
    unit: "LFP-Starter Unit 8",
    id: "SW9-2024-LF-U8-P68-E6",
    exerciseKey: "img/FriendsPlus/Page68/Key/E6answerkey.jpg",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "6",
        title: `Correct the words in bold.`,
        color: "#4E3E94",
        width: "27cm",
        left: 50,
        // star: 2,
        // colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#6fd7e7",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "break",
          "do",
          "finish",
          "live",
          "<s>leave</s>",
          "not find",
          "read",
          "see",
          "not try",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      // marginLeft: 20,
      width: 150,
      paddingTop: 10,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `

        If you walk in a circle <span style='text-decoration:line-through'>across</span> the building, you’ll see the beautiful gardens. <u style='text-decoration-style: dotted'>&emsp;around&emsp;</u><br>
        <b>1</b>&ensp;Open the box and look <b>here</b>. You’ll find an old ring there. #<br>
        <b>2</b>&ensp;The monument is just <b>left</b> the road. It’s directly on the other side from here. #<br>
        <b>3</b>&ensp;If you look <b>down</b> , you can see a tall tower above you. #<br>
        <b>4</b>&ensp;Walk into that room at the end of the long corridor. The coins are displayed <b>here</b>. #<br>
        
        
        
        `,
        answer: ["inside", "across", "up", "there"],
      },
    ],
  },
};

export default json;

import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P22-E1",
    exerciseKey: "/img/FriendsPlus/Page22/key/e1-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px solid",
      width: 180,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#0067b3",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "rip-off",
          "bargain",
          "consumer",
          "retailer",
          "status symbol",
          "product",
          "producer",
          "business",
          "advertisement",
          "marketing",
          "value",
          "price",
          "rent",
          "spend",
          "afford",
          "save",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the words.`,
        left: 50,
        star: 1,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;position:relative'>
                <img src='/img/FriendsPlus/Page22/13.jpg'style='width:800px'/>
                <div style='position:absolute;top:214px;left:448px'><input id=0/></div>
                <div style='position:absolute;top:482px;left:53px'><input id=1/></div>
                <div style='position:absolute;top:480px;left:448px'><input id=2/></div>
                <div style='position:absolute;top:757px;left:53px'><input id=3/></div>
                <div style='position:absolute;top:757px;left:448px'><input id=4/></div>
                <div style='position:absolute;top:1024px;left:31px'><input id=5/></div>
                <div style='position:absolute;top:1024px;left:202px'><input id=6/></div>
                <div style='position:absolute;top:1024px;left:436px'><input id=7/></div>
                <div style='position:absolute;top:1024px;left:637px'><input id=8/></div>



            </div>
      
        
        `,
        answer: [
          "eceipt",
          "eliver",
          "abel",
          "can",
          "helves",
          "pecial",
          "ffer",
          "indow",
          "hopping",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P22-E2",
    exerciseKey: "/img/FriendsPlus/Page22/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px solid",
      width: 180,
      fontSize: 20,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f29046",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "changing rooms",
          "checkout",
          "deliver",
          "exchange",
          "fit",
          "receipt",
          "return",
          "shop assistants",
          "<s>try on</s>",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the notice with the words.`,
        left: 50,
        star: 2,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='margin-top:20px;position:relative'>
          <img src='/img/FriendsPlus/Page22/14.jpg'style='width:600px'/>
          <div style='position:absolute;top:107px;left:98px'><input id=0 /> </div>
          <div style='position:absolute;top:137px;left:94px'><input id=1 /> </div>
          <div style='position:absolute;top:196px;left:82px'><input id=2 /> </div>
          <div style='position:absolute;top:295px;left:143px'><input id=3 /> </div>
          <div style='position:absolute;top:325px;left:218px'><input id=4 /> </div>
          <div style='position:absolute;top:356px;left:87px'><input id=5 /> </div>
          <div style='position:absolute;top:456px;left:134px'><input id=6 /> </div>
          <div style='position:absolute;top:486px;left:237px'><input id=6 /> </div>
        </div>
      
        
        `,
        answer: [
          "changing rooms",
          "fit ",
          "deliver",
          "return",
          "receipt",
          "exchange",
          "checkout",
          "shop assistants",
        ],
      },
    ],
  },
  3: {
    unit: "Unit1",
    id: "SW9-2024-U2-P22-E3",
    exerciseKey: "/img/FriendsPlus/Page22/key/e3-key.png",
    audio: "/Audios/1.06.mp3",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=06 colorimg='#f29046'></headphone> Elliot is shopping when he gets a
phone call from his brother, David. Study the picture
above and choose the correct words in 1–5. Then
listen and check.`,
        left: -50,
        star: 2,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "jacket / shirt / tie",
          "tired / very&nbsphappy / a&nbspbit&nbspannoyed",
          "small / medium / large",
          "a&nbsplot&nbspof / some / no",
          "very&nbspcheap / quite&nbspcheap / quite&nbspexpensive",
        ],
        answers: ["3-8", "0-4", "1-8", "2-0", "4-8"],
        initialValue: [],
      },
      Layout: `
       <div >Elliot is in a  
       <span style="font-weight:bold;">food</span> /
       <span style="font-weight:bold;border:1px solid black; border-radius:50%;padding:5px 10px">clothes</span> /
       <span style="font-weight:bold">sports</span>
        shop.
        </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">1</span>
       <span>David wants Elliot to buy a  <input id=0 type=Circle />.</span>
       </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">2</span>
       <span>Elliot is <input id=1 type=Circle />.</span>
       </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">3</span>
       <span>David’s size is <input id=2 type=Circle />.</span>
       </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">4</span>
       <span>My There are  <input id=3 type=Circle /> special offers.
        </span>
       </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">5</span>
       <span>The clothes in the shop are <input id=4 type=Circle />.
        </span>
       </div>

       
       </div>
      `,
    },
  },
  4: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P22-E4",
    exerciseKey: "/img/FriendsPlus/Page22/key/e4-key.png",
    audio: "/Audios/1.07.mp3",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f29046",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "changing rooms",
          "checkout",
          "deliver",
          "exchange",
          "fit",
          "receipt",
          "return",
          "shop assistants",
          "<s>try on</s>",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=07 colorimg='#f29046'></headphone> Now listen to a later phone
conversation. Complete the notes about the shirt
with two or three words in each space.`,
        left: 50,
        star: 2,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          <p>The company is going 
          <span style='border-bottom:2px dotted black;font-weight:bold'> to deliver it</span>
           between 9.00 and 10.00 tomorrow morning.
          </p>

          <div style='margin-top:10px'>
            <span style="margin-right:10px;font-weight:bold">1</span>
            <span>David can <input id=0 /> at home. 
              </span>
          </div>

          <div style='margin-top:10px'>
            <span style="margin-right:10px;font-weight:bold">2</span>
            <span>If it <input id=1 />, he can <input id=2 />. 
              </span>
          </div>

          <div style='margin-top:10px'>
            <span style="margin-right:10px;font-weight:bold">3</span>
            <span>But he needs to <input id=3 /> on. 
              </span>
          </div>

          <div style='margin-top:10px'>
            <span style="margin-right:10px;font-weight:bold">4</span>
            <span>If necessary, he can <input id=4 />  at <input id=5 /> 
              on the first floor. 
            </span>
          </div>

          <div style='margin-top:10px'>
            <span style="margin-right:10px;font-weight:bold">5</span>
            <span>But he must <input id=6 />
            </span>
          </div>


        </div>
      
        
        `,
        answer: [
          "try it on",
          "doesn't fit|does not fit",
          "return it",
          "leave the label ",
          "exchange it",
          "the checkout",
          "keep the receipt",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P22-E5",
    exerciseKey: "/img/FriendsPlus/Page22/key/e5-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f29046",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "changing rooms",
          "checkout",
          "deliver",
          "exchange",
          "fit",
          "receipt",
          "return",
          "shop assistants",
          "<s>try on</s>",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Answer the questions using some of the
words on this page. Give reasons for your answers.
`,
        left: 50,
        star: 3,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          <p>Do you always try on clothes in the changing rooms before you buy them? <br>
          <span style='border-bottom:2px dotted black;font-weight:bold'>Yes, I always try them on to check that they fit.</span>
          </p>

          <div style='margin-top:10px'>
            <span style="margin-right:10px;font-weight:bold">1</span>
            <span>
              Do you keep the receipt when you buy things?
            </span> <br>
            <textarea rows=2 id=0 ></textarea>
          </div>

          <div style='margin-top:10px'>
            <span style="margin-right:10px;font-weight:bold">2</span>
            <span>
              Do you often go window shopping?
            </span> <br>
            <textarea rows=2 id=1 ></textarea>
          </div>

          <div style='margin-top:10px'>
            <span style="margin-right:10px;font-weight:bold">3</span>
            <span>
              Do you ever ask the shop assistants for help?
            </span> <br>
            <textarea rows=2 id=2 ></textarea>
          </div>

          

        </div>
      
        
        `,
        answer: [
          "Yes, I always keep the receipt in case I want to return or exchange anything",
          "Yes, I often go window shopping to see what I like and then I wait for the special offers.",
          "Yes, I sometimes speak to them if I can't find what I'm looking for on the shelves.",
        ],
      },
    ],
  },
};

export default json;

import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Starter U8",
    id: "SW9-2024-Starter-U8-P59-E1",
    exerciseKey: "",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ` <span style='color:#574d89;font-weight:800;'>Speaking</span>  

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>
                
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;color:rgb(87, 77, 137);' > 1 </span> 
                    <span > Look at the photo. What is her problem?  </span>
                    <img src='/img/FriendsPlus/Page59/13.jpg' style='width:600px;margin-top:20px;margin-left:150px' />
                    <p></p>
                    <textarea rows=5 id=0 ></textarea>
                </div>

        </div>
      
        `,
        answer: [],
      },
    ],
  },
  2: {
    unit: "Starter U8",
    id: "SW9-2024-Starter-U8-P59-E2",
    exerciseKey: "/img/FriendsPlus/Page59/key/e2-key.jpg",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 900,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ` <span style='color:#574d89;font-weight:800;'>Reading</span>  

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>
                
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;color:rgb(87, 77, 137);' > 2 </span> 
                    <span > Read the narrative paragraph. Where did the writer
                    experience something unpleasant?  </span>
                    <input  id=0 ></input>
                    <p></p>
                    <img src='/img/FriendsPlus/Page59/14.jpg' style='width:600px;margin-top:20px;margin-left:150px' />
                </div>

        </div>
      
        `,
        answer: ["At the supermarket"],
      },
    ],
  },
  3: {
    unit: "Starter U8",
    id: "SW9-2024-Starter-U8-P59-E3",
    exerciseKey: "/img/FriendsPlus/Page59/key/e3-key.jpg",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 20,
      fontWeight: "bold",
      width: 1000,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: ` Read the paragraph again and write short answers
        to the following questions.

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                    <img src='/img/FriendsPlus/Page59/14.jpg' style='width:600px;margin-top:20px;margin-left:200px' /> <p></p>
                </div>
                <p></p>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>1</span>
                    <span> Why did the writer feel embarrassed? </span>
                    <input id=0  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>2</span>
                    <span> What did she do to deal with the problem? </span>
                    <input id=1  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>3</span>
                    <span> What suddenly happened when she rushed back home? </span>
                    <input id=2  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>4</span>
                    <span> Did she learn anything from the unpleasant experience at the supermarket? </span>
                    <input id=3  />
                </div>

            
        </div>
      
        `,
        answer: [
          "Because she forgot her purse.",
          "She left all her groceries at the counter and rushed back home to get her purse.",
          "It rained heavily.",
          "She learned that she should double-check that she has everything she needs before leaving the house.",
        ],
      },
    ],
  },
  4: {
    unit: "Starter U8",
    id: "SW9-2024-Starter-U8-P59-E4",
    exerciseKey: "/img/FriendsPlus/Page59/key/e4-key.jpg",
    audio: "/Audios/1.21.mp3",
    video: "",
    component: T6,
    // textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 800,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: `<span style='color:#574d89;font-weight:800;'>Listening</span> <br>
        <span style='font-size:30px'>4</span> <headphone name=21 colorImg=#71699c ></headphone> Listen to the local radio news. What does
        the local council want to ban? How many people does the reporter talk to?

`,
        left: 50,
        star: 0,
        width: "27cm",
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>
                <input id=0 />
        </div>
      
        `,
        answer: ["Skateboarders in the city centre, two"],
      },
    ],
  },
  5: {
    unit: "Starter U8",
    id: "SW9-2024-Starter-U8-P59-E5",
    exerciseKey: "/img/FriendsPlus/Page59/key/e5-key.jpg",
    audio: "/Audios/1.21.mp3",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 170,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: ` <headphone name=21 colorImg=#71699c ></headphone> Listen again. Correct one word in each sentence.

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>1</span>
                    <span> Offenders could have to pay up to £200.  </span> 
                    <input id=0  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>2</span>
                    <span> A small skatepark was opened last year </span> 
                    <input id=1  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>3</span>
                    <span> Daniella spoke to people on the buses. </span> 
                    <input id=2  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>4</span>
                    <span> The man is worried about skateboarding.  </span> 
                    <input id=3  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>5</span>
                    <span> The man says skateboarding is good fun.  </span> 
                    <input id=4  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>6</span>
                    <span> The woman thinks skateboarders are exciting. </span> 
                    <input id=5  />
                </div>

            
        </div>
      
        `,
        answer: ["100", "large", "streets", "isn't", "exercise", "annoying"],
      },
    ],
  },
  6: {
    unit: "Starter U8",
    id: "SW9-2024-Starter-U8-P59-E6",
    exerciseKey: "",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ` <span style='color:#574d89;font-weight:800;'>Writing</span>  

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#574d89",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>

                <b style='font-size:30px'>6</b>&ensp;<span style='margin-right:10px;font-weight:bold;' > A TASK Write a paragraph about your unpleasant
                experience in life. 
                 </span> <br>
                 <textarea rows=5 id=0 ></textarea>
        
                <span style='margin-right:10px;font-weight:bold;' > B THINK AND PLAN </span> <br> 
                <ul>
                  <li>When and where did you experience something unpleasant?</li>
                  <textarea rows=2 id=1 ></textarea>

                  <li>Why was it annoying or displeasing?</li>
                  <textarea rows=2 id=2 ></textarea>

                  <li>What lesson can you learn from that frustrating experience?</li>
                  <textarea rows=2 id=3 ></textarea>

                </ul>

                <span style='margin-right:10px;font-weight:bold;' > C WRITE Use the text in exercise 2 and the
                writing guide. </span> <br>
                <img src='/img/FriendsPlus/Page59/15.jpg' style='width:600px;margin-left:200px;margin-top:40px' />
                <br><br>
                <textarea rows=6 id=4 ></textarea>

        </div>
      
        `,
        answer: [],
      },
    ],
  },
};

export default json;

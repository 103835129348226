import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P27-E1",
    exerciseKey: "/img/FriendsPlus/Page27/key/e1-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct words.`,
        left: -50,
        star: 1,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "must / might",
          "must / may",
          "could / must",
          "can't / might",
          "might_not / could",
          "may_not / can’t",
        ],
        answers: ["1-4", "2-0", "3-4", "4-0", "5-4"],
        initialValue: [],
      },
      Layout: `
       <div style='margin-top:20px;position:relative'> 
          <img src='/img/FriendsPlus/Page27/9.jpg' style='height:16cm'/>

          <div style='position:absolute;top:66px;left:36px;line-height:40px;font-size:24px;width:171mm'> 
              <b>Definitely</b><br>
              Experts think that animals <span style="border: 2px solid rgb(0, 174, 239); font-weight: bold; border-radius: 50%;"><span>must</span></span> / <span style="border: 2px solid transparent; font-weight: bold;"><span>might</span></span> have feelings.<br>
              <b>Possibly</b><br>
              Scientists think that elephants <sup>1</sup><input id=1 type=Circle /> express sadness.<br>
              Animal emotions <sup>2</sup><input id=2 type=Circle /> be an interesting topic to study.<br>
              Mice <sup>3</sup><input id=3 type=Circle /> be more intelligent than we thought.<br>
              <b>Possibly not</b><br>
              We <sup>4</sup><input id=4 type=Circle /> understand everything yet.<br>
              <b>Definitely not</b><br>
              They feel sure that the evidence <sup>5</sup><input id=5 type=Circle /> be true<br>
              </div>
        </div>
      `,
    },
  },
  2: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P27-E2",
    exerciseKey: "/img/FriendsPlus/Page27/key/e2-key.png",
    audio: "",
    video: "",
    component: Circle_Write,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Correct the mistakes in bold. Tick the
sentence that is correct.
`,
        left: -30,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: "|",
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          textAlign: "center",
          background: "none",
          fontSize: 24,
          // borderBottom: "none",
          borderBottom: "2px dotted",
          width: 200,
          paddingTop: 10,
        },
        answers: [
          "mightn't finish|might not finish",
          "",
          "can't be|cannot be",
          "must be",
          "might learn",
        ],
        initialValue: [],
      },
      Circle: {
        TickRong: true,
        initialWordStyle: {
          marginLeft: 10,
          padding: "1px 10px",
          border: "2px solid",
          borderRadius: 5,
          color: "transparent",
          borderColor: "black",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          borderRadius: 5,
          // borderRadius: "50%",
          color: "black",
          // textDecoration: "line-through",
          border: "2px solid",
          borderColor: "black",
        },
        limitSelect: 1,
        listWords: ["✓", "✓", "✓", "✓", "✓", "✓", "✓"],
        answers: ["1-0", "6-0"],
        initialValue: [],
      },
      Layout: `

            <div style='line-height:45px;width:27cm'>
              <b>1</b>&ensp;We <b>not might finish</b> our project on whales today. # <input id=0 type=Circle />  <br>
              <b>2</b>&ensp;Those zoo animals don’t move around much. They <b>could feel</b> <br>bored. # <input id=1 type=Circle />  <br>
              <b>3</b>&ensp;Our cat has just eaten. It <b>don’t can be</b> hungry again. # <input id=2 type=Circle />  <br>
              <b>4</b>&ensp;Studies suggests that dolphins <b>must to be</b> very intelligent. # <input id=3 type=Circle />  <br>
              <b>5</b>&ensp;They <b>might learning</b> more about these animals in the future. # <input id=4 type=Circle />  <br>
            </div>

  `,
    },
  },
  3: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P27-E3",
    exerciseKey: "/img/FriendsPlus/Page27/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: ` Complete the replies with may, must,
        might not or can’t and the verb in brackets.
`,
        left: 50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          <p>‘Do you know what that animal is over there?’
              ‘Well, it
          <span style='border-bottom:2px dotted black;font-weight:bold'>
            can’t be
          </span>
          <span> (be) a horse. It’s too small.’</span>
          
          </p>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">1</span>
            
            <span> 
             ‘Ollie seems stressed.’ ‘I think he 
            </span> 
            <input  id=0 ></input> (feel) worried about his driving test.’
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">2</span>
            <span> 
              ‘Are you coming to the beach later?’ ‘I’m not
              sure. I
            </span> 
            <input  id=1 ></input> (come) if I’m tired.
            
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">3</span>
            <span>
              ‘That study was very successful.’ ‘Yes. The
              scientists
            </span> 
            <input  id=2 ></input> (be) very pleased.’
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">4</span>
            <span>
              ‘Why is Marta excited?’ ‘I don’t know. She 
            </span> 
            <input  id=3 ></input>  (have) tickets for that concert.<br>
            She was trying to buy some online.
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">5</span>
            <span>
              ‘Is it time to get up?’ ‘No, it’s still dark outside. It
            </span> 
            <input  id=4 ></input> (be) morning yet.’
          </div>

        </div>
      
        
        `,
        answer: [
          "may feel",
          "might not come",
          "must be",
          "may have",
          "can't be",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P27-E4",
    exerciseKey: "/img/FriendsPlus/Page27/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      marginLeft: 10,
      width: 400,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `  Rewrite the underlined sentences with
        might, might not, must or can’t.
`,
        left: 50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          <p>Bradley looks relaxed.
          <span style='border-bottom:2px solid black;'>He definitely isn’t nervous</span> <br>
          <span style='border-bottom:2px dotted black;font-weight:bold'>
            He can’t be nervous.
          </span>
          
          
          </p>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">1</span>
            
            <span> 
             That giraffe seems unhappy 
            </span>
            <span style='border-bottom:2px solid black;'> It’s possible that it
            feels sad. </span> <br>
            <input  id=0 ></input> 
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">2</span>
            <span> 
               Ava called me.
            </span>
            <span style='border-bottom:2px solid black;'> Perhaps she won’t meet us later.</span> <br>
            <input  id=1 ></input>
            
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">3</span>
            <span>
              That perfume is a luxury brand.
            </span>
            <span style='border-bottom:2px solid black;'>  I’m sure it’s very
            expensive.</span> <br>
            <input  id=2 ></input> 
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">4</span>
            <span>
              The sky is grey.
            </span>
            <span style='border-bottom:2px solid black;'> Maybe it will rain later.</span> <br>
            <input  id=3 ></input>  
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">5</span>
            <span>
             Look at the price label!
            </span>
            <span style='border-bottom:2px solid black;'>I’m sure it doesn’t cost
            that much. </span> <br>
            <input  id=4 ></input> 
          </div>

        </div>
      
        
        `,
        answer: [
          "It might feel sad",
          "She might not meet us later",
          "It must be very expensive",
          "It might rain later",
          "It can't cost that much",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P27-E5",
    exerciseKey: "/img/FriendsPlus/Page27/key/e5-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 660,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 660,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["annoyed", "excited", "frightened", "surprised"],
      },
      {
        src: ["bored", "happy / relaxed", "stressed", "worried"],
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Write sentences about photos A and B. Use
        may, might, could, can’t and must and the words in
        the box or your own ideas.

`,
        left: 50,
        star: 3,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
         <img src='/img/FriendsPlus/Page27/10.jpg' style='width:600px'/>
          <hintbox id=0></hintbox>
          <br>
          <span style='border-bottom:1px dotted black; font-weight:bold'>The woman might be surprised by the horse jumping up.</span>
          <p></p>
          <input id=0/> <br>
          <input id=1/> <br>
          <input id=2/> <br> <br>
          

          <img src='/img/FriendsPlus/Page27/11.jpg' style='width:600px'/>
          <hintbox id=1></hintbox>
        <br>
          <span style='border-bottom:1px dotted black; font-weight:bold'>She could be bored of studying.</span>
          <p></p>
          <input id=3/> <br>
          <input id=4/> <br>
          <input id=5/> <br>
          
        </div>
      
        
        `,
        answer: [
          "She could be very frightened.",
          "The horse must be excited about going in the field.",
          "She may be scared of the horse.",
          "She can't feel happy and relaxed.",
          "She may feel stressed about revising.",
          "She might be worried about her exams.",
        ],
      },
    ],
  },
};

export default json;

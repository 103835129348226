import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P40-E1",
    exerciseKey: "/img/FriendsPlus/Page40/key/e1-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: ` Choose the correct words.
`,
        left: -50,
        star: 1,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          // fontSize: 19,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "rewarding / reward",
          " adventurous / adventure",
          "responsible / responsibility",
          "sociable / socialise",
          "independent / independence",
          "easy-going / hard-working.",
        ],
        answers: ["0-0", "1-2", "2-4", "3-4", "4-0", "5-4"],
        initialValue: [],
      },
      Layout: `
       <div style='margin-top:20px;position:relative'>
         Do you want the   
         <span> <b>challenging / <span style='border:1px solid;border-radius:50%;'>challenge</span> </b> </span>
          of climbing Ben Nevis in Scotland? It’s 1,345 metres high
          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 1 </span>
            <span > Mark liked volunteering. He thought it was a very  </span>
            <input id=0 type=Circle />  thing to do.
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 2 </span>
            <span > Jana and Eva are interested in the mountain trek. They’re very </span>
            <input id=1 type=Circle /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 3 </span>
            <span > If you like </span>
            <input id=2 type=Circle />
            , you can be a group leader at the summer camp.
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 4 </span>
            <span > If you want to </span>
            <input id=3 type=Circle />
             with other teenagers, this is the programme for you.
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 5 </span>
            <span > You can become more </span>
            <input id=4 type=Circle />
            by learning to read maps.
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 6 </span>
            <span > You get up at 6 a.m. to help cook breakfast, so you need to be </span>
            <input id=5 type=Circle /> 
          </div>



       </div>
      `,
    },
  },
  2: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P40-E2",
    exerciseKey: "/img/FriendsPlus/Page40/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      // fontSize: 22,
      width: 180,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f29046",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "benefits",
          "challenging",
          "<s>confidence</s>",
          "easy-going",
          "independence",
          "reward",
          "sociable",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `   Complete the blog post with the words.


`,
        left: 50,
        star: 2,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-left:100px'> <hintbox id=0 ></hintbox>  </div>
        
           <div style='margin-top:20px;position:relative'>
              <img src='/img/FriendsPlus/Page40/11.jpg' style='width:600px;margin-left:100px' />
              <div style='position:absolute;top:332px;left:359px'> <input id=0 /> </div>
              <div style='position:absolute;top:364px;left:431px'> <input id=1 /> </div>
              <div style='position:absolute;top:427px;left:316px'> <input id=2 /> </div>
              <div style='position:absolute;top:533px;left:384px'> <input id=3 /> </div>
              <div style='position:absolute;top:564px;left:247px'> <input id=4 /> </div>
              <div style='position:absolute;top:595px;left:169px'> <input id=5 /> </div>

        </div>
      
        `,
        answer: [
          "challenging",
          "independence",
          "reward",
          "sociable",
          "easy-going",
          "benefits",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P40-E3",
    exerciseKey: "/img/FriendsPlus/Page40/key/e3-key.png",
    audio: "/Audios/1.13.mp3",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 22,
      width: 75,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f29046",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "benefits",
          "challenging",
          "<s>confidence</s>",
          "easy-going",
          "independence",
          "reward",
          "sociable",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: ` <headphone name=13 colorImg=#f29046></headphone>   Listen to the radio programme
and write true or false


`,
        left: 50,
        star: 2,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          There are three people talking on the radio programme.
          <span style='border-bottom:2px dotted;'><b>false</b></span>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 1 </span>
            <span> The first person to speak is the presenter. </span>
            <input id=0 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 2 </span>
            <span> He interviews four students </span>
            <input id=1 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 3 </span>
            <span> Marie Bell works in motorsports </span>
            <input id=2 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 4 </span>
            <span> The students talk about work experience. </span>
            <input id=3 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 5 </span>
            <span> The students give some advice </span>
            <input id=4 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 6 </span>
            <span> The last people to speak are the students </span>
            <input id=5 /> 
          </div>

          

          
        </div>
      
        `,
        answer: ["true", "false", "false", "true", "true", "false"],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P40-E4",
    exerciseKey: "/img/FriendsPlus/Page40/key/e4-key.png",
    audio: "/Audios/1.13.mp3",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 22,
      width: 170,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f29046",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "benefits",
          "challenging",
          "<s>confidence</s>",
          "easy-going",
          "independence",
          "reward",
          "sociable",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: ` <headphone name=13 colorImg=#f29046></headphone>   Complete the sentences with
        adjectives from the radio programme. Listen again and check your answers.

`,
        left: 50,
        star: 2,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          Marie Bell is a person who’s
          <span style='border-bottom:2px dotted;'>&emsp;<b>responsible</b>&emsp;</span>
          for work experience in the area.

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 1 </span>
            <span> Emma thought the course was a </span>
            <input id=0 />
            experience, but <input id=1 />  , too
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 2 </span>
            <span> Being good at maths is </span>
            <input id=2 />
            if you want a place on the course.
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 3 </span>
            <span> All the students on the course have to be </span>
            <input id=3 />
             with numbers.
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 4 </span>
            <span> The programme isn’t for couch potatoes. You need to be </span>
            <input id=4 />
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 5 </span>
            <span> One thing Chris liked about the programme was that it was a very </span>
            <input id=5 /> experience.
          </div>

        </div>
      
        `,
        answer: [
          "challenging",
          "rewarding",
          "beneficial",
          "confident",
          "hard-working",
          "sociable",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P40-E5",
    exerciseKey: "/img/FriendsPlus/Page40/key/e5-key.jpg",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 22,
      width: 170,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f29046",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a fashion company",
          "a football club",
          "a large hospital",
          "the NASA space centre",
          "a TV station",
          "a website design company",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `  What would be your ideal work experience? Write a short paragraph using
            some of the words on this page. Use an idea from the box or one of your own
        
.

`,
        left: 50,
        star: 3,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    KeyMau: true,
    isHello: true,
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='margin-top:20px;'>
          
          <span style='border-bottom:2px dotted;'><b>I’d like to do work experience at a zoo. I think that
          looking after animals would be very rewarding. Working with wild animals can be a challenge, but there would be many benefits. I’d become more confident …</b></span>
          <br><br>
        <textarea rows=10 id=0 ></textarea>
        </div>
      
        `,
        answer: [
          "I'd like to do work experience at a fashion company. I think it'd be a very sociable place. It'd be challenging and rewarding to think of new ideas and show that I'm hard-working and responsible.",
        ],
      },
    ],
  },
};

export default json;

import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P38-E1",
    exerciseKey: "/img/FriendsPlus/Page38/key/e1-key.png",
    audio: "",
    video: "",
    component: Circle_Write,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 175,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#00aa4c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<s>against</s>",
          "because",
          "believe",
          "the first place",
          "if",
          "opinion",
          "summary",
          "unless",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `   Find eight more words or phrases for types of people in the wordsearch.

`,
        left: -30,
        star: 1,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: true,
        colorLineTo: "#40c7f4",
        newPointDot: true,
        // notOverlapse: true,
        circlePadding: "0 0",
        // margin: "0 0 0 10px",
        padding: "4px 0px 4px 15px",
        border: "1px solid #40c7f4",
        // transform: "translate(50%)",
        circleStringArray: [
          "CNINTROVERT",
          "OAFVTNVZIPE",
          "UTOZUFUMOEA",
          "CULKQBDWTSM",
          "HRLRIPNZOSP",
          "PEOLEADERIL",
          "OLWQWOKLIMA",
          "TOETZHEZLIY",
          "AVRSWFKLDSE",
          "TEOPTIMISTR",
          "OREXTROVERT",
        ],
        boxMatch: [],
        answers: [
          "0_0-10_0",
          "0_1-10_1",
          "0_2-0_10",
          "5_3-5_8",
          "1_9-9_9",
          "0_10-9_10",
          "10_2-10_10",
          "1_2-9_2",
        ],
        initialValue: ["0_2-0_10"],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
          height: 34,
        },
        answers: ["nature lover", "couch potato"],
        initialValue: [],
      },
      Circle: {
        isHiddenCheck: true,
        newSplit: true,
        initialWordStyle: {
          border: "2px solid",
          borderColor: "transparent",
          padding: "1px 5px",
          borderRadius: "50%",
          fontSize: 20,
        },
        selectWordStyle: {
          borderColor: "#40c7f4",
        },
        limitSelect: 1,
        listWords: [
          "soda / ice_tea",
          "mushroom / meat",
          "lemonade / melon",
          "onion / melon",
          "onion / meat",
          "lemonade / ice_tea",
          "cucumber / lemonade",
          "onion / mushrooms",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
  
      <div style='display:flex'>
            <div id='circle-container' style='position: relative; width: 14cm;  display: grid; grid-template-columns: repeat(11, 1fr)'>
                  <input id=0 type='boxMatch'/>
                  <input id=1 type='boxMatch'/>
                  <input id=2 type='boxMatch'/>
                  <input id=3 type='boxMatch'/>
                  <input id=4 type='boxMatch'/>
                  <input id=5 type='boxMatch'/>
                  <input id=6 type='boxMatch'/>
                  <input id=7 type='boxMatch'/>
                  <input id=8 type='boxMatch'/>
                  <input id=9 type='boxMatch'/>
                  <input id=10 type='boxMatch'/>
                </div>
              <div style=';margin-left:20px'>
                <b>Which type of person from the wordsearch …</b><br>
                    <b>1</b>&ensp;likes animals and plants?#<br>
                    <b>2</b>&ensp;sits and watches TV a lot?#<br>
              </div>
          </div>
        
      `,
    },
  },
  2: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P38-E2",
    exerciseKey: "/img/FriendsPlus/Page38/key/e2-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: ` Choose the correct words.
`,
        left: -50,
        star: 2,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          // fontSize: 19,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "introvert / optimist / extrovert",
          "follower / rebel / pessimist",
          "loner / realist / team player",
          "leader / follower / dreamer",
          "follower / leader / realist",
          "conformist / dreamer / nature&nbsplover",
        ],
        answers: ["0-8", "1-4", "2-0", "3-0", "4-8", "5-4"],
        initialValue: [],
      },
      Layout: `
       <div style='margin-top:20px;position:relative'>
         ‘Do you usually follow rules?’ ‘Yes, I’m definitely a 
         <span  > <b>rebel / <span style='border:1px solid;border-radius:50%;'>conformist</span> / dreamer.’</b> </span>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 1 </span>
            <span > You love talking to people at parties. You’re an </span>
            <input id=0 type=Circle /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 2 </span>
            <span > Deniz hates rules and always fights against things. He’s a </span>
            <input id=1 type=Circle /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 3 </span>
            <span > Natalie doesn’t like being in groups or crowds. She’s a </span>
            <input id=2 type=Circle /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 4 </span>
            <span > Sasha likes telling people what to do. He’s more of a </span>
            <input id=3 type=Circle /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 5 </span>
            <span > I don’t like fantasy stories or science fiction. I’m a bit of a </span>
            <input id=4 type=Circle /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 6 </span>
            <span > Daisy has lots of ideas, but they’re not practical! She’s a </span>
            <input id=5 type=Circle /> 
          </div>



       </div>
      `,
    },
  },
  3: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P38-E3",
    exerciseKey: "/img/FriendsPlus/Page38/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      width: 175,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#00aa4c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<s>against</s>",
          "because",
          "believe",
          "the first place",
          "if",
          "opinion",
          "summary",
          "unless",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `  Complete the personality descriptions
                  with words and phrases from exercise 1.


`,
        left: 50,
        star: 2,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;position:relative'>
          <img src='/img/FriendsPlus/Page38/10.jpg' style='width:600px;margin-left:200px' />
          <div style='position:absolute;top:431px;left:207px'> <input id=0 /> </div>
          <div style='position:absolute;top:463px;left:255px'> <input id=1 /> </div>
          <div style='position:absolute;top:493px;left:520px'> <input id=2 /> </div>
          <div style='position:absolute;top:555px;left:254px'> <input id=3 /> </div>
          <div style='position:absolute;top:730px;left:347px'> <input id=4 /> </div>
          <div style='position:absolute;top:762px;left:347px'> <input id=5 /> </div>
          <div style='position:absolute;top:824px;left:210px'> <input id=6 /> </div>
          <div style='position:absolute;top:854px;left:537px'> <input id=7 /> </div>
          


        </div>
      
        `,
        answer: [
          "extrovert",
          "team player",
          "pessimist",
          "optimist",
          "leader",
          "follower",
          "nature lover",
          "couch potato",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P38-E4",
    exerciseKey: "/img/FriendsPlus/Page38/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 900,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#00aa4c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<s>against</s>",
          "because",
          "believe",
          "the first place",
          "if",
          "opinion",
          "summary",
          "unless",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `  Answer the questions using some of the
                  words on this page. Give reasons or examples.


`,
        left: 50,
        star: 3,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    KeyMau: true,
    isHello: true,
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          Do you prefer to be on your own or in a group? <br >
          <span style='border-bottom:2px dotted;'><b>I prefer to be in a group of friends most of the time.
          I’m more of a team player than a loner</b></span>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 1 </span>
            <span > What type of person are you? <br>
            <input id=0 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 2 </span>
            <span > Has your personality changed over time?  </span> <br>
            <input id=1 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 3 </span>
            <span > What type of person is your best friend? </span> <br>
            <input id=2 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 4 </span>
            <span > Who is your favourite celebrity? What type of personality do you think he / she has? </span> <br>
            <input id=3 /> 
          </div>

        </div>
      
        `,
        answer: [
          "I like to be positive and look on the bright side so I'm definitely an optimist|I like to be positive and look on the bright side so I am definitely an optimist",
          "Yes, I used to be an introvert as a child, but I'm more of an extrovert now.",
          "He's a natural leader because people often listen to his ideas or opinions.",
          "I like Taylor Swift. I think she's a bit of a rebel because she's in the news a lot.|I like Taylor Swift. I think she is a bit of a rebel because she is in the news a lot.",
        ],
      },
    ],
  },
};

export default json;

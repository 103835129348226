import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P6-E1",
    exerciseKey: "img/FriendsPlus/Page16/Key/E1answerKey.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 114,
      paddingTop: 10,
      maxLength: 1,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f88125",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Match 1–7 with a–g.`,
        color: "#f88125",
        left: 50,
        star: 1,
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        The advantages of doing work experience are …

        <div style='display:flex'>
         
          
            <div>
                <b>1</b>&ensp;that it helps you to get b <u style='text-decoration-style: dotted'>&ensp;b&ensp;</u><br>
                <b>2</b>&ensp;that you get used to #<br>
                <b>3</b>&ensp;that you get together #<br>
                <b>4</b>&ensp;that you often get to #<br>
                <b>5</b>&ensp;that you can get a lot #<br>
                <b>6</b>&ensp;that you can get in #<br>
                <b>7</b>&ensp;that you also get the #<br>
            </div>
            <div style='margin-left: 100px'>
                <b>a</b>&ensp;being with different people.<br>
                <b>b</b>&ensp;ready for the world of work.<br>
                <b>c</b>&ensp;know your work colleagues.<br>
                <b>d</b>&ensp;with other young people, too.<br>
                <b>e</b>&ensp;chance to learn new skills.<br>
                <b>f</b>&ensp;out of helping customers.<br>
                <b>g</b>&ensp;touch with useful contacts.<br>
            </div>
        </div>
      
        
        `,
        answer: ["a", "d", "c", "f", "g", "e"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P6-E2",
    exerciseKey: "img/FriendsPlus/Page16/Key/E2answerKey.png",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "2",
        title: `Complete the letter with the words.`,
        color: "#f88125",
        width: "27cm",
        left: 50,
        star: 2,
        colorStar: "#fdc89c",
      },
    ],
    styleHint: {
      borderColor: "#f88125",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<s>the chance</s>",
          "into",
          "in touch",
          "involved",
          "ready",
          "to know",
          "used",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "2px solid",
      borderBottom: "none",
      width: 114,
      paddingTop: 10,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <div style='position:relative'>
          <div style='position:absolute;top:213px;left:139px'>#</div>
          <div style='position:absolute;top:371px;left:453px'>#</div>
          <div style='position:absolute;top:406px;left:390px'>#</div>
          <div style='position:absolute;top:441px;left:314px'>#</div>
          <div style='position:absolute;top:563px;left:53px'>#</div>
          <div style='position:absolute;top:635px;left:355px'>#</div>
          
          
            <img src="img/FriendsPlus/Page16/E2/1.jpg" style='height:27cm'/>
        </div>
      
        
        `,
        answer: ["involved", "used", "into", "to know", "ready", "in touch"],
      },
    ],
  },

  3: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P6-E3",
    exerciseKey: "/img/FriendsPlus/Page16/Key/e3-key.png",
    audio: "/Audios/1.04.mp3",
    video: "",
    // textAlign:'center',
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 173,
      padding: 0,
      fontSize: 23,
      paddingTop: 10,
      textAlign: "center",
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    hintBox: [
      {
        src: [
          "awesome places to visit",
          "big hotels",
          "cool shops and markets",
          "fun entertainment",
          "historic buildings",
          "nice parks to walk in",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
      {
        src: [
          "big queues",
          "cheap places to eat",
          "crowds of tourists",
          "green spaces",
          "litter",
          "space on public transport",
          "traffic",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=04  ></headphone> Listen to a teacher interviewing
three seventeen-year-old students about their first
year at sixth form college in the UK. Complete the
sentences with the names of the students. Write <i>Rebecca, James</i> or <i>Marcus</i>.`,
        width: "27cm",
        color: "#f88125",
        left: 50,
        star: 2,
        colorStar: "#fdc89c",
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          <div style='margin-top:10px'>
            <span style="font-weight:bold">1</span>
            <span>
              <input id=0 /> didn’t have a very positive experience
              at the beginning, but things have improved.

            </span> 
              
          </div>
          <div style='margin-top:10px'>
            <span style="font-weight:bold">2</span>
            <span>
              <input id=1 ></input> has had a very positive experience.

            </span> 
            
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">3</span>
            <span>
             <input id=2 ></input> has had a generally negative
                experience at the college up to now.
            </span> 
            
          </div>
                   
        </div>
      
        
        `,
        answer: ["Marcus", "Rebecca", "James"],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P6-E4",
    exerciseKey: "img/FriendsPlus/Page16/Key/E4answerKey.png",
    audio: "/Audios/1.04.mp3",
    video: "",
    component: T6,
    textAlign: "center",
    // isHello:true,
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 100,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: {
      width: 940,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f88125",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "book",
          "pronounce",
          "buy",
          "ask",
          "learn",
          "fix",
          "improve",
          "<s>deal</s>",
          "handle",
          "contact",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=04  ></headphone> Listen to the interviews again and
write true or false.
`,
        color: "#f88125",
        width: "27cm",
        left: 50,
        star: 2,
        colorStar: "#fdc89c",
      },
    ],
    // KeyMau: true,
    // isHello: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
      <div style='width:27cm'>
      Rebecca has found all her lessons interesting thanks to her teachers. <u style='text-decoration-style: dotted'>&emsp;true&emsp;</u> <br>
        <b>1</b>&ensp;Rebecca has taken part in some activities outside of class. # <br>
        <b>2</b>&ensp;James wishes that he’d chosen to do more science subjects. # <br>
        <b>3</b>&ensp;It hasn’t been easy for James to make friends with other students, but he has now. # <br>
        <b>4</b>&ensp;Marcus is worried that he isn’t getting prepared for work in the future. # <br>
        <b>5</b>&ensp;Marcus has contacted some companies with the help of his teachers. # <br>

      </div>





          
        `,
        answer: ["true", "false", "true", "false", "true"],
      },
    ],
  },
  5: {
    unit: "Unit 2",

    id: "SW9-2024-U2-P16-E5",
    exerciseKey: "",
    audio: "",
    video: "",
    component: T6,
    // textAlign:'center',
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 850,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    hintBox: [
      {
        src: ["how many", "what", "when", "<s>where</s>", "who", "why"],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Choose one of the community work
        projects below. Write about why you would like to
        get involved with the project. Use some of the ‘get’
        phrases from this page
`,
        color: "#f88125",
        left: 50,
        star: 3,
        width: "27cm",
        colorStar: "#fdc89c",
      },
    ],
    KeyMau: true,
    isHello: true,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        
        
        <div style='width:1000px;margin-top:20px'>
        <ul>
          <li>a sports club for children aged eight to eleven</li>
          <li>a nature reserve for rare animals and birds</li>
          <li>a large hospital in your area</li>
        </ul>
        <u style='text-decoration-style: dotted'>I’d prefer to volunteer at the hospital because I’d get
        the chance to make a difference to the patients’ lives.&emsp;&emsp;</u><br>
        <u style='text-decoration-style: dotted'>I’m sure I’d get to know some fascinating people and I’d
        get a lot out of the experience because I’d learn …&emsp;&emsp;</u>

          <textarea id=0 rows=10 ></textarea>
        </div>
          
        `,
        answer: [
          "I'd like to get involved in the nature reserve project because I'm very interested in working with animals. I'd get the chance to learn a lot of new skills and I'd get to know the different wildlife in the park. I'd also get used to working outdoors and I'd get in touch with useful contacts for the future.",
        ],
      },
    ],
  },
};

export default json;

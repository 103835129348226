import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit2",
    id: "SW9-2024-U2-P18-E1",
    exerciseKey: "/img/FriendsPlus/Page18/key/e1-key.png",
    audio: "/Audios/1.05.mp3",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=05 colorimg='#f0ab9c'></headphone> Look at the title, headings and
photo in the report. What do you think it is about?
Read and listen to the report to check your answer.`,
        left: -50,
        star: 1,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["a <br> b <br> c"],
        answers: ["0-4"],
        initialValue: [],
      },
      Layout: `
       <p>It is about a school where students … for a week</p>
       <div style ="margin-top:30px">

          <div style='display:flex'>
          <div style='margin-right:20px'>
            <div style ="display:flex; margin-bottom:20px">
              <div style='width:30px'><b><input id=0 type=Circle /></b></div>
              <div>
                weren't allowed to use their phones<br>
                tried to stop using social media completely<br>
                tried to spend less time on social media<br>
                </div>
            </div>
          </div>


          <div><img src='/img/FriendsPlus/Page18/9.jpg' style='width:600px' /></div>
          
          </div>
        

        </div>
      `,
    },
  },
  2: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P18-E2",
    exerciseKey: "/img/FriendsPlus/Page18/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 100,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f88125",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: ` Read the report again and write true or false.`,
        left: 50,
        star: 2,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style ="margin-top:30px">
          <span>The students have agreed to stop using social media for an experiment.</span> <span style ='border-bottom-style: dotted'><b><i>true</i></b></span>
        <div style='margin-bottom:20px'></div>
         <div style='display:flex'>
         <div>
         <div style ="display:flex">
             <div style='width:30px'><b>1</b></div>
             <span> All the students are taking part. <input id=0/></span>
          </div>
          <div style="margin-bottom:30px"></div>

          <div style ="display:flex">
             <div style='width:30px'><b>2</b></div>
             <span>  They can't watch YouTube videos. <input id=1/></span>
          </div>
          <div style="margin-bottom:30px"></div>

          <div style ="display:flex">
             <div style='width:30px'><b>3</b></div>
             <span>  Two hundred students dropped out on day seven. <input id=2/></span>
          </div>
          <div style="margin-bottom:30px"></div>

          <div style ="display:flex">
             <div style='width:30px'><b>4</b></div>
             <span> The experiment has been a negative experience for everybody. <input id=3/></span>

          </div>
          <div style="margin-bottom:30px"></div>

          <div style ="display:flex">
             <div style='width:30px'><b>5</b></div>
             <span> Most students were successful. <input id=4/></span>
          </div>
          <div style="margin-bottom:30px"></div>

          <div style ="display:flex">
             <div style='width:30px'><b>6</b></div>
             <span> The experiment was useful. <input id=5/></span>
          </div>
          <div style="margin-bottom:30px"></div>
         </div>

         <div>
          <img src='/img/FriendsPlus/Page18/9.jpg' style='width:600px' />
         </div>
         </div>

          
         
        </div>
       
        `,
        answer: ["true", "true", "false", "false", "false", "true"],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P18-E3",
    exerciseKey: "/img/FriendsPlus/Page18/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: "24cm",
      paddingTop: 10,
      marginLeft: 20,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f88125",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: ` Answer the questions. Write complete sentences.`,
        left: 50,
        star: 3,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style ="margin-top:10px, width: 25cm">
Where is the school that is doing the experiment? 
<div style='border-bottom: 2px dotted'><b>It's in Tarporley, northern England.</b></div>
<div><b>1 </b>Which types of communication can the students still use during the experiment?</div> #
<div><b>2 </b>What other things has Georgia been doing? </div> #
<div><b>3 </b>Why have some students had problems making plans with their friends? </div> #
<div><b>4 </b>What have some students learned?</div> #
<div><b>5 </b>Why did the librarian like the experiment?</div> #
        </div>
       
        `,
        answer: [
          "They can still make phone calls and send emails and text messages.",
          "She's been looking at newspapers and magazines, and talking to her parents.",
          "They don't have their phone numbers because they normally use online chat.",
          "They've learned that they spend over two hours a day on social media.",
          "She liked it because she'd never seen students borrow so many books before.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P18-E4",
    exerciseKey: "/img/FriendsPlus/Page18/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 150,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f88125",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: ` VOCABULARY PLUS Complete the
sentences with the words in brackets and add the
suffixes -ful or -less.`,
        left: 50,
        star: 2,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style ="margin-top:30px">
          <span>Some people go on social media sites <span style ='border-bottom-style: dotted'><b><i>countless</i></b>
          </span> times a day. (count)</span> 
          
        <div style='margin-bottom:20px'></div>


          <div style ="display:flex">
             <div style='width:30px'><b>1</b></div>
             <span> Georgia found the experiment.<input id=0/> (help)</span>

          </div>
          <div style="margin-bottom:30px"></div>

          <div style ="display:flex">
             <div style='width:30px'><b>2</b></div>
             <span>All students were honest and.<input id=1/> (truth)</span>

          </div>
          <div style="margin-bottom:30px"></div>

          <div style ="display:flex">
             <div style='width:30px'><b>3</b></div>
             <span> A lot of things happened at the school. It was a very <input id=2/> week. (event)</span>

          </div>
          <div style="margin-bottom:30px"></div>

          <div style ="display:flex">
             <div style='width:30px'><b>4</b></div>
             <span> One student was desperate without his phone. He found it a <input id=3/> situation. (hope) </span>


          </div>
          <div style="margin-bottom:30px"></div>

          <div style ="display:flex">
             <div style='width:30px'><b>5</b></div>
             <span> For some students, the week without social media felt. <input id=4/> (end) </span>
          </div>

          <div style="margin-bottom:30px"></div>
          
        </div>
       
        `,
        answer: ["helpful", "truthful", "eventful", "hopeless", "endless"],
      },
    ],
  },
};

export default json;

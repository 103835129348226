import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Starter U4",
    id: "SW9-2024-Starter-U4-P57-E1",
    exerciseKey: "",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ` <span style='color:#574d89;font-weight:800;'>Speaking</span>  

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>
                
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;color:rgb(87, 77, 137);' > 1 </span> 
                    <span > Read the first post on an advice forum. Do you
                    like shopping? Why / Why not? What products do
                    you buy? </span>
                    <textarea rows=5 id=0 ></textarea>
                </div>
                <img src='/img/FriendsPlus/Page57/11.jpg' style='width:600px;margin-top:20px;margin-left:150px' />

        </div>
      
        `,
        answer: [],
      },
    ],
  },
  2: {
    unit: "Starter U4",
    id: "SW9-2024-Starter-U4-P57-E2",
    exerciseKey: "/img/FriendsPlus/Page57/key/e2-key.jpg",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 900,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ` <span style='color:#574d89;font-weight:800;'>Reading</span>  

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // KeyMau: true,
    // isHello: true,
    // hideBtnFooter: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>
                
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;color:rgb(87, 77, 137);' > 2 </span> 
                    <span > Read the second post on an advice forum. What is
                    Mia’s attitude to the latest sports brands? </span>
                    <input id=0 />
                </div>
                <img src='/img/FriendsPlus/Page57/11.jpg' style='width:600px;margin-top:20px;margin-left:150px' />

        </div>
      
        `,
        answer: ["They're a bit of a rip-off"],
      },
    ],
  },
  3: {
    unit: "Starter U4",
    id: "SW9-2024-Starter-U4-P57-E3",
    exerciseKey: "/img/FriendsPlus/Page57/key/e3-key.jpg",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 200,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `  Read the posts again. Choose the correct words

`,
        left: -50,
        star: 0,
        color: "#574d89",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: [],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "58px",
              left: "232px",
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          // borderBottom: "none",
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
          fontSize: 22,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          // fontSize: 19,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",

          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "Toby / Ben",
          "listen / speak",
          "will / won’t",
          "a&nbspminority / the&nbspmajority",
        ],
        answers: ["0-0", "1-4", "2-0", "3-4"],
        initialValue: [],
      },
      Layout: `
        <img src='/img/FriendsPlus/Page57/11.jpg' style='width:600px;margin-top:20px;margin-left:150px' />
        <div style='margin-top:20px;'>
           <span style='font-weight:bold;'>a </span> 
          <input id=0 type=Circle />
          <span style='margin-left:10px;'>likes going to the shopping centre </span> <p></p>

          <span style='font-weight:bold;'>b </span>
          Ben needs to
          <input id=1 type=Circle />
          <span style='margin-left:10px;'>to his friend. </span> <p></p>

          <span style='font-weight:bold;'>c </span>
          Mia thinks that Toby
          <input id=2 type=Circle />
          <span style='margin-left:10px;'>understand. </span> <p></p>

          <span style='font-weight:bold;'>d </span>
          Mia says that
          <input id=3 type=Circle />
          <span style='margin-left:10px;'>of people
          can’t afford the latest sports brands. </span> <p></p>
             
            
        </div>
      `,
    },
  },
  4: {
    unit: "Starter U4",
    id: "SW9-2024-Starter-U4-P57-E4",
    exerciseKey: "/img/FriendsPlus/Page57/key/e4-key.jpg",
    audio: "/Audios/1.19.mp3",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 200,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: `<span style='color:#574d89;font-weight:800;'>Listening</span> <br>
        <span style='font-size:30px'>4</span> <headphone name=19 colorImg=#71699c ></headphone>  Listen to the radio programme, Customer
          Choice. What is the mood of the presenter?


`,
        left: -50,
        star: 0,
        color: "#574d89",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: [],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "58px",
              left: "232px",
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          // borderBottom: "none",
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
          fontSize: 22,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          // fontSize: 19,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",

          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a|&nbsp&nbspworried&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|b|&nbsp&nbspangry&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|c|&nbsp&nbspcalm&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|d|&nbsp&nbspexcited",
        ],
        answers: ["0-12"],
        initialValue: [],
      },
      Layout: `
        
        <div style='margin-top:20px;'>
          <input id=0 type=Circle />
          

          
             
            
        </div>
      `,
    },
  },
  5: {
    unit: "Starter U4",
    id: "SW9-2024-Starter-U4-P57-E5",
    exerciseKey: "/img/FriendsPlus/Page57/key/e5-key.jpg",
    audio: "/Audios/1.19.mp3",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      fontSize: 20,
      fontWeight: "bold",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name=19 colorImg=#71699c ></headphone> Listen again. Complete the notes with one,
        two or three words from the radio programme.

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;position:relative'>
          <img src='/img/FriendsPlus/Page57/12.jpg' style='width:600px;margin-left:200px' />
          <div style='position:absolute;top:46px;left:179px' > <input id=0 /> </div>
          <div style='position:absolute;top:46px;left:496px' > <input id=1 /> </div>
          <div style='position:absolute;top:107px;left:598px' > <input id=2 /> </div>
          <div style='position:absolute;top:200px;left:179px' > <input id=3 /> </div>
          <div style='position:absolute;top:264px;left:308px' > <input id=4 /> </div>
          <div style='position:absolute;top:293px;left:181px' > <input id=5 /> </div>
          

        </div>
      
        `,
        answer: [
          "retailers",
          "ten million",
          "used",
          "eleven / 11",
          "fit",
          "reduce",
        ],
      },
    ],
  },
  6: {
    unit: "Starter U4",
    id: "SW9-2024-Starter-U2-P57-E6",
    exerciseKey: "",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ` <span style='color:#574d89;font-weight:800;'>Writing</span>  

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>

                <b style='font-size:30px'>6</b>&ensp;<span style='margin-right:10px;font-weight:bold;' > A TASK Read the post from an advice forum and write
                  a reply.
                 </span> <br>
                 <textarea rows=5 id=0 ></textarea>
        
                <span style='margin-right:10px;font-weight:bold;' > B THINK AND PLAN </span> <br> 
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span> 
                    <span > What do you think? Is this situation common? </span>
                    <textarea rows=2 id=1 ></textarea>
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 2 </span> 
                    <span > Who could Eva talk to about this? </span>
                    <textarea rows=2 id=2 ></textarea>
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 3 </span> 
                    <span > What practical things should Eva do? </span>
                    <textarea rows=2 id=3 ></textarea>
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 4 </span> 
                    <span > What other advice can you give to Eva? </span>
                    <textarea rows=2 id=4 ></textarea>
                </div>

                <span style='margin-right:10px;font-weight:bold;' > C WRITE Use the text in exercise 2 and the writing
                guide. </span> <br>
                <img src='/img/FriendsPlus/Page57/13.jpg' style='width:600px;margin-left:200px;margin-top:40px' />
                <br><br>
                <textarea rows=6 id=5 ></textarea>

        </div>
      
        `,
        answer: [],
      },
    ],
  },
};

export default json;

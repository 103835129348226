import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P43-E1",
    exerciseKey: "/img/FriendsPlus/Page43/key/e1-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 22,
      width: 50,
      paddingTop: 10,
      maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["in", "that (x2)", "where", "which", "<s>who</s>", "whose"],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Match 1–6 with a–f.

`,
        left: 50,
        star: 1,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        
         <div style='margin-top:20px;'>
          <div style='display:flex'>
            <div>
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span>
                    <span> I’d like to work in engineering one day </span>
                    <span style='border-bottom:2px dotted;font-weight:bold'> d </span>
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 2 </span>
                    <span> I’m keen to get some experience working <br> on
                      the information desk </span>
                    <input id=0 /> 
                </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 3 </span>
                <span> I consider myself a confident person </span> 
                <input id=1 /> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 4 </span>
                <span> I’d be grateful for extra information </span> 
                <input id=2 /> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 5 </span>
                <span> I currently attend science club  </span> 
                <input id=3/> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 6 </span>
                <span> I am at school at the moment,  </span> 
                <input id=4/> 
              </div>
            
            </div>

            <div style='margin-left:60px'>
              <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > a </span>
                    <span> but I listen to other people’s ideas, too </span>
              </div>

              <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > b </span>
                    <span> so that I can learn to help visitors. </span>
              </div>

              <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > c </span>
                    <span> because I like working on team projects. </span>
              </div>

              <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > d </span>
                    <span> so it would be useful to try new technology </span>
              </div>

              <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > e </span>
                    <span> but I am available in the summer holidays </span>
              </div>

              <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > f </span>
                    <span> so that I can learn more about the role </span>
              </div>

            </div>

          </div>

        </div>
      
        `,
        answer: ["b", "a", "f", "c", "e"],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P43-E2",
    exerciseKey: "/img/FriendsPlus/Page43/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      // fontSize: 22,
      width: 150,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#00aa4c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<s>because</s>", "because", "but (x2)", "so (x2)", "so that"],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the paragraphs from a formal
            letter with the words.

`,
        left: 50,
        star: 2,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
         <div style='margin-top:20px;position:relative'>
          <img src='/img/FriendsPlus/Page43/9.jpg' style='width:700px;margin-top:30px'/>
          <div style='position:absolute;top:94px;left:299px'> <input id=0 />  </div>
          <div style='position:absolute;top:122px;left:188px'> <input id=1 />  </div>
          <div style='position:absolute;top:193px;left:301px'> <input id=2 />  </div>
          <div style='position:absolute;top:249px;left:18px'> <input id=3 />  </div>
          <div style='position:absolute;top:320px;left:18px'> <input id=4/>  </div>
           <div style='position:absolute;top:347px;left:348px'> <input id=5 />  </div>

        </div>
      
        `,
        answer: ["so that", "so", "but", "so", "because", "but"],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P43-E3",
    exerciseKey: "/img/FriendsPlus/Page43/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 22,
      width: 280,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#00aa4c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "I believe",
          "I consider myself",
          "I’d be grateful",
          "I’m keen",
          "look forward to hearing",
          "really interests me",
          "<s>request further</s>",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Replace the bold words in the formal
              letter with the phrases.

`,
        left: 50,
        star: 2,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
         <div style='margin-top:20px;'>

          <div style='display:flex;width:700px;justify-content:space-between;align-items:center;margin-top:30px'>
            <div>
            <div style='border-bottom:2px dotted;margin-left:27px;margin-bottom:10px'> request further </div>
              <div > <b>1</b> <input id=0 />  </div>
              <div ><b>2</b> <input id=1 />  </div>
              <div ><b>3</b> <input id=2 />  </div>

            </div>

            <div>
              <div style='color:white;margin-bottom:20px'> xxxx </div>
              <div ><b>4</b> <input id=3 />  </div>
              <div ><b>5</b> <input id=4/>  </div>
              <div ><b>6</b> <input id=5 />  </div>
            
            </div>
          
          </div>

          <img src='/img/FriendsPlus/Page43/10.jpg' style='width:700px;margin-top:40px'/>

        </div>
      
        `,
        answer: [
          "really interests me",
          "I'm keen",
          "I believe",
          "I consider myself",
          "I'd be grateful",
          "look forward to hearing",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P43-E4",
    exerciseKey: "",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 22,
      width: 280,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 550,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#00aa4c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "I believe",
          "I consider myself",
          "I’d be grateful",
          "I’m keen",
          "look forward to hearing",
          "really interests me",
          "<s>request further</s>",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `Write a formal letter for a volunteer role
          at a science or technology museum. It involves
          working on the information desk and showing
          visitors around. Use the letter in exercise 3 to help
          you and make notes using the grid below.

`,
        left: 50,
        star: 3,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
         <div style='margin-top:20px;'>

          <div style='border:2px solid rgb(160, 212, 174); '>

            <div style='display:flex;'>
              <div style='width:300px;border-right:1px solid rgb(160, 212, 174);padding-left:15px;padding-right:10px;padding-top:10px'> <span> Who you are and why you are writing the letter </span>
              </div>

              <div style='width:600px;padding-left:15px;padding-right:10px;padding-top:10px'> <textarea rows=2 id=0 ></textarea> </div>
            </div>

            <div style='display:flex;border-top:1px solid rgb(160, 212, 174);'>
              <div style='width:300px;border-right:1px solid rgb(160, 212, 174);padding-left:15px;padding-right:10px;padding-top:10px'> <span> Why you are interested in the programme </span>
              </div>

              <div style='width:600px;padding-left:15px;padding-right:10px;padding-top:10px'>
                <textarea rows=2 id=1 ></textarea>
              </div>
            </div>

            <div style='display:flex;border-top:1px solid rgb(160, 212, 174);'>
              <div style='width:300px;border-right:1px solid rgb(160, 212, 174);padding-left:15px;padding-right:10px;padding-top:10px'> <span> Your personality and any relevant experience </span>
              </div>

              <div style='width:600px;padding-left:15px;padding-right:10px;padding-top:10px'>
                <textarea rows=2 id=2 ></textarea>
              </div>
            </div>

            <div style='display:flex;border-top:1px solid rgb(160, 212, 174);'>
              <div style='width:300px;border-right:1px solid rgb(160, 212, 174);padding-left:15px;padding-right:10px;padding-top:10px'> <span> Any requests for more information and details </span>
              </div>

              <div style='width:600px;padding-left:15px;padding-right:10px;padding-top:10px'>
                <textarea rows=2 id=3 ></textarea>
              </div>
            </div>
          
          
          </div>
          

        </div>
      
        `,
        answer: [""],
      },
    ],
  },
};

export default json;

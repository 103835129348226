import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit1",
    id: "SW9-2024-U2-P20-E1",
    exerciseKey: "/img/FriendsPlus/Page20/key/e1-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct words.`,
        left: -50,
        star: 1,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "rip-off / bargain",
          "consumer / retailer",
          "status&nbspsymbol / product",
          "producer / business",
          "advertisement / marketing",
          "value / price",
          "rent / spend",
          "afford / save",
        ],
        answers: ["0-4", "1-4", "2-0", "3-4", "4-0", "5-4", "6-0", "7-0"],
        initialValue: [],
      },
      Layout: `
       <div >That’s a famous
       <span style="font-weight:bold;border:1px solid black; border-radius:50%;padding:5px 10px">brand</span> / <span style="font-weight:bold">cost</span>
        of sunglasses.It’s popular with celebrities.
        </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">1</span>
       <span>This poster is cheap. It’s a <input id=0 type=Circle />.</span>
       </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">2</span>
       <span>If there are any problems with the smartphone,
        please take it back to the <input id=1 type=Circle />.</span>
       </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">3</span>
       <span>Lots of rich people are buying that expensive car. It’s
        a real status <input id=2 type=Circle />.</span>
       </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">4</span>
       <span>My aunt and uncle have got an online fashion
        <input id=3 type=Circle />. It sells men’s clothes.</span>
       </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">5</span>
       <span>They’ve asked that film star to appear in a new TV
        <input id=4 type=Circle />.</span>
       </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">6</span>
       <span>‘What’s the <input id=5 type=Circle /> of that T-shirt over
        there?’  ‘It’s £20.
        </span>
       </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">7</span>
       <span>My parents are going to <input id=6 type=Circle />  a car on our
        holiday to Italy 
        </span>
       </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">8</span>
       <span>‘Can you <input id=7 type=Circle />  that luxury bag?’
        ‘No, it’s too expensive for me.’ 
        </span>
       </div>
      `,
    },
  },
  2: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P20-E2",
    exerciseKey: "/img/FriendsPlus/Page20/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 180,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#0067b3",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "rip-off",
          "bargain",
          "consumer",
          "retailer",
          "status symbol",
          "product",
          "producer",
          "business",
          "advertisement",
          "marketing",
          "value",
          "price",
          "rent",
          "spend",
          "afford",
          "save",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Match some of the words from exercise 1
with the definitions.`,
        left: 50,
        star: 2,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='margin-top:20px'>
                to give money to pay for products  <u style='text-decoration-style: dotted'>&ensp;spend&ensp;</u><br>
                <b>1</b>&ensp;something that isn’t worth the money you pay for it  #<br>
                <b>2</b>&ensp;something that is a lot cheaper than usual #<br>
                <b>3</b>&ensp;a company which makes products  #<br>
                <b>4</b>&ensp;to pay to use something that belongs to another person and then give it back #<br>
                <b>5</b>&ensp;a person who buys or uses something #<br>
                <b>6</b>&ensp;a product which shows to other people that a
                person is rich and has a high position in society #<br>
                <b>7</b>&ensp;the activity of presenting, advertising and selling
                a company’s products #<br>
                <b>8</b>&ensp;to buy something cheap and use less money  #<br>
            </div>
      
        
        `,
        answer: [
          "rip-off",
          "bargain",
          "producer",
          "rent",
          "consumer",
          "status symbol",
          "marketing",
          "save",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P20-E3",
    exerciseKey: "/img/FriendsPlus/Page20/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      width: 180,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#0067b3",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<s>advertisement</s>",
          "brands",
          "companies",
          "consumers",
          "marketing",
          "product",
          "promote",
          "worth",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the online article with the words.`,
        left: 50,
        star: 2,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='margin-top:20px; display:flex;position:relative'>
                <img src ='/img/FriendsPlus/Page20/9.jpg' style='width:700px'/>
                <div style='position:absolute;top:149px;left:118px'><input id=0/></div>
                <div style='position:absolute;top:281px;left:455px'><input id=1/></div>
                <div style='position:absolute;top:320px;left:305px'><input id=2/></div>
                <div style='position:absolute;top:397px;left:198px'><input id=3/></div>
                <div style='position:absolute;top:451px;left:259px'><input id=4/></div>
                <div style='position:absolute;top:527px;left:198px'><input id=5/></div>
                <div style='position:absolute;top:565px;left:51px'><input id=6/></div>
        </div>
        `,
        answer: [
          "product",
          "companies",
          "promote",
          "worth",
          "marketing",
          "consumers",
          "brands",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P20-E4",
    exerciseKey: "/img/FriendsPlus/Page20/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 180,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 1000,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#0067b3",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<s>advertisement</s>",
          "brands",
          "companies",
          "consumers",
          "marketing",
          "product",
          "promote",
          "worth",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `Answer the questions using some of the
words on this page and your own ideas.`,
        left: 50,
        star: 3,
        color: "#0067b3",
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
       
        <div style='margin-top:20px;'>
                What is your favourite retailer?
                <p style='border-bottom:1px dotted black;font-weight:bold'>It’s a big sports shop in my local shopping centre which sells lots of different brands.
                </p>

                <div>
                  <b>1</b>&ensp;Think of a brand you like. What do you think about
                  the price of this company’s products?<br>
                  <textarea id=0 rows=2></textarea>
                </div>

                <div>
                  <b>2</b>&ensp;How do you think this company promotes its latest
                  products?<br>
                  <textarea id=1 rows=2></textarea>
                </div>

                <div>
                  <b>3</b>&ensp;Why do some companies use celebrities for
                    marketing products?<br>
                  <textarea id=2 rows=2></textarea>
                </div>
        </div>
        `,
        answer: [
          "I think that they cost a lot but they aren’t a rip-off because they’re good quality.",
          "I think it uses online advertisements to promote its latest products.",
          "It makes consumers believe they’re luxury items and status symbols, and buy them.",
        ],
      },
    ],
  },
};

export default json;

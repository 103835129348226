import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P55-E1",
    exerciseKey: "/img/FriendsPlus/Page55/key/e1-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 150,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: ` Complete the sentences with as soon as,
        after, when or until. In some sentences, more than
        one conjunction can be used.

`,
        left: 50,
        star: 1,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> When </span>
                <span> the temperature drops, I prefer to stay indoors.  </span> 
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span> 
                    <span > He decided to stay home </span>
                    <input id=0 />
                    watching the weather forecast.
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 2 </span> 
                    <span > I was studying for my exam  </span> 
                    <input id=1 />
                    my friend called me.
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 3 </span> 
                    <input id=2 />
                    <span > the traffic clears, we can continue our journey.  </span> 
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 4 </span> 
                    <span > He always checks his email  </span> 
                    <input id=3 />
                    he wakes up in the morning.
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 5 </span> 
                    <span > They went for a walk  </span> 
                    <input id=4 />
                    having breakfast.
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 6 </span> 
                    <span > She didn't leave the house  </span> 
                    <input id=5 />
                    the rain stopped.
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 7 </span> 
                    <span > We'll go for a walk in the park  </span> 
                    <input id=6 />
                    lunch.
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 8 </span> 
                    <span >  He couldn't play video games  </span> 
                    <input id=7 />
                    he finished his chores.
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 9 </span> 
                    <span >  He'll go to bed  </span> 
                    <input id=8 />
                    he brushes his teeth.
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 10 </span> 
                    <span >   I'll wait for you  </span> 
                    <input id=9 />
                    you finish your meeting.
                </div>

                

        </div>
      
        `,
        answer: [
          "after",
          "when",
          "As soon as / When",
          "as soon as / when",
          "after",
          "until",
          "after",
          "until",
          "after",
          "until",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P55-E2",
    exerciseKey: "/img/FriendsPlus/Page55/key/e2-key.jpg",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 900,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 700,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: ` Match 1–10 with a–j. Then write full
        sentences with as soon as, after, when or until.

`,
        left: 50,
        star: 2,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>

                <div style='display:flex'>
                <div>
                  <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span> 
                    <span > She'll send the report to you </span>
                    <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> c </span>
                  </div>

                  <div style='margin-top:20px;'>
                      <span style='margin-right:10px;font-weight:bold;' > 2 </span> 
                      <span > They won't announce the winner  </span> 
                      <input id=0 width=50px maxlength=1 /> 
                  </div>

                  <div style='margin-top:20px;'>
                      <span style='margin-right:10px;font-weight:bold;' > 3 </span> 
                      <span > The movie ends  </span> 
                      <input id=1 width=50px maxlength=1 />
                  </div>

                  <div style='margin-top:20px;'>
                      <span style='margin-right:10px;font-weight:bold;' > 4 </span> 
                      <span > He was playing the guitar  </span> 
                      <input id=2 width=50px maxlength=1 />
                  </div>

                  <div style='margin-top:20px;'>
                      <span style='margin-right:10px;font-weight:bold;' > 5 </span> 
                      <span >  He apologised </span> 
                      <input id=3 width=50px maxlength=1 />
                  </div>

                  <div style='margin-top:20px;'>
                      <span style='margin-right:10px;font-weight:bold;' > 6 </span> 
                      <span >  She went to bed  </span> 
                      <input id=4 width=50px maxlength=1 />
                  </div>

                  <div style='margin-top:20px;'>
                      <span style='margin-right:10px;font-weight:bold;' > 7 </span> 
                      <span > The children were talking loudly  </span> 
                      <input id=5 width=50px maxlength=1 />
                  </div>

                  <div style='margin-top:20px;'>
                      <span style='margin-right:10px;font-weight:bold;' > 8 </span> 
                      <span >  We cleaned up the kitchen </span> 
                      <input id=6 width=50px maxlength=1 />
                  </div>

                  <div style='margin-top:20px;'>
                      <span style='margin-right:10px;font-weight:bold;' > 9 </span> 
                      <span >  They won't release the new product  </span> 
                      <input id=7 width=50px maxlength=1 />
                  </div>

                  <div style='margin-top:20px;'>
                      <span style='margin-right:10px;font-weight:bold;' > 10 </span> 
                      <span > He was not allowed to play video games </span> 
                      <input id=8 width=50px maxlength=1 />
                  </div>
                
                </div>


                <div style='margin-left:20px;'>
                  <div style='margin-top:20px;'>
                      <span style='margin-right:10px;font-weight:bold;' > a </span> 
                      <span > we can grab something to eat. </span> 
                  </div>

                  <div style='margin-top:20px;'>
                      <span style='margin-right:10px;font-weight:bold;' > b </span> 
                      <span > they receive the positive feedback from the market research. </span>
                  </div>

                  <div style='margin-top:20px;'>
                      <span style='margin-right:10px;font-weight:bold;' > c </span> 
                      <span > she receives the final data. </span>
                  </div>

                  <div style='margin-top:20px;'>
                      <span style='margin-right:10px;font-weight:bold;' > d </span> 
                      <span > finishing her homework. </span>
                  </div>

                  <div style='margin-top:20px;'>
                      <span style='margin-right:10px;font-weight:bold;' > e </span> 
                      <span > their new teacher came in. </span>
                  </div>

                  <div style='margin-top:20px;'>
                      <span style='margin-right:10px;font-weight:bold;' > f </span> 
                      <span > he finished his chores. </span>
                  </div>

                  <div style='margin-top:20px;'>
                      <span style='margin-right:10px;font-weight:bold;' > g </span> 
                      <span > we cooked dinner. </span>
                  </div>

                  <div style='margin-top:20px;'>
                      <span style='margin-right:10px;font-weight:bold;' > h </span> 
                      <span > all the votes are counted. </span>
                  </div>

                  <div style='margin-top:20px;'>
                      <span style='margin-right:10px;font-weight:bold;' > i </span> 
                      <span > he heard the doorbell ring. </span>
                  </div>

                  <div style='margin-top:20px;'>
                      <span style='margin-right:10px;font-weight:bold;' > j </span> 
                      <span > realising his mistake. </span>
                  </div>
                
                </div>
                
                
                </div>

        </div>

        <div style='width:100%;margin-top:20px; '>
          <p style='font-weight:bold'>Write full sentences</p>
                 <div style='margin-top:20px; display:flex '>
                      <span style='margin-right:10px;font-weight:bold;' > 1 </span> 
                      <textarea id=9 rows=2 ></textarea> 
                  </div>

                 <div style='margin-top:20px; display:flex '>
                      <span style='margin-right:10px;font-weight:bold;' > 2 </span> 
                      <textarea id=10 rows=2 ></textarea> 
                  </div>

                 <div style='margin-top:20px; display:flex '>
                      <span style='margin-right:10px;font-weight:bold;' > 3 </span> 
                      <textarea id=11 rows=2 ></textarea> 
                  </div>

                 <div style='margin-top:20px; display:flex '>
                      <span style='margin-right:10px;font-weight:bold;' > 4 </span> 
                      <textarea id=12 rows=2 ></textarea> 
                  </div>

                 <div style='margin-top:20px; display:flex '>
                      <span style='margin-right:10px;font-weight:bold;' > 5 </span> 
                      <textarea id=13 rows=2 ></textarea> 
                  </div>

                 <div style='margin-top:20px; display:flex '>
                      <span style='margin-right:10px;font-weight:bold;' > 6 </span> 
                      <textarea id=14 rows=2 ></textarea> 
                  </div>

                 <div style='margin-top:20px; display:flex '>
                      <span style='margin-right:10px;font-weight:bold;' > 7 </span> 
                      <textarea id=15 rows=2 ></textarea> 
                  </div>

                 <div style='margin-top:20px; display:flex '>
                      <span style='margin-right:10px;font-weight:bold;' > 8 </span> 
                      <textarea id=16 rows=2 ></textarea> 
                  </div>

                 <div style='margin-top:20px; display:flex '>
                      <span style='margin-right:10px;font-weight:bold;' > 9 </span>
                      
                      <textarea id=17 rows=2 ></textarea> 
                  </div>

                 <div style='margin-top:20px; display:flex '>
                      <span style='margin-right:10px;font-weight:bold;' > 10 </span> 
                      <textarea id=18 rows=2 ></textarea> 
                  </div>
          
        
        </div>
      
        `,
        answer: [
          "h",
          "a",
          "i",
          "j",
          "d",
          "e",
          "g",
          "b",
          "f",
          "She'll send the report to you as soon as she receives the final data. / She'll send the report to you after she receives the final data.",
          "They won't announce the winner until all the votes are counted.",
          "After the movie ends, we can grab something to eat.",
          "He was playing the guitar when he heard the doorbell ring.",
          "He apologised after realising his mistake.",
          "She went to bed after finishing her homework.",
          "The children were talking loudly when their new teacher came in.",
          "We cleaned up the kitchen after we cooked dinner.",
          "They won't release the new product until they receive the positive feedback from the market research.",
          "He was not allowed to play video games until he finished his chores.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P55-E3",
    exerciseKey: "/img/FriendsPlus/Page55/key/e3-key.jpg",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 150,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#a0d4ae",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "making",
          "soaked",
          "<s>change</s>",
          "efforts",
          "Despite",
          "gathered",
          "worse",
          "planned",
          "when",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: ` Read and complete the narrative paragraph
        with the words.

`,
        left: 50,
        star: 2,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
       <div style='margin-top:20px;position:relative'>
          <img src='/img/FriendsPlus/Page55/9.jpg' style='width:700px' />
          <div style='position:absolute;top:88px;left:22px' > <input id=0 /> </div>
          <div style='position:absolute;top:150px;left:48px' > <input id=1 /> </div>
          <div style='position:absolute;top:276px;left:320px' > <input id=2 /> </div>
          <div style='position:absolute;top:371px;left:155px' > <input id=3 /> </div>
          <div style='position:absolute;top:433px;left:83px' > <input id=4 /> </div>
          <div style='position:absolute;top:496px;left:149px' > <input id=5 /> </div>
          <div style='position:absolute;top:559px;left:153px' > <input id=6 /> </div>
          <div style='position:absolute;top:685px;left:26px' > <input id=7 /> </div>
          
          

        </div>
      
        `,
        answer: [
          "when",
          "planned",
          "worse",
          "making",
          "efforts",
          "gathered",
          "soaked",
          "Despite",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P55-E4",
    exerciseKey: "",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 150,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#a0d4ae",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "making",
          "soaked",
          "<s>change</s>",
          "efforts",
          "Despite",
          "gathered",
          "worse",
          "planned",
          "when",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: ` Write a narrative paragraph about
        something unpleasant you experienced at school.

`,
        left: 50,
        star: 3,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;position:relative'>
         <span><b>Use the narrative paragraph in exercise 3 to help
          you and think about the following things:</b></span> <br>
          <ul>
            <li>When did you experience something unpleasant?</li>
            <li>Why was it annoying or displeasing?</li>
            <li>What lesson can you learn from that frustrating experience?</li>
          </ul>
          
          <textarea rows=10 id=0 ></textarea>

        </div>
      
        `,
        answer: [""],
      },
    ],
  },
};

export default json;

import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Starter",
    id: "SW9-2024-Starter-P6-E1",
    exerciseKey: "img/FriendsPlus/Page6/Key/E1answerKey.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
      // background: "none",
    },
    styleHint: {
      borderColor: "#f88125",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the table with the words.`,
        color: "#f88125",
        left: 50,
        star: 1,
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='position:relative'>
          <div style='position:absolute;top:108px;left:30px'><textarea id=0 rows=1 ></textarea></div>
          <div style='position:absolute;top:64px;left:319px'><textarea id=1 rows=2 ></textarea></div>
          <div style='position:absolute;top:220px;left:30px'><textarea id=2 rows=2 ></textarea></div>
          <div style='position:absolute;top:220px;left:319px'><textarea id=3 rows=2 ></textarea></div>
          
            <img src="img/FriendsPlus/Page6/E1/1.jpg" style='height:9cm'/>
        </div>
      
        
        `,
        answer: [
          "video calling",
          "games console, VR headset",
          "HD TV, satellite TV",
          "calculator, spellchecker",
        ],
      },
    ],
  },
  2: {
    unit: "Starter",
    id: "SW9-2024-Starter-P6-E2",
    exerciseKey: "img/FriendsPlus/Page6/Key/E2answerKey.png",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "2",
        title: `Choose the correct words.`,
        color: "#f88125",
        width: "27cm",
        left: -50,
        star: 2,
        colorStar: "#fdc89c",
      },
    ],
    styleHint: {
      borderColor: "#f88125",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "check",
          "download",
          "follow",
          "<s>post</s>",
          "share",
          "spend time",
          "update",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "2px solid",
      borderBottom: "none",
      width: 150,
      paddingTop: 10,
      textAlign: "center",
    },
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "games_console / GPS / VR_headset",
          "HD_TV / GPS / video_calling",
          "VR_headsets / spellcheckers / calculators",
          "remote_controls / satellite_TV / wearables",
          "HD_TV / high-speed_broadband / video_calling",
          "app / GPS / translator",
          "translator / satellite_TV / remote_control",
          "remote_controls / video_calling / HD_TV",
          "games_console / spellchecker / calculator",
        ],
        answers: ["1-0", "2-0", "3-8", "4-4", "5-0", "6-8", "7-4", "8-8"],
        initialValue: [],
      },
      Layout: `

      <div style='width:29cm;'>
      We used to get lost all the time before we had a <span style='font-weight: bold'>games_console</span> / <span style="padding: 1px 4px; border: 2px solid rgb(0, 174, 239); font-weight: bold; border-radius: 50%; color: black;"><span>GPS</span></span> / <span style='font-weight: bold'>VR_headset</span>.<br>
        <b>1</b>&ensp;I’d like to watch that nature programme on <input id=1 type=Circle />.<br>
        <b>2</b>&ensp;Pilots use <input id=2 type=Circle /> to practise flying planes.<br>
        <b>3</b>&ensp;Some athletes exercise using <input id=3 type=Circle /> to get information about their fitness.<br>
        <b>4</b>&ensp;Our internet connection at home is too slow. We need <input id=4 type=Circle />.<br>
        <b>5</b>&ensp;He’s got a cool new <input id=5 type=Circle /> for editing photos on his phone.<br>
        <b>6</b>&ensp;You can’t change the TV channel without the <input id=6 type=Circle />.<br>
        <b>7</b>&ensp;They often use <input id=7 type=Circle /> to talk to their cousins.<br>
        <b>8</b>&ensp;We can use a <input id=8 type=Circle /> to help us in our maths exam.<br>
        
      </div>
      `,
    },
  },

  3: {
    unit: "Starter",
    id: "SW9-2024-Starter-P6-E3",
    exerciseKey: "img/FriendsPlus/Page6/Key/E3answerKey.png",
    audio: "",
    video: "",
    // textAlign:'center',
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 173,
      padding: 0,
      fontSize: 23,
      paddingTop: 10,
      textAlign: "center",
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    hintBox: [
      {
        src: [
          "awesome places to visit",
          "big hotels",
          "cool shops and markets",
          "fun entertainment",
          "historic buildings",
          "nice parks to walk in",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
      {
        src: [
          "big queues",
          "cheap places to eat",
          "crowds of tourists",
          "green spaces",
          "litter",
          "space on public transport",
          "traffic",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the information
article with words from exercises 1 and 2.`,
        width: "27cm",
        color: "#f88125",
        left: 50,
        star: 2,
        colorStar: "#fdc89c",
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <div style='position:relative'>
          <div style='position:absolute;top:534px;left:127px'>#</div>
          <div style='position:absolute;top:664px;left:93px'>#</div>
          <div style='position:absolute;top:765px;left:137px'>#</div>
          <div style='position:absolute;top:838px;left:347px'>#</div>
          <div style='position:absolute;top:967px;left:32px'>#</div>
          <div style='position:absolute;top:1068px;left:144px'>#</div>
          
            <img src="img/FriendsPlus/Page6/E3/1.jpg" style='height:30cm'/>
        </div>
      
        
        `,
        answer: [
          "spellchecker",
          "GPS",
          "HD TV",
          "games console",
          "video calling",
          "translator",
        ],
      },
    ],
  },
  4: {
    unit: "Starter",
    id: "SW9-2024-Starter-P6-E4",
    exerciseKey: "img/FriendsPlus/Page6/Key/E4answerKey.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    // isHello:true,
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 200,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: {
      width: 940,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f88125",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "book",
          "pronounce",
          "buy",
          "ask",
          "learn",
          "fix",
          "improve",
          "<s>deal</s>",
          "handle",
          "contact",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences with some of the
words on this page and your own ideas.
`,
        color: "#f88125",
        width: "27cm",
        left: 50,
        star: 3,
        colorStar: "#fdc89c",
      },
    ],
    // KeyMau: true,
    // isHello: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        I couldn’t live without a <u style='text-decoration-style: dotted'>&emsp;calculator&emsp;</u> because
        <u style='text-decoration-style: dotted'>&emsp;I'm not very good at doing maths in my head.&emsp;</u>
      <div style='width:27cm'>
        <b>1</b>&ensp;I could live without <input width=200px /> because <input width=400px />.<br>
        <b>2</b>&ensp;The most popular technology in our home is <input width=200px /> because <input width=400px />.<br>
        <b>3</b>&ensp;The technology that I use the most is <input width=300px /> because <input width=400px />.<br>
        <b>4</b>&ensp;When I write essays, I always use a <input width=200px /> because <input width=400px />.<br>
      </div>





          
        `,
        answer: [
          "video calling",
          "I use messenger to chat to friends",
          "satellite TV",
          "we all watch it",
          "my games console",
          "I love gaming",
          "spellchecker",
          "I sometimes make mistakes",
        ],
      },
    ],
  },
  5: {
    unit: "Starter",
    id: "SW9-2024-Starter-P6-E5",
    exerciseKey: "img/FriendsPlus/Page6/Key/E5answerKey.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign:'center',
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 850,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    hintBox: [
      {
        src: ["how many", "what", "when", "<s>where</s>", "who", "why"],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Order the phrases to make meaningful
questions and answer them.`,
        color: "#f88125",
        left: 50,
        star: 3,
        colorStar: "#fdc89c",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        improve time / know how to / management skills? / Do you <br>
        <u style='text-decoration-style: dotted'>&emsp;Do you know how to improve time management skills?&emsp;</u>
        <div style='width:27cm'>
            <b>1</b>&ensp;me why to / for a term? / consider studying abroad / Can you tell<br>#<br>
            <b>2</b>&ensp;who to approach / Do you know / on writing a / for guidance / good essay?<br>#<br>
            <b>3</b>&ensp;recommend where to / Can you / party next week? / celebrate our year-end<br>#<br>
            <b>4</b>&ensp;tell me who / before using the hall? / to ask for permission / Can you <br>#<br>
        </div>
          
        `,
        answer: [
          "Can you tell me why to consider studying abroad for a term?",
          "Do you know who to approach for guidance on writing a good essay?",
          "Can you recommend where to celebrate our year-end party next week?",
          "Can you tell me who to ask for permission before using the hall?",
        ],
      },
    ],
  },
};

export default json;

import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P28-E1",
    exerciseKey: "/img/FriendsPlus/Page28/key/e1-key.png",
    audio: "",
    video: "",
    checkDuplicated: true,
    component: Circle_Write,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 150,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: ` Find seven more verbs in the wordsearch.
        Then put all the verbs into opposite pairs.
`,
        left: -39,
        star: 2,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    question: {
      DrawLines: {
        multipleLine: true,
        colorLineTo: "#40c7f4",
        newPointDot: true,
        // notOverlapse: true,
        circlePadding: "0 0",
        // margin: "0 0 0 10px",
        padding: "4px 0px 4px 15px",
        border: "1px solid rgb(248,129,37)",
        // transform: "translate(50%)",
        circleStringArray: [
          "TURNONRBXS",
          "IKVBKZEWZL",
          "NZOYLKDETO",
          "CSPEEDUPUW",
          "RVQUSNCYRD",
          "EZJGBTEXNO",
          "ATURNOFFUW",
          "SKWVISTYPN",
          "EZTURNDOWN",
        ],
        boxMatch: [],
        answers: [
          "0_0-0_5",
          "0_6-5_6",
          "1_0-8_0",
          "3_1-3_7",
          "6_1-6_7",
          "8_2-8_9",
          "2_8-7_8",
          "0_9-7_9",
        ],
        initialValue: ["0_0-0_5"],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 300,
          height: 34,
        },
        answers: [
          "turn off",
          "speed up - slow down|turn up - turn down|increase - reduce",
          "speed up - slow down|turn up - turn down|increase - reduce",
          "speed up - slow down|turn up - turn down|increase - reduce",
        ],
        initialValue: [],
      },
      Circle: {
        isHiddenCheck: true,
        newSplit: true,
        initialWordStyle: {
          border: "2px solid",
          borderColor: "transparent",
          padding: "1px 5px",
          borderRadius: "50%",
          fontSize: 20,
        },
        selectWordStyle: {
          borderColor: "#40c7f4",
        },
        limitSelect: 1,
        listWords: [
          "soda / ice_tea",
          "mushroom / meat",
          "lemonade / melon",
          "onion / melon",
          "onion / meat",
          "lemonade / ice_tea",
          "cucumber / lemonade",
          "onion / mushrooms",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
  
      <div style='display:flex'>
      <div id='circle-container' style='position: relative; width: 14cm;  display: grid; grid-template-columns: repeat(10, 1fr)'>
            <input id=0 type='boxMatch'/>
            <input id=1 type='boxMatch'/>
            <input id=2 type='boxMatch'/>
            <input id=3 type='boxMatch'/>
            <input id=4 type='boxMatch'/>
            <input id=5 type='boxMatch'/>
            <input id=6 type='boxMatch'/>
            <input id=7 type='boxMatch'/>
            <input id=8 type='boxMatch'/>
            <input id=9 type='boxMatch'/>
          </div>
          <div style=';margin-left:20px'>
                <div style='display:flex;height:0cm;justify-content:space-around'>
                <div>
                  <b style='font-size:30px'>+ / ⬆</b>
                </div>
                <div>
                  <b style='font-size:30px'>+ / ⬇</b>
                </div>
                </div>
                <div style='display:flex;justify-content:space-around'>
                <div>
                  <br><u style='text-decoration-style: dotted'>&emsp;turn on&emsp;</u> - 
                </div>
                <div>
                  <br><input width=150px />
                </div>
                </div>
                #<br>#<br>#
          </div>
          </div>
        
      `,
    },
  },
  2: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P28-E2",
    exerciseKey: "/img/FriendsPlus/Page28/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      fontSize: 20,
      fontWeight: "bold",
      width: 140,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: ` Complete the words in the advertisement
`,
        left: 50,
        star: 2,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;position:relative'>
         <img src='/img/FriendsPlus/Page28/10.jpg' style='width:600px'/>
         <div style='position:absolute; top:385px;left:417px'> <input id=0 /> </div>
         <div style='position:absolute; top:385px;left:489px'> <input id=1 /> </div>
         <div style='position:absolute; top:416px;left:362px'> <input id=2 /> </div>
         <div style='position:absolute; top:416px;left:432px'> <input id=3 /> </div>
         <div style='position:absolute; top:515px;left:40px'> <input id=4 /> </div>
         <div style='position:absolute; top:546px;left:391px'> <input id=5 /> </div>
         <div style='position:absolute; top:546px;left:458px'> <input id=6 /> </div>
         <div style='position:absolute; top:578px;left:261px'> <input id=7 /> </div>
         <div style='position:absolute; top:607px;left:85px'> <input id=8 /> </div>
         <div style='position:absolute; top:607px;left:156px'> <input id=9 /> </div>
         <div style='position:absolute; top:648px;left:373px'> <input id=10 /> </div>
         <div style='position:absolute; top:679px;left:280px'> <input id=11 /> </div>
         
        
        </div>
      
        
        `,
        answer: [
          "ork",
          "ut",
          "ing",
          "long",
          "mproves",
          "heer",
          "p",
          "educe",
          "alm",
          "own",
          "istracts",
          "oncentrate",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P28-E3",
    exerciseKey: "/img/FriendsPlus/Page28/key/e3-key.png",
    audio: "/Audios/1.09.mp3",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=09 colorImg=#f29046></headphone> Listen to the podcast about the
benefits of learning a musical instrument. Choose the
correct words.`,
        left: -50,
        star: 1,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          // fontSize: 20,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "patient / annoyed / energetic",
          "inform / instruct / warn",
          "deny / confirm / explain",
          "angry / relaxed / worried",
        ],
        answers: ["0-8", "1-0", "2-8", "3-4"],
        initialValue: [],
      },
      Layout: `
       <div >
        <span>The presenter sounds </span>
          <span style="font-weight:bold;">excited </span>/
          <span style="font-weight:bold;border:1px solid black; border-radius:50%;padding:5px 10px">calm</span> /
          <span style="font-weight:bold;">surprised</span>
       
        </div>

       <div style='margin-top:20px'>
       <span style="margin-right:10px;font-weight:bold">1</span>
       Dr Lloyd is 
       <span><input id=0 type=Circle />.</span>
       </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">2</span>
       The podcast aims to 
       <span><input id=1 type=Circle />.</span>
       </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">3</span>
        Dr Lloyd wants to certain
        important ideas.

       <span><input id=2 type=Circle />.</span>
       </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">4</span>
        At the end when Dr Lloyd discusses emotions, she
        sounds more 
       <span><input id=3 type=Circle />.
        </span>
       </div>
       
       </div>
      `,
    },
  },
  4: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P28-E4",
    exerciseKey: "/img/FriendsPlus/Page28/key/e4-key.png",
    audio: "/Audios/1.09.mp3",
    video: "",
    component: T6,
    textAlign: "center",
    checkDuplicated: true,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=09 colorImg=#f29046></headphone> Listen again. Complete the
sentences with one or two words in each space
`,
        left: 50,
        star: 2,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: "|",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          <p>Dr Lloyd says that playing a musical instrument is the
          same as 
          <span style='border-bottom:2px dotted black;font-weight:bold'>
            working out .
          </span>
          
          
          </p>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">1</span>
            
            <span> 
             Learning an instrument can help your memory to
            </span> 
            <input  id=0 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">2</span>
            <span> 
              It could even help your IQ to
            </span> 
            <input  id=1 ></input> 
            
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">3</span>
            <span>
              It’s important for musicians to
            </span> 
            <input  id=2 ></input> 
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">4</span>
            <span>
              You can often hear an orchestra
            </span> 
            <input  id=3 ></input> or <input  id=4 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">5</span>
            <span>
              Music may help you to
            </span> 
            <input  id=5 ></input> and relax.
          </div>

        </div>
      
        
        `,
        answer: [
          "improve",
          "increase",
          "concentrate",
          "speed up|slow down",
          "slow down|speed up",
          "calm down",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SW9-2024-U4-P28-E5",
    exerciseKey: "/img/FriendsPlus/Page28/key/e5-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 500,
      marginLeft: 10,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `What would you say to these people? Use
        some of the words on this page and your own ideas.
`,
        left: 50,
        star: 3,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          <p>Your brother or sister is playing really loud music and
            you’re studying for a test tomorrow. <br>
          <span style='border-bottom:2px dotted black;font-weight:bold'>
            Please turn down your music! I can’t concentrate!
          </span>
          
          
          </p>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">1</span>
            
            <span> 
             Your mum is listening to the radio quietly in the car
              and your favourite song comes on. <br>
            </span> 
            <input  id=0 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">2</span>
            <span> 
              Your friends are annoyed and unhappy because
              they have had an argument with each other <br>
            </span> 
            <input  id=1 ></input> 
            
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">3</span>
            <span>
              Your younger cousin is practising for a piano exam,
              but he keeps playing too fast.<br>
            </span> 
            <input  id=2 ></input> 
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">4</span>
            <span>
              Your friend doesn’t do any exercise and never goes
              to the gym.<br>
            </span> 
            <input  id=3 ></input>
          </div>

         

        </div>
      
        
        `,
        answer: [
          "Please turn up the volume!",
          "You should calm down and cheer up!",
          "You need to slow down a bit!",
          "You should work out at the gym.",
        ],
      },
    ],
  },
};

export default json;

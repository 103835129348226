import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit1",
    id: "SW9-2024-U1-P11-E1",
    exerciseKey: "img/FriendsPlus/Page11/Key/E1answerKey.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      width: 200,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "decided",
          "didn’t stay",
          "’d left",
          "hadn’t seen",
          "wasn’t enjoying",
          "<s>were driving</s>",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences with the words.`,
        color: "#1dbfd6",
        left: 50,
        star: 1,
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
      <div style='position:relative'>
        <div style='position:absolute;top:184px;left:34px'><input width=272px /></div>
        <div style='position:absolute;top:280px;left:153px'>#</div>
        <div style='position:absolute;top:329px;left:57px'>#</div>
        <div style='position:absolute;top:424px;left:477px'>#</div>
        <div style='position:absolute;top:506px;left:64px'>#</div>
        
        
        <img src="img/FriendsPlus/Page11/E1/1.jpg" style='height:15cm'/>
      </div>
        
        `,
        answer: [
          "wasn't enjoying",
          "decided",
          "didn't stay",
          "'d left",
          "hadn't seen",
        ],
      },
    ],
  },
  2: {
    unit: "Unit1",
    id: "SW9-2024-U1-P11-E2",
    exerciseKey: "img/FriendsPlus/Page11/Key/E2answerKey.png",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "2",
        title: `Complete the dialogues with the past
simple, past continuous or past perfect form of the
verbs.`,
        color: "#1dbfd6",
        width: "27cm",
        left: 50,
        star: 2,
        colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "break",
          "do",
          "finish",
          "live",
          "<s>leave</s>",
          "not find",
          "read",
          "see",
          "not try",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 200,
      paddingTop: 10,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        ‘You look surprised, Alina.’ ‘Yes! I <u style='text-decoration-style: dotted'>&emsp;had&emsp;</u> already <u style='text-decoration-style: dotted'>&emsp;was walking&emsp;</u> <br>
        to school when I <u style='text-decoration-style: dotted'>&emsp;had&emsp;</u> already <u style='text-decoration-style: dotted'>&emsp;came across&emsp;</u> an old friend.’
<div style='width:26cm'>    
      <div style='display:flex'>
            <b>1</b>&ensp;
            <div style='margin-left:15px'>‘What happened to your new phone on holiday?’ 
            ‘Someone # it from my bag when I # .’</div>
      </div>
      <div style='display:flex'>
            <b>2</b>&ensp;
            <div style='margin-left:15px'>‘Did you know anybody at Mike’s party?’ 
            ‘Yes, we # Mike’s brother because we # him before.’</div>
      </div>
      <div style='display:flex'>
            <b>3</b>&ensp;
            <div style='margin-left:15px'>‘ # your friends # the theme park?’  ‘Yes, they did. 
            It was their first time there – they # it before.’</div>
      </div>
      <div style='display:flex'>
            <b>4</b>&ensp;
            <div style='margin-left:15px'>‘My sister # that Bond film was boring.’ 
            ‘Really? # she # it before?’</div>
      </div>
      <div style='display:flex'>
            <b>5</b>&ensp;
            <div style='margin-left:15px'>‘What # you # this time last
            year?’  ‘We # across Germany on a school trip.’</div>
      </div>
      <div style='display:flex'>
            <b>6</b>&ensp;
            <div style='margin-left:15px'>‘Tell me about the last time you went to the
            beach.’  ‘It was brilliant – first I # football on the sand, then I # in the sea for a while.’</div>
      </div>
</div>
        
        `,
        answer: [
          "stole",
          "wasn't looking",
          "recognised",
          "'d met",
          "Did",
          "enjoy",
          "hadn't visited",
          "thought",
          "Had",
          "seen",
          "were",
          "doing",
          "were travelling",
          "played",
          "swam",
        ],
      },
    ],
  },

  3: {
    unit: "Unit1",
    id: "SW9-2024-U1-P11-E3",
    exerciseKey: "img/FriendsPlus/Page11/Key/E3answerKey.png",
    audio: "",
    video: "",
    // textAlign:'center',
    stylesTextInput: {
      background: "none",
      // borderBottom: "2px dotted",
      borderBottom: "none",
      width: 240,
      padding: 0,
      fontSize: 23,
      paddingTop: 10,
      textAlign: "center",
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    hintBox: [
      {
        src: [
          "awesome places to visit",
          "big hotels",
          "cool shops and markets",
          "fun entertainment",
          "historic buildings",
          "nice parks to walk in",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the sentences with the words in
brackets. Use the correct past narrative tenses and
your own ideas.`,
        width: "27cm",
        color: "#1dbfd6",
        left: 50,
        star: 2,
        colorStar: "#b4e2ef",
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        

      <div style='position:relative'>
        <div style='position:absolute;top:265px;left:124px'><input width=137px /></div>
        <div style='position:absolute;top:297px;left:146px'><input width=100px /></div>
        <div style='position:absolute;top:361px;left:124px'><input width=137px /></div>
        <div style='position:absolute;top:425px;left:190px'><input width=137px /></div>
        <div style='position:absolute;top:521px;left:109px'><input width=181px /></div>
        <div style='position:absolute;top:553px;left:49px'><input width=107px /></div>
        <div style='position:absolute;top:585px;left:124px'><input width=137px /></div>
        <div style='position:absolute;top:617px;left:47px'><input width=100px /></div>
        <div style='position:absolute;top:681px;left:331px'><input width=100px /></div>
        <div style='position:absolute;top:745px;left:143px'><input width=170px /></div>
        <div style='position:absolute;top:777px;left:271px'><input width=100px /></div>
        <div style='position:absolute;top:841px;left:138px'><input width=100px /></div>
        <div style='position:absolute;top:873px;left:271px'><input width=100px /></div>
        <div style='position:absolute;top:937px;left:92px'><input width=120px /></div>
        <img src="img/FriendsPlus/Page11/E3/1.jpg" style='height:27cm'/>
      </div>
          
        `,
        answer: [
          "was eating",
          "saw",
          "recognised",
          "belonged",
          "hadn't realised",
          "'d left",
          "didn't keep",
          "sent",
          "invited",
          "were sitting",
          "called",
          "sat down",
          "gave",
          "'d been",
        ],
      },
    ],
  },
  4: {
    unit: "Unit1",
    id: "SW9-2024-U1-P11-E4",
    exerciseKey: "img/FriendsPlus/Page11/Key/E4answerKey.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    // isHello:true,
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 600,
      marginLeft: 20,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: {
      width: 700,
      resize: "none",
      paddingTop: 3,
      marginBottom: -23,
      marginLeft: 17,
      marginTop: -3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["finish", "go", "lose", "not eat", "not fly", "not see"],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences with the words in
brackets. Use the correct past narrative tenses and
your own ideas.
`,
        color: "#1dbfd6",
        width: "27cm",
        left: 50,
        star: 3,
        colorStar: "#b4e2ef",
      },
    ],
    // KeyMau: true,
    // isHello: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        My dad was filming us play in the football match  <br>
        <u style='text-decoration-style: dotted'>&emsp;&emsp;when his camera suddenly broke.&emsp;&emsp;</u>(when)
        <div style='width:26cm'>
            <b>1</b>&ensp;We came across some funny videos <br>#(while)<br>
            <b>2</b>&ensp;My parents were tidying the house <br>#(when)<br>
            <b>3</b>&ensp;I arrived at the bus stop at 8.20, but the bus <br>#(already)<br>
            <b>4</b>&ensp;I tried to buy some memorabilia on eBay but <br>#(five minutes before)<br>
        </div>





          
        `,
        answer: [
          "while we were reading a blog.",
          "when they found my old action figures.",
          "had already gone without me.",
          "another person had bought it five minutes before.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit1",
    id: "SW9-2024-U1-P11-E5",
    exerciseKey: "img/FriendsPlus/Page11/Key/E5answerKey.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign:'center',
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 850,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "brother(s) / sister(s)",
          "live in …",
          "name",
          "used to play / go / like",
        ],
        width: 600,
        borderColor: "#1dbfd6",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `You meet a friend who you haven’t seen
for a long time. Write four or five questions using
question tags. Use the phrases in the box and
exercise 4 for ideas.`,
        color: "#1dbfd6",
        left: 50,
        star: 3,
        colorStar: "#b4e2ef",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <textarea id=0 rows=5></textarea>
        `,
        initialValue: ["Hey, it's Derin, isn't it?"],
        answer: [
          "Can you tell me why to consider studying abroad for a term?",
          "Do you know who to approach for guidance on writing a good essay?",
          "Can you recommend where to celebrate our year-end party next week?",
          "Can you tell me who to ask for permission before using the hall?",
        ],
      },
    ],
  },
};

export default json;

import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit1",
    id: "SW9-2024-U2-P17-E1",
    exerciseKey: "/img/FriendsPlus/Page17/key/e1-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct words.`,
        left: -50,
        star: 1,
        color: "#1dbfd6",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          " ’ve / ’s",
          "haven't / hasn't",
          "haven't / hasn't",
          "Have / Has",
          "Have / Has",
          "worked / work",
          "dropped&nbspout / drop&nbspout",
          "Did / Have",
        ],
        answers: ["0-6", "1-0", "2-4", "3-0", "4-4", "5-0", "6-4", "7-0"],
        initialValue: [],
      },
      Layout: `
       <div style='position:relative'>
        <div style='position:absolute;top:85px;left:40px; right:40px'>I / You / We / You / They <span style='border:1px solid black ;border-radius:50%;text-align:center'>
        
        <span style ='padding:10px'>’ve </span>
        </span> / ’s finished the work experience.
        He / She / It <sup>1</sup><input id=0 type=Circle /> started a project.</div>

        <div style='position:absolute;top:206px;left:40px; right:40px'>I / You / We / You / They
         <sup>2</sup><input id=1 type=Circle />
         made a decision yet. <br/>
         He / She / It <sup>3</sup><input id=2 type=Circle /> committed to the new idea.</div>

          <div style='position:absolute;top:341px;left:40px; right:40px'><sup>4</sup><input id=3 type=Circle /> I / you / we / you / they rushed into a new course? <sup>5</sup><input id=4 type=Circle /> he / she / it considered the job?</div>

          <div style='position:absolute;top:495px;left:40px; right:40px'>I / You / He / She / It / We / You / They <sup>6</sup><input id=5 type=Circle /> hard last year.</div>

        <div style='position:absolute;top:583px;left:40px; right:40px'>I / You / He / She / It / We / You / They didn’t
        <sup>7</sup><input id=6 type=Circle /> of the class.</div>

        <div style='position:absolute;top:668px;left:40px; right:40px'><sup>8</sup><input id=7 type=Circle /> I / you / he / she / it / we / you / they
enjoy the work at the school last week?</div>

        <img src="/img/FriendsPlus/Page17/9.png" style='height:20cm;width:800px'/>
      </div>
      `,
    },
  },
  2: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P17-E2",
    exerciseKey: "/img/FriendsPlus/Page17/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 220,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f88125",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the dialogue using the past
simple or present perfect form of the verbs in
brackets.`,
        left: 50,
        star: 2,
        color: "#1dbfd6",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style ="margin-top:30px">
          <div style ="display:flex">
             <div style='width:100px'><b>Max</b></div>
             <span>Hi, Jim! What have you been up to</span>
          </div>

          <div style ="display:flex">
             <div style='width:100px'><b>Jim</b></div>
             <span> I've been doing work experience with my cousin, Ray – he’s an electrician.</span>
          </div>

          <div style ="display:flex">
             <div style='width:100px'><b>Max</b></div>
             <span> Cool. When did you start (you / start)?.</span>
          </div>

          <div style ="display:flex">
             <div style='width:100px'><b>Jim</b></div>
             <span> I <sup>1</sup> <input id=0 /> (start) on Monday.</span>
          </div>

          <div style ="display:flex">
             <div style='width:100px'><b>Max</b></div>
             <span><sup>2</sup> <input id=1 /> (you / learn) anything yet?</span>
          </div>

          <div style ="display:flex">
             <div style='width:100px'><b>Jim</b></div>
             <span>Yes, I  <sup>3</sup> <input id=2 /> (find out) how to stay safe with electricity!</span>
          </div>

          <div style ="display:flex">
             <div style='width:100px'><b>Max</b></div>
             <span>  That’s important!</span>
          </div>

          <div style ="display:flex">
             <div style='width:100px'><b>Jim</b></div>
             <span> Yeah, there’s so much to learn. Ray <sup>4</sup> <input id=3 /> (teach) me a lot this week.<br/>
              He’s very patient – he <sup>5</sup> <input id=4 /> (not get) annoyed with me once!</span>
          </div>

          <div style ="display:flex">
             <div style='width:100px'><b>Max</b></div>
             <span>  That’s lucky! What else have you done?</span>
          </div>

          <div style ="display:flex">
             <div style='width:100px'><b>Jim</b></div>
             <span>   Yesterday, I <sup>6</sup> <input id=5 /> (repair)  a lamp</span>
          </div>

          <div style ="display:flex">
             <div style='width:100px'><b>Max</b></div>
             <span> <sup>7</sup> <input id=6 /> (Ray / help) you?</span>
          </div>

          <div style ="display:flex">
             <div style='width:100px'><b>Jim</b></div>
             <span>  No, he <sup>8</sup> <input id=7 /> (not do) anything.</span>
          </div>
          <div style ="display:flex">
             <div style='width:100px'><b>Max</b></div>
             <span> Very impressive!</span>
          </div>
        </div>
          
       
         
          
           
       
      
        
        `,
        answer: [
          "started",
          "Have you learned",
          "'ve found out",
          "'s taught",
          "hasn't got",
          "repaired",
          "Did Ray help",
          "didn't do",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P17-E3",
    exerciseKey: "/img/FriendsPlus/Page17/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 700,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f88125",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Write the sentences using the words
given to make clauses and phrases of concession.`,
        left: 50,
        star: 2,
        color: "#1dbfd6",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style ="margin-top:30px">
          <div style ="display:flex">
             <div style='width:30px'><b>1</b></div>
             <span> Even though / concert / crowded / they / really / enjoyed / it.</span>
          </div>
          <div style="margin-bottom:30px"><input id=0/></div>

          <div style ="display:flex">
             <div style='width:30px'><b>2</b></div>
             <span>  She / completed / marathon / although / she / exhausted.</span>
          </div>
          <div style="margin-bottom:30px"><input id=1/></div>

          <div style ="display:flex">
             <div style='width:30px'><b>3</b></div>
             <span>  We / decided / have / picnic / despite / rainy weather.</span>
          </div>
          <div style="margin-bottom:30px"><input id=2/></div>

          <div style ="display:flex">
             <div style='width:30px'><b>4</b></div>
             <span> In spite / his age / he / still / leads / active life.</span>
          </div>
          <div style="margin-bottom:30px"><input id=3/></div>

          <div style ="display:flex">
             <div style='width:30px'><b>5</b></div>
             <span> Though / Tim / often / annoyed / Ann / she / fond / him.</span>
          </div>
          <div style="margin-bottom:30px"><input id=4/></div>
          
        </div>
       
        `,
        answer: [
          "Even though the concert was crowded, they really enjoyed it.",
          "She completed the marathon although she was exhausted.",
          "We decided to have a picnic despite the rainy  weather.",
          "In spite of his age, he still leads an active life.",
          "Though Tim often annoyed Ann, she was fond of him.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P17-E4",
    exerciseKey: "/img/FriendsPlus/Page17/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 820,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f88125",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `Rewrite the sentences, beginning with the words in brackets.`,
        left: 50,
        star: 3,
        color: "#1dbfd6",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style ="margin-top:30px">
        <div style ="display:flex">
             <span> They have a car, but they rarely use it. (though)</span>
          </div>
          <div style="margin-bottom:30px;width:820px;border-bottom-style: dotted">
          <span>Though they have a car, they rarely use it.</span>
          </div>

          <div style ="display:flex">
             <div style='width:30px'><b>1</b></div>
             <span> She never takes any exercise, but she is very fit and healthy. (even though)</span>
          </div>
          <div style="margin-bottom:30px"><input id=0/></div>

          <div style ="display:flex">
             <div style='width:30px'><b>2</b></div>
             <span>  He wasn't wearing a coat although it was quite cold. (despite) </span>
          </div>
          <div style="margin-bottom:30px"><input id=1/></div>

          <div style ="display:flex">
             <div style='width:30px'><b>3</b></div>
             <span> We love music, but we can't play a musical instrument. (although)</span>
          </div>
          <div style="margin-bottom:30px"><input id=2/></div>

          <div style ="display:flex">
             <div style='width:30px'><b>4</b></div>
             <span> Sarah felt very tired. She worked on the project late yesterday. (in spite of)</span>
          </div>
          <div style="margin-bottom:30px"><input id=3/></div>

          <div style ="display:flex">
             <div style='width:30px'><b>5</b></div>
             <span> It rained heavily, but the football match continued without interruption. (despite)</span>
          </div>
          <div style="margin-bottom:30px"><input id=4/></div>
          
        </div>
       
        `,
        answer: [
          "Even though she never takes any exercise, she is very fit and healthy.",
          "Despite the cold, he wasn't wearing a coat.|Despite the cold, he was not wearing a coat.",
          "Although we love music, we can't play a musical instrument.|Although we love music, we can not play a musical instrument.",
          "In spite of feeling very tired, Sarah worked on the project late yesterday.",
          "Despite the heavy rain, the football match continued without interruption.",
        ],
      },
    ],
  },
};

export default json;

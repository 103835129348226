import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P46-E1",
    exerciseKey: "/img/FriendsPlus/Page46/Key/e1-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: ` Choose the correct words.
`,
        left: -50,
        star: 1,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 50,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          fontSize: 19,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",

          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "bury / examine",
          "remove / display",
          "unearth / preserve",
          "restore / locate",
          "hide / destroy",
        ],
        answers: ["0-4", "1-4", "2-0", "3-0", "4-4"],
        initialValue: [],
      },
      Layout: `
        
        <div style='margin-top:20px;position:relative'>
            <img src='/img/FriendsPlus/Page46/12.jpg' style='width:900px' />
            <div style='position:absolute;top:109px;left:366px'> <input id=0 type=Circle /> </div>
            <div style='position:absolute;top:109px;left:685px'> <input id=1 type=Circle /> </div>
            <div style='position:absolute;top:256px;left:40px'> <input id=2 type=Circle /> </div>
            <div style='position:absolute;top:256px;left:366px'> <input id=3 type=Circle /> </div>
            <div style='position:absolute;top:256px;left:685px'> <input id=4 type=Circle /> </div>
            
            
        </div>
      `,
    },
  },
  2: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P46-E2",
    exerciseKey: "/img/FriendsPlus/Page46/Key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      width: 150,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#0067b3",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "heavy traffic",
          "<s>leisure facilities</s>",
          "music venue",
          "pedestrian walkways",
          "railway line",
          "skatepark",
          "sports stadium",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `  Complete the sentences with the past
                participle of some of the verbs in exercise 1.

`,
        left: 50,
        star: 2,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                    <span> The ancient vase was</span> 
                    <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> unearthed  </span>
                    by carefully brushing away the earth on top of it.
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span>
                    The money was
                    <input  id=0 ></input>
                    by thieves in a secret room. It was only found recently.

                </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 2 </span>
                The coin was
                <input  id=1 ></input>
                with a microscope. The archaeologists could then see the details.
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 3 </span>
                Was his body
                <input  id=2 ></input>
                in the ground here over 1,000 years ago when he died?
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 4 </span>
                The precious Roman necklace was
                <input  id=3 ></input>
                to its original beauty and is now in a museum.
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 5 </span>
                Some hair was carefully
                <input  id=3 ></input>
                from the skeleton and taken away for testing.
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 6 </span>
               ‘Where are the ancient ruins
                <input  id=3 ></input>
                ?’ ‘Next to the river. They’re open to visitors every day.’
              </div>
            
            </div>
           
        </div>
      
        `,
        answer: [
          "hidden",
          "examined",
          "buried",
          "restored",
          "removed",
          "located",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P46-E3",
    exerciseKey: "/img/FriendsPlus/Page46/Key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      fontSize: 22,
      width: 150,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#0067b3",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "heavy traffic",
          "<s>leisure facilities</s>",
          "music venue",
          "pedestrian walkways",
          "railway line",
          "skatepark",
          "sports stadium",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `   Read the news article ‘An amazing
          discovery’. What type of word do you think goes in
          each space? Write date, number or time.

`,
        left: 50,
        star: 2,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;position:relative'>
            <img src='/img/FriendsPlus/Page46/13.jpg' style='width:600px;margin-left:200px' />
            <div style='position:absolute;top:174px;left:242px'> <input id=0  /> </div>
            <div style='position:absolute;top:202px;left:368px'> <input id=1  /> </div>
            <div style='position:absolute;top:255px;left:259px'> <input id=2  /> </div>
            <div style='position:absolute;top:309px;left:442px'> <input id=3  /> </div>
            <div style='position:absolute;top:362px;left:249px'> <input id=4  /> </div>
            
        </div>
      
        `,
        answer: ["date", "number", "number", "time", "date"],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P46-E4",
    exerciseKey: "/img/FriendsPlus/Page46/Key/e4-key.png",
    audio: "/Audios/1.15.mp3",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      fontSize: 22,
      width: 150,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#0067b3",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "heavy traffic",
          "<s>leisure facilities</s>",
          "music venue",
          "pedestrian walkways",
          "railway line",
          "skatepark",
          "sports stadium",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=15 colorImge=#f29046></headphone>   Now listen to the news story and
            complete the words in the article. Then check your
            answers to exercise 3.

`,
        left: 50,
        star: 2,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;position:relative'>
            <img src='/img/FriendsPlus/Page46/13.jpg' style='width:600px;margin-left:200px' />
            <div style='position:absolute;top:174px;left:242px'> <input id=0  /> </div>
            <div style='position:absolute;top:202px;left:368px'> <input id=1  /> </div>
            <div style='position:absolute;top:255px;left:259px'> <input id=2  /> </div>
            <div style='position:absolute;top:309px;left:442px'> <input id=3  /> </div>
            <div style='position:absolute;top:362px;left:249px'> <input id=4  /> </div>
            <div style='position:absolute;top:362px;left:442px'> <input id=5  /> </div>
            
        </div>
      
        `,
        answer: [
          "27th April",
          "nineteen",
          "one",
          "600",
          "3rd",
          "4th centuries",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P46-E5",
    exerciseKey: "/img/FriendsPlus/Page46/Key/e5-key.png",
    audio: "/Audios/1.15.mp3",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      width: 180,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#0067b3",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "heavy traffic",
          "<s>leisure facilities</s>",
          "music venue",
          "pedestrian walkways",
          "railway line",
          "skatepark",
          "sports stadium",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: ` <headphone name=15 colorImge=#f29046></headphone>  Listen again. Correct the
information in bold.

`,
        left: 50,
        star: 2,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                    <span> The coins were unearthed by</span> 
                    <span> <s><b>road diggers.</b></s> </span>
                    <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> construction workers  </span>
                    by <br> carefully brushing away the earth on top of it.
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span>
                    The coins were preserved in Roman jugs and
                    <span> <b>eleven</b> </span>
                    of those were unbroken
                    <input  id=0 ></input>

                </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 2 </span>
                The workers stopped so the archaeologists could
                <span> <b>restore </b> </span>
                the jugs
                <input  id=1 ></input>
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 3 </span>
                The coins are in very good condition as they were
                <span> <b> renovated </b> </span>
                soon after they were made.
                <input  id=2 ></input>
                in the ground here over 1,000 years ago when he died?
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 4 </span>
                The coins could be worth
                <span> <b> a few thousand </b> </span>
                 euros.
                <input  id=3 ></input>
                
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 5 </span>
                The coins will be 
                <span> <b>  sold by </b> </span>
                the museum in Seville.
                <input  id=3 ></input>
                from the skeleton and taken away for testing.
              </div>

            </div>
           
        </div>
      
        `,
        answer: ["ten", "remove", "buried", "several million", "displayed at"],
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "SW9-2024-U7-P46-E6",
    exerciseKey: "/img/FriendsPlus/Page46/Key/e6-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      width: 180,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f29046",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "by archaeologists",
          "by farmers",
          "display",
          "examine and restore",
          "excavate",
          "in a nearby town",
          "last year",
          "on local TV news",
          "Roman pot",
          "silver jewellery",
          "town’s museum",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `  Write about things that have been
        discovered in your area or country using some of
        the words on this page. Or use the ideas in the box
        to write about an imaginary discovery.

`,
        left: 50,
        star: 3,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    KeyMau: true,
    isHello: true,
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                    <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> Last year, a beautiful Roman mosaic and some silver coins were unearthed during <br> roadworks near …  </span>
                </div> <br>
                <textarea rows=10 id=0 ></textarea>
          
            </div>
           
        </div>
      
        `,
        answer: [
          "Last year, a Roman pot and some silver jewellery were excavated by farmers in a nearby town. I saw it on the local TV news. They were examined and restored by archaeologists and they're now displayed in the town's museum.",
        ],
      },
    ],
  },
};

export default json;

import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import ScoreByColor from "../../components/ExcerciseTypes/Design/ScoreByColor";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P53-E1",
    exerciseKey: "/img/FriendsPlus/Page53/key/e1-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 200,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `  Choose the correct words to complete
        the table.
`,
        left: -50,
        star: 1,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: [],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "58px",
              left: "232px",
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          // borderBottom: "none",
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
          fontSize: 22,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          // fontSize: 19,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",

          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "would&nbspthe&nbsppolice / the&nbsppolice&nbspwould",
          "could&nbspI / I&nbspcould",
          "the&nbspbookstore&nbspwas / was&nbspthe&nbspbookstore.",
          "has&nbsphe / he'd ",
          "they&nbspwere / were&nbspthey",
        ],
        answers: ["3-4", "4-0", "2-0", "1-4", "0-4"],
        initialValue: [],
      },
      Layout: `
        
        <div style='margin-top:20px;'>
          <div style='border: 1px solid rgb(36, 187, 212);width:900px'>
          <div style='background: rgb(180, 226, 239);padding-left:20px;border-bottom:1px solid rgb(36, 187, 212);'>
            <b><span >Reported questions</span></b>
          </div>

          <div style='display:flex' >
              <div style="flex:1;padding:10px 20px;border-right:1px solid rgb(36, 187, 212)">
                  <b><span>Yes / No questions</span></b>
              </div>
              <div style="flex:1;padding:10px 20px">
                <b><span>Use if or whether</span></b>
              </div>
          </div>

          <div style='display:flex' >
              <div style="flex:1;padding:10px 20px;border-right:1px solid rgb(36, 187, 212)">
                  <span>‘Are you worried about the new subject?’</span>
              </div>
              <div style="flex:1;padding:10px 20px">
                <span>She asked if
                <b><span style='border:1px solid;border-radius:50%'>I was</span></b> /
                  <b><span >was I</span></b>
                worried about the new subject.</span>
              </div>
          </div>

          <div style='display:flex' >
              <div style="flex:1;padding:10px 20px;border-right:1px solid rgb(36, 187, 212)">
                  <span>‘Will the police arrive quickly?’</span>
              </div>
              <div style="flex:1;padding:10px 20px">
                  <span>
                    They asked if
                    <b><sup>1</sup></b> <input id=0 type=Circle /> arrive quickly.
                  </span>
              </div>
          </div>

          <div style='display:flex' >
              <div style="flex:1;padding:10px 20px;border-right:1px solid rgb(36, 187, 212)">
                  <span>‘Can you describe one dangerous sport?’</span>
              </div>
              <div style="flex:1;padding:10px 20px">
                  <span>
                    They asked me whether
                    <b><sup>2</sup></b> <input id=1 type=Circle />
                     describe one dangerous sport.
                  </span>
              </div>
          </div>

          <div style='display:flex' >
              <div style="flex:1;padding:10px 20px;border-right:1px solid rgb(36, 187, 212)">
                  <b><span>Wh- questions</span></b>
              </div>
              <div style="flex:1;padding:10px 20px">
                <b><span>Use the wh- word</span></b>
              </div>
          </div>

            <div style='display:flex' >
              <div style="flex:1;padding:10px 20px;border-right:1px solid rgb(36, 187, 212)">
                  <span>‘Where is the bookstore?’</span>
              </div>
              <div style="flex:1;padding:10px 20px">
                  <span>
                    She asked the police where
                    <b><sup>3</sup></b> <input id=2 type=Circle />
                  </span>
              </div>
            </div>

            <div style='display:flex' >
              <div style="flex:1;padding:10px 20px;border-right:1px solid rgb(36, 187, 212)">
                  <span>‘What has he written about the school?’</span>
              </div>
              <div style="flex:1;padding:10px 20px">
                  <span>
                    They asked us what
                    <b><sup>4</sup></b> <input id=3 type=Circle />
                    written about the school.
                  </span>
              </div>
            </div>

            <div style='display:flex' >
              <div style="flex:1;padding:10px 20px;border-right:1px solid rgb(36, 187, 212)">
                  <span>‘Where are they looking for him?’</span>
              </div>
              <div style="flex:1;padding:10px 20px">
                  <span>
                    My friend asked me where
                    <b><sup>5</sup></b> <input id=4 type=Circle />
                    looking for him
                  </span>
              </div>
            </div>
          
        </div>
          

        </div>
      `,
    },
  },
  2: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P53-E2",
    exerciseKey: "/img/FriendsPlus/Page53/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 450,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["could", "’d", "had to", "knew", "was", "would"],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `  Order the words to complete the reported questions.

`,
        left: 50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                  <span> ‘Where are you calling from?’
                  calling / where / was / I / from The police officer asked me </span>
                  <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> where I was calling from  </span>
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span> 
                    <span > ‘What did you see?’ what / seen / had / we </span> <br>
                    She asked me <input id=0 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 2 </span> 
                    <span > ‘Where did the burglar go?’ the / where / had / burglar / gone <br>
                    She asked me <input id=1 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 3 </span> 
                    <span > ‘Do you have a photo of him?’ him / if / of / photo / had / a / I <br>
                    She asked me <input id=2 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 4 </span> 
                    <span > ‘Can you identify him?’ could / we / whether / him / identify <br>
                    She asked me <input id=3 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 5 </span> 
                    <span > ‘When will you come to the station?’ would / when / station / come / the / to / we <br>
                    She asked me <input id=4 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 6 </span> 
                    <span > ‘Have you told anybody about it?’ about / told / if / we / had / anybody / it <br>
                    She asked me <input id=5 />
                </div>

        </div>
      
        `,
        answer: [
          "what we had seen",
          "where the burglar had gone",
          "if I had a photo of him",
          "whether we could identify him",
          "when we could come to the station",
          "if we had told anybody about it",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P53-E3",
    exerciseKey: "/img/FriendsPlus/Page53/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 180,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "not chase",
          "<s>describe</s>",
          "help",
          "not leave",
          "tell",
          "drive",
          "make",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `  Complete the sentences with the correct
        form of the verbs. Then write command, request,
        offer or suggestion.

`,
        left: 50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                  <span> The teacher asked me </span>
                  <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> to describe </span>
                  my father's appearance
                  <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> request </span>
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span> 
                    <span > They told me 
                    <input id=0 />
                    the thief. He was running too fast.
                    <input id=1 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 2 </span> 
                    <span > A woman offered 
                    <input id=2 />
                    me pick up my things
                    <input id=3 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 3 </span> 
                    <span > My friend Angela suggested that I 
                    <input id=4 />
                    my boyfriend the truth
                    <input id=5 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 4 </span> 
                    <span > Roy offered 
                    <input id=6 />
                    me to the nearest post office
                    <input id=7 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 5 </span> 
                    <span > My new pen pal asked me 
                    <input id=8 />
                    a phone call every month
                    <input id=9 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 6 </span> 
                    <span > He told me 
                    <input id=10 />
                    the school until the orientation session was finished.
                    <input id=11 />
                </div>


        </div>
      
        `,
        answer: [
          "not to chase",
          "command",
          "to help",
          "offer",
          "tell",
          "suggestion",
          "to drive",
          "offer",
          "to make",
          "request",
          "not to leave",
          "command",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P53-E4",
    exerciseKey: "/img/FriendsPlus/Page53/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 700,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "not chase",
          "<s>describe</s>",
          "help",
          "not leave",
          "tell",
          "drive",
          "make",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `  Imagine the situations and what the
        people would do. Complete the reported questions.

`,
        left: 50,
        star: 3,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    KeyMau: true,
    isHello: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                  <span> Someone hacked your computer. What did your dad suggest? </span> <br>
                  He <span style='border-bottom:2px dotted;font-weight:bold;margin-right:10px'> suggested that I download security software </span>
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span> 
                    <span > You lost your phone in the shopping centre. What
                    did your friend offer to do? <br>
                    He <input id=0 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 2 </span> 
                    <span > Someone stole your camera on holiday. What did
                    the police officer ask you to do?  <br>
                    She <input id=1 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 3 </span> 
                    <span > You saw someone drawing graffiti at school. What
                    did your mum tell you to do?  <br>
                    She <input id=2 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 4 </span> 
                    <span > You found a bag in the school playground. What
                    did your classmate suggest?  <br>
                    He <input id=3 />
                </div>

        </div>
      
        `,
        answer: [
          "offered to lend me his phone",
          "asked me to describe it.",
          "told me to tell a teacher.",
          "suggested that I take it to the school reception desk.",
        ],
      },
    ],
  },
};

export default json;

import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 3",
    id: "SW9-2024-U2-P25-E1",
    exerciseKey: "/img/FriendsPlus/Page25/key/e1-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct words.`,
        left: -50,
        star: 1,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          // fontSize: 20,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "as / both",
          "is / is&nbspnot",
          "as / of",
          "too / both",
          "well / good",
          "also / both",
        ],
        answers: ["0-0", "1-4", "2-0", "3-4", "4-0", "5-0"],
        initialValue: [],
      },
      Layout: `
       <div style='width: 27cm'>
       <div >This training rope is a fantastic present for both
sporty adults  
       <span style="font-weight:bold;">as</span> /
       <span style="font-weight:bold;border:1px solid black; border-radius:50%;padding:5px 10px">and</span>
       teenagers.
        </div>

       <div style='margin-top:20px'>
       <span style="margin-right:10px;font-weight:bold">1</span>
       <span>This awesome gift would be brilliant for
        birthdays <input id=0 type=Circle />  well as other occasions.</span>
       </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">2</span>
       <span>This new projector <input id=1 type=Circle />  only unique, but it’s
also really useful.</span>
       </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">3</span>
       <span>The gym equipment is convenient as well  <input id=2 type=Circle /> extremely light.</span>
       </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">4</span>
       <span>You’ll notice that this gadget is <input id=3 type=Circle /> attractive and beautifully designed.
        </span>
       </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">5</span>
       <span>The bicycle is good value as <input id=4 type=Circle />  as great
          fun.
        </span>
       </div>

       <div style='margin-top:10px'>
       <span style="margin-right:10px;font-weight:bold">6</span>
       <span>Everybody agrees that this present is not only
stylish, but <input id=5 type=Circle />  extremely practical.
        </span>
       </div>

       
       </div>
      `,
    },
  },
  2: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P25-E2",
    exerciseKey: "/img/FriendsPlus/Page25/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 800,
      textAlign: "Left",
      marginLeft: 10,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "aren’t going to shop",
          "doesn’t open",
          "’ll pay",
          "’m going to buy",
          "’m not waiting",
          "’s renting",
          "<s>starts</s>",
          "won’t drive",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Order the words to complete the
sentences.
`,
        left: 50,
        star: 2,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          <p>This portable speaker isn’t only small, (but /
              loud / also / it’s / very) 
          <span style='border-bottom:2px dotted black;font-weight:bold'>but it’s also very loud.</span>
          </p>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">1</span>
            <span>
              I think that this cool scarf will be a hit with boys
              (girls / as / as / well) <br>

            </span> 
            <input id=0 ></input>  
          </div>
          <div style='margin-top:10px'>
            <span style="font-weight:bold">2</span>
            <span>
              This ice cream machine will appeal to both
              adults (love / children / who / food / and) 

            </span> 
            <input id=1 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">3</span>
            <span>
              You’ll see that this box for your headphones
              is both attractive (too / very / and / tough,) 
            </span> 
            <input id=2 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">4</span>
            <span>
              This box of luxury chocolates isn’t only for
              my mum, (me / it’s / but / also / for / !) 

            </span> 
            <input id=3 ></input>
          </div>

          <div style='margin-top:10px'>
            <span style="font-weight:bold">5</span>
            <span>
              This waterproof radio is perfect for both using
              in the shower (to / and / taking / beach / the)
            </span> 
            <input id=4 ></input>
          </div>

          
        </div>
      
        
        `,
        answer: [
          "as well as girls",
          "and children who love food",
          "and very tough, too",
          "but it's also for me!|but it is also for me!",
          "and taking to the beach",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P25-E3",
    exerciseKey: "/img/FriendsPlus/Page25/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 350,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#00aa4c",
      justifyContent: "center",
      width: 700,
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,

        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "the chance to contribute",
          "as well as fashion",
          "share my career plan",
          "Write to me soon",
          "To achieve my goals",
          "our drawing skills",
          "both knowledge and skills ",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Read and complete the letter with the
phrases.
`,
        left: 50,
        star: 2,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='margin-top:20px;'>
          <div style='border:2px solid rgb(0,170,76);padding: 10px 20px;border-radius:20px; width:800px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; margin-top:30px'>

          <p style='margin-top:30px'>Dear Aaron,</p>
          <p>It's been a while since our last mails. How is
          everything at school? I do hope you are enjoying
          your term now.</p>

          <p>I've been thinking about what I'm going to do in
            the future lately. Today, I'm glad to <sup style='font-weight:bold'>1</sup> <input id=0 />
          </p>

          <p>with you and I'd like to know what you think about
            it. I've always been interested in art <sup style='font-weight:bold'>2</sup> <input id=1>
          </p>
          <p>so I decided to pursue a career in fashion designing after a great deal of consideration. To be a great
            fashion designer, not only do we sharpen 
          </p>

          <p><sup style='font-weight:bold'>3</sup> <input id=2 /> but we also have to know some
            basic fashion concepts. In every industry, especially
            in fashion designing,<sup style='font-weight:bold'>4</sup> <input /id=3 />
          </p>
          <p>are necessary. In my opinion, it will be a dream of my life if I successfully become one of the most       well-known fashion designers who creates numerous masterpieces someday.
          </p>

          <p><sup style='font-weight:bold'>5</sup> <input id=4 />, I am enrolling in the University of Architecture after graduating from high  school. I believe in the possible influence of my future career and
          </p>

          <p><sup style='font-weight:bold'>6</sup> <input id=5 />to our country's fashion growth.
          </p>
          <p>Well, that's all for my plan. Are there any pieces of advice you can give me? How about your future
              career? Don't be shy to tell me.
          </p>

          <p><sup style='font-weight:bold'>7</sup> <input id=6 />and tell me about your decision. Stay healthy and happy,
          </p>
          <p style='margin-bottom:30px'>Danny </p>
         
          
          </div>
        </div>
      
        
        `,
        answer: [
          "share my career plan",
          "as well as fashion",
          "our drawing skills",
          "both knowledge and skills",
          "To achieve my goals ",
          "the chance to contribute",
          "Write to me soon",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "SW9-2024-U3-P25-E4",
    exerciseKey: "",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 300,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#00aa4c",
      justifyContent: "center",
      width: 700,
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,

        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "the chance to contribute",
          "as well as fashion",
          "share my career plan",
          "Write to me soon",
          "To achieve my goals",
          "our drawing skills",
          "both knowledge and skills ",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `Write a letter to your pen pal and share
with him / her about your future career. Using the ideas in exercise 3 to help you.

`,
        left: 50,
        star: 3,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
       <div style='margin-left:250px;margin-top:40px'> <img src='/img/FriendsPlus/Page25/9.jpg' style='width:600px'/>  </div>
      
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;

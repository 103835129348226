import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P21-E1",
    exerciseKey: "/img/FriendsPlus/Page21/key/e1-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px solid",
      width: 50,
      paddingTop: 10,
      maxLength: 1,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#0067b3",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "rip-off",
          "bargain",
          "consumer",
          "retailer",
          "status symbol",
          "product",
          "producer",
          "business",
          "advertisement",
          "marketing",
          "value",
          "price",
          "rent",
          "spend",
          "afford",
          "save",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Match the half-sentences in column A and B.`,
        left: 50,
        star: 1,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;position:relative'>
                <img src='/img/FriendsPlus/Page21/11.jpg'style='width:800px'/>
                <div style='position:absolute;top:214px;left:254px'><input id=0/></div>
                <div style='position:absolute;top:294px;left:224px'><input id=1/></div>
                <div style='position:absolute;top:379px;left:288px'><input id=2/></div>
                <div style='position:absolute;top:464px;left:341px'><input id=3/></div>
                <div style='position:absolute;top:502px;left:341px'><input id=4/></div>
            </div>
      
        
        `,
        answer: ["d", "e", "b", "c", "a"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P21-E2",
    exerciseKey: "/img/FriendsPlus/Page21/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px solid",
      width: 260,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["because", "as", "since", "because of", "if", "so"],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the words.`,
        left: 50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: "/",
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='margin-top:20px;'>
                <p>Her family decided to eat out yesterday
                <span style='border-bottom:1px dotted black;font-weight:bold'>because / as / since</span>
                there was no food at home.</p>

                <div style='margin-top:10px'>
                <span style="margin-right:10px;font-weight:bold">1</span>
                <span>The flight to Italy was cancelled <input id=0 type=Circle /> the heavy fog.</span>
                </div>

                <div style='margin-top:10px'>
                <span style="margin-right:10px;font-weight:bold">2</span>
                <span>You should look the words up in a dictionary <input id=1 type=Circle />
                you don't understand them.</span>
                </div>

                <div style='margin-top:10px'>
                <span style="margin-right:10px;font-weight:bold">3</span>
                <span>No one was watching the film, <input id=2 type=Circle /> I turned it off.</span>
                </div>

                <div style='margin-top:10px'>
                <span style="margin-right:10px;font-weight:bold">4</span>
                <span><input id=3 type=Circle /> Nancy had no beautiful clothes to
                wear, she didn't come up at your party last night.</span>
                </div>

                <div style='margin-top:10px'>
                <span style="margin-right:10px;font-weight:bold">5</span>
                <span>The team lost the trophy <input id=4 type=Circle />  their bad
                performances yesterday.</span>
                </div>

                <div style='margin-top:10px'>
                <span style="margin-right:10px;font-weight:bold">6</span>
                <span><input id=5 type=Circle /> you are not fluent in English, you
                won't be accepted for this job.</span>
                </div>

                <div style='margin-top:10px'>
                <span style="margin-right:10px;font-weight:bold">7</span>
                <span><input id=6 type=Circle />the cheap price, my son insisted on
                buy him a new laptop.</span>
                </div>

                <div style='margin-top:10px'>
                <span style="margin-right:10px;font-weight:bold">8</span>
                <span>I found the last exam so easy <input id=7 type=Circle /> I had
                worked very hard during the course.</span>
                </div>

            </div>
      
        
        `,
        answer: [
          "because of",
          "if",
          "so",
          "Because / As / Since",
          "because of",
          "if",
          "Because of",
          "because / as / since",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P21-E3",
    exerciseKey: "/img/FriendsPlus/Page21/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px solid",
      width: 260,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["because", "as", "since", "because of", "if", "so"],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the dialogue with the adverbial
clauses and phrase of reason.`,
        left: 50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: "/",
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='margin-top:20px;'>
                
                <div style='margin-top:10px;display:flex'>
                <div style="margin-right:10px;font-weight:bold; width:80px"><span >Kyle</span></div>
                <span>Hi Daisy! Great to see you again. How was
                your trip to New York?</span>
                </div>

                <div style='margin-top:10px;display:flex'>
                <div style="margin-right:10px;font-weight:bold; width:80px"><span >Daisy</span></div>
                <span>It was nice, but not as great as I had expected.</span>
                </div>

                <div style='margin-top:10px;display:flex'>
                <div style="margin-right:10px;font-weight:bold; width:80px"><span >Kyle</span></div>
                <span>What happened?</span>
                </div>
                
                <div style='margin-top:10px;display:flex'>
                <div style="margin-right:10px;font-weight:bold; width:80px"><span >Daisy</span></div>
                <span> <sup>1</sup> <input id=0/> the heavy rain, we couldn't enjoy
                many outdoor activities. <br> We mostly went
                window shopping and found a shelter in a 
                small café.  It was very cozy, <br/> though.</span>
                </div>

                <div style='margin-top:10px;display:flex'>
                <div style="margin-right:10px;font-weight:bold; width:80px"><span >Kyle</span></div>
                <span>That's too bad. I saw your photos of the café
                on social media. They were great shots.</span>
                </div>

                <div style='margin-top:10px;display:flex'>
                <div style="margin-right:10px;font-weight:bold; width:80px"><span >Daisy</span></div>
                <span>Thanks. <sup>2</sup> <input id=1/> it was raining all day,
                we couldn't do anything except <br> watching 
                the raindrops from the glass window. How
                romantic it was! I couldn't  hesitate <br> to snap
                the photos.</span>
                </div>

                <div style='margin-top:10px;display:flex'>
                <div style="margin-right:10px;font-weight:bold; width:80px"><span >Kyle</span></div>
                <span>I also saw the candle on the table, too. It did
                make the photos even more eye-catching.</span>
                </div>

                 <div style='margin-top:10px;display:flex'>
                <div style="margin-right:10px;font-weight:bold; width:80px"><span >Daisy</span></div>
                <span>It was my intention to put the candle there
                <sup>3</sup> <input id=2/> I thought it would <br> make the best shot. </span>
                </div>

                <div style='margin-top:10px;display:flex'>
                <div style="margin-right:10px;font-weight:bold; width:80px"><span >Kyle</span></div>
                <span>Brilliant!</span>
                </div>

              

            </div>
      
        
        `,
        answer: ["Because of", "Because / As / Since", "because / as / since"],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SW9-2024-U2-P21-E4",
    exerciseKey: "/img/FriendsPlus/Page21/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px solid",
      width: 900,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["because", "as", "since", "because of", "if", "so"],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `Rewrite the sentences.`,
        left: 50,
        star: 3,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
                <p>Olivia wants to travel to Milan this summer so she
                    has to save money from now.<br>
                <span style='border-bottom:1px dotted black;font-weight:bold'>Since Olivia wants to travel to Milan this summer, she has to save money from now.</span>
                </p>

                <div style='margin-top:10px'>
                <span style="margin-right:10px;font-weight:bold">1</span>
                <span>Because of driving too fast, my brother caused a
                serious accident last night.<br>
                My brother <input id=0 type=Circle /></span>
                </div>

                <div style='margin-top:10px'>
                <span style="margin-right:10px;font-weight:bold">2</span>
                <span>As the fashion bloggers make really funny videos,
                they become popular on social media.<br>
                Because of <input id=1 type=Circle />.</span>
                </div>

                <div style='margin-top:10px'>
                <span style="margin-right:10px;font-weight:bold">3</span>
                <span>Ann didn't sleep well last night so she's having a
                headache now.<br>
                Since <input id=2 type=Circle />.</span>
                </div>
                </div>

            </div>
      
        
        `,
        answer: [
          "caused a serious accident last night because he drove too fast.",
          "making really funny videos, the fashion bloggers become popular on social media.",
          "Ann didn't sleep well last night, she's having a headache now.",
        ],
      },
    ],
  },
};

export default json;

import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Starter",
    id: "SW9-2024-Starter-P4-E1",
    exerciseKey: "img/FriendsPlus/Page4/Key/E1answerKey.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "1px dotted",
      width: 130,
      paddingTop: 3,
    },
    hintBox: [
      {
        src: [
          "found",
          "has",
          "haven’t",
          "invited",
          "isn’t",
          "might",
          "’m",
          "preserved",
          "’re",
          "wasn’t",
          "were",
          "will",
          "won’t",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Match phrases 1–5 with photos A–F.`,
        color: "#203c8f",
        left: 50,
        star: 1,
        colorStar: "#95b0db",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
         stream a film <span style='text-decoration: underline; text-decoration-style: dotted'>&ensp;E&ensp;</span>
        <div style='display:flex'>
          <div style=''>
                <div style='display:flex'><select id=0></select><b>1</b>&ensp;chat to friends online</div>
                <div style='display:flex'><select id=1></select><b>2</b>&ensp;reset your password</div>
                <div style='display:flex'><select id=2></select><b>3</b>&ensp;check your phone</div>
                <div style='display:flex'><select id=3></select><b>4</b>&ensp;connect to Wi-Fi</div>
                <div style='display:flex'><select id=4></select><b>5</b>&ensp;upload photos</div>
          </div>
            <img src="img/FriendsPlus/Page4/E1/1.jpg" style='height:17cm'/>
        </div>
      
        
        `,
        answer: ["D", "A", "B", "F", "C"],
      },
    ],
  },
  2: {
    unit: "Starter",
    id: "SW9-2024-Starter-P4-E2",
    exerciseKey: "img/FriendsPlus/Page4/Key/E2answerKey.png",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "2",
        title: `Replace the bold words.`,
        color: "#203c8f",
        left: 50,
        star: 2,
        colorStar: "#95b0db",
      },
    ],
    styleHint: {
      borderColor: "#203c8f",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "check",
          "download",
          "follow",
          "<s>post</s>",
          "share",
          "spend time",
          "update",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      width: 150,
      paddingTop: 10,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
          <hintbox id=0></hintbox>
          Do you ever <b>write</b> comments on blogs? <u style='text-decoration-style: dotted'>&emsp;post&emsp;</u>
          <div style=''>
                <b>1</b>&ensp;He wants to <b>transfer</b> that new film to his laptop.#<br>
                <b>2</b>&ensp;I usually <b>look at</b> my social media accounts once a day.#<br>
                <b>3</b>&ensp;I hardly ever <b>add new information to</b> my Facebook profile.#<br>
                <b>4</b>&ensp;Do you often <b>post</b> photos on Instagram?#<br>
                <b>5</b>&ensp;We <b>get news and updates from</b> that famous footballer on Twitter.#<br>
                <b>6</b>&ensp;Do you <b>go</b> online every day?#<br>
          </div>
        
      
        
        `,
        answer: [
          "download",
          "check",
          "update",
          "share",
          "follow",
          "spend time",
        ],
      },
    ],
  },

  3: {
    unit: "Starter",
    id: "SW9-2024-Starter-P4-E3",
    exerciseKey: "img/FriendsPlus/Page4/Key/E3answerKey.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign:'center',
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 150,
      paddingTop: 10,
      textAlign: "center",
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    hintBox: [
      {
        src: [
          "awesome places to visit",
          "big hotels",
          "cool shops and markets",
          "fun entertainment",
          "historic buildings",
          "nice parks to walk in",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
      {
        src: [
          "big queues",
          "cheap places to eat",
          "crowds of tourists",
          "green spaces",
          "litter",
          "space on public transport",
          "traffic",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the online comments with verbs
      and verb phrases from exercises 1 and 2.`,
        width: "27cm",
        color: "#203c8f",
        left: 50,
        star: 2,
        colorStar: "#95b0db",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position:relative'>
          <div style='position:absolute;top:272px;left:107px'><input width=125px /></div>
          <div style='position:absolute;top:341px;left:126px'><input width=125px /></div>
          <div style='position:absolute;top:378px;left:40px'><input width=125px /></div>
          <div style='position:absolute;top:486px;left:221px'><input width=153px /></div>
          <div style='position:absolute;top:522px;left:139px'><input width=125px /></div>
          <div style='position:absolute;top:590px;left:247px'><input width=125px /></div>
          <div style='position:absolute;top:628px;left:127px'><input width=125px /></div>
          <div style='position:absolute;top:665px;left:41px'><input width=125px /></div>
            <img src="img/FriendsPlus/Page4/E3/1.jpg" style='height:20cm'/>
        </div>
          
        `,
        answer: [
          "check",
          "follow",
          "post",
          "spend time",
          "connect",
          "chat",
          "share",
          "update",
        ],
      },
    ],
  },
  4: {
    unit: "Starter",
    id: "SW9-2024-Starter-P4-E4",
    exerciseKey: "img/FriendsPlus/Page4/Key/E4answerKey.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign:'center',
    // isHello:true,
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 560,
      background: "none",
      paddingTop: 3,
    },
    textareaStyle: {
      width: 940,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#203c8f",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "don’t usually / often",
          "every day",
          "(nearly) always",
          "never",
          "two or three times a week",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `How often do you use social media?
Write a short paragraph explaining how you use it.
Use some of the words on this page and the phrases
in the box.`,
        color: "#203c8f",
        width: "27cm",
        left: 50,
        star: 3,
        colorStar: "#95b0db",
      },
    ],
    KeyMau: true,
    isHello: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <hintbox id=0></hintbox>
       <div style='width:26cm'>
        I use social media every day. I post comments on YouTube videos two or three times a week …
       </div>
       <textarea id=0 rows=5></textarea>


          
        `,
        answer: [
          "I use social media all the time. I usually spend hours on Twitter and Instagram. I follow celebrities and I always read the comments and look at the photos they share every day.",
        ],
      },
    ],
  },
  5: {
    unit: "Starter",
    id: "SW9-2024-Starter-P4-E5",
    exerciseKey: "img/FriendsPlus/Page4/Key/E5answerKey.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign:'center',
    isHello: true,
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 542,
      background: "none",
      paddingTop: 3,
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    hintBox: [
      {
        src: ["how many", "what", "when", "<s>where</s>", "who", "why"],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Look at the photo of China’s Terracotta 
          Army soldiers. Write four questions about them 
          using the past, present and future passive.`,
        color: "#203c8f",
        left: 50,
        star: 3,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <img src="img/FriendsPlus/Page4/E5/1.jpg" style='height:6cm'/>
        <div style='margin-left:20px'>
          <span style='text-decoration: underline; text-decoration-style: dotted'>How were the soldiers found?&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span><br>
          #<br>#<br>#<br>#<br>
        </div>
        </div>
          
        `,
        answer: ["", "", "", ""],
      },
    ],
  },
};

export default json;

import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P52-E1",
    exerciseKey: "/img/FriendsPlus/Page52/key/e1-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",

    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 80,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 100,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `  Complete the words. Then match clues a–f
        with words 1–6.

`,
        left: 50,
        star: 1,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    // KeyMau: true,
    // isHello: true,
    questions: [
      {
        title: `
        <div style='margin-top:20px;position:relative'>
          <img src='/img/FriendsPlus/Page52/11.jpg' style='width:600px' />
          <div style='position:absolute;top:120px;left:103px' > <input id=0 /> </div>
          <div style='position:absolute;top:120px;left:135px' > <input id=1 /> </div>
          <div style='position:absolute;top:223px;left:102px' > <input id=2 /> </div>
          <div style='position:absolute;top:223px;left:133px' > <input id=3 /> </div>
          <div style='position:absolute;top:21px;left:448px' > <input id=4 /> </div>
          <div style='position:absolute;top:121px;left:464px' > <input id=5 /> </div>
          <div style='position:absolute;top:121px;left:489px' > <input id=6 /> </div>
          <div style='position:absolute;top:220px;left:466px' > <input id=7 /> </div>
          <div style='position:absolute;top:220px;left:488px' > <input id=8 /> </div>
          <div style='position:absolute;top:300px;left:40px' > <input id=9 /> </div>
          <div style='position:absolute;top:332px;left:42px' > <input id=10 /> </div>
          <div style='position:absolute;top:434px;left:40px' > <input id=11 /> </div>
          <div style='position:absolute;top:503px;left:40px' > <input id=12 /> </div>
          <div style='position:absolute;top:537px;left:40px' > <input id=13 /> </div>
          

        </div>
      
        `,
        answer: [
          "u",
          "i",
          "u",
          "r",
          "u",
          "e",
          "a",
          "r",
          "a",
          "4",
          "2",
          "6",
          "5",
          "3",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P52-E2",
    exerciseKey: "/img/FriendsPlus/Page52/key/e2-key.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 200,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `  Choose the correct answers.
`,
        left: -50,
        star: 2,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: [],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "58px",
              left: "232px",
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          // borderBottom: "none",
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
          fontSize: 22,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          marginLeft: 30,
          // fontSize: 19,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a|&nbspsprained&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|b|&nbspbroken&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|c|&nbspbruised&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|d|&nbspcut",
          "a|&nbspburn&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|b|&nbspsprain&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|c|&nbspcut&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|d|&nbspbruise",
          "a|&nbspburned&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|b|&nbspbruised&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|c|&nbspinjured&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|d|&nbspcut",
          "a|&nbspa&nbspburn&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|b|&nbspa&nbspsprain&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|c|&nbspan&nbspinjury&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|d|&nbspa&nbspbreak",
          "a|&nbspinjure&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|b|&nbspsprained&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|c|&nbspinjury&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|d|&nbspburned",
          "a|&nbspburn&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|b|&nbspcut&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|c|&nbspbreak&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp|d|&nbspbruise",
        ],
        answers: ["0-4", "1-8", "2-0", "3-4", "4-8", "5-12"],
        initialValue: [],
      },
      Layout: `
        
        <div style='margin-top:20px;position:relative'>
            That footballer can’t play because he’s ...............<br>
            
            <b>a</b> bruised  <b>b</b> cut  <b>c</b> broken
            <b> <span style='border:1px solid;border-radius:50%;'>d</span> </b> injured </span>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 1 </span>
            <span >  Enes has been in hospital for five weeks because he’s........... his leg.
              <input id=0 type=Circle  /> 
             </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 2 </span>
            <span >  Be careful! Don’t........... your finger on that big knife! <br>
              <input id=1 type=Circle /> 
             </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 3 </span>
            <span >  Olivia has been in the hot sun and now her arms are........... <br>
              <input id=2 type=Circle /> 
             </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 4 </span>
            <span >  My mum fell over and hurt her ankle. But it’s OK, it’s only........... and she can walk slowly <br>
              <input id=3 type=Circle /> 
             </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 5 </span>
            <span >  George has got a serious leg............ <br>
              <input id=4 type=Circle /> 
             </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 6 </span>
            <span >  I walked into a chair earlier and now I’ve got a big blue and green........... on my leg <br>
              <input id=5 type=Circle /> 
             </span>
          </div>

       
       </div>
      `,
    },
  },
  3: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P52-E3",
    exerciseKey: "/img/FriendsPlus/Page52/key/e3-key.png",
    audio: "/Audios/1.17.mp3",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 200,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=17 colorImg=#f29046 ></headphone>  Listen and draw lines from the
        names (1–6) to the people (a–f) in the picture.
`,
        left: -50,
        width: "27cm",
        star: 2,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: [],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        lineToNotCenter: true,
        widthLineto: 3,
        colorLineTo: "blue",
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "362px",
              left: "321px",
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "362px",
              left: "552px",
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "362px",
              left: "737px",
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "210px",
              left: "321px",
              width: "80px",
              height: "200px",
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "210px",
              left: "468px",
              width: "80px",
              height: "200px",
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 7
          // 0

          {
            boxMatchStyle: {
              position: "absolute",
              top: "210px",
              left: "391px",
              width: "70px",
              height: "200px",
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "210px",
              left: "575px",
              width: "80px",
              height: "200px",
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "210px",
              left: "641px",
              width: "80px",
              height: "200px",
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "21px",
              left: "735px",
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1

          {
            boxMatchStyle: {
              position: "absolute",
              top: "21px",
              left: "546px",
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
        ],
        answers: ["5-8", "6-9", "0-7", "1-4", "2-3"],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          // borderBottom: "none",
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
          fontSize: 22,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          marginLeft: 30,
          // fontSize: 19,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [],
        answers: [],
        initialValue: [],
      },
      Layout: `
        <div style='margin-top:20px;position:relative' >
        <img src='/img/FriendsPlus/Page52/12.jpg' style='width:600px;margin-left:200px' />
        <input id=0 type=boxMatch />
        <input id=1 type=boxMatch />
        <input id=2 type=boxMatch />
        <input id=3 type=boxMatch />
        <input id=4 type=boxMatch />
        <input id=5 type=boxMatch />
        <input id=6 type=boxMatch />
        <input id=7 type=boxMatch />
        <input id=8 type=boxMatch />
        <input id=9 type=boxMatch />
        
        </div>
        
      `,
    },
  },
  4: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P52-E4",
    exerciseKey: "/img/FriendsPlus/Page52/key/e4-key.png",
    audio: "/Audios/1.17.mp3",
    video: "",
    component: T6,
    // textAlign: "center",

    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",

      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      background: "none",
      width: 170,
      fontWeight: "bold",
      height: 90,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `  <headphone name=17 colorImg=#f29046 ></headphone>  Listen again. Complete the
        receptionist’s notes.

`,
        left: 50,
        star: 2,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    // KeyMau: true,
    // isHello: true,
    questions: [
      {
        title: `
        <div style='margin-top:20px;position:relative'>
          <img src='/img/FriendsPlus/Page52/13.jpg' style='width:650px;margin-left:70px' />
          <div style='position:absolute;top:113px;left:334px' > #</div>
          <div style='position:absolute;top:127px;left:534px' > #</div>
          <div style='position:absolute;top:185px;left:328px' > #</div>
          <div style='position:absolute;top:198px;left:534px' > #</div>
          <div style='position:absolute;top:255px;left:334px' > #</div>
          <div style='position:absolute;top:271px;left:502px' > #</div>
          <div style='position:absolute;top:342px;left:334px' > #</div>
          <div style='position:absolute;top:335px;left:490px' > <textarea id=9 rows=2 ></textarea> </div>
          <div style='position:absolute;top:428px;left:334px' > #</div>
          <div style='position:absolute;top:442px;left:534px' > #</div>
          
          

        </div>
      
        `,
        answer: [
          "burn",
          "sun",
          "sprain",
          "bus",
          "break",
          "mountains",
          "cut",
          "break",
          "door",
          "mountain bike",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "SW9-2024-U8-P52-E5",
    exerciseKey: "",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",

    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      // fontSize: 17,
      fontWeight: "bold",
      width: 180,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 900,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f29046",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "ankle",
          "arm",
          "hand",
          "finger",
          "foot",
          "head",
          "knee",
          "leg",
          "shoulder",
          "wrist",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `  Imagine you’ve got an injury and you go to
        the doctor’s. Explain what it is and how it happened.
        Write about four different injuries. Use the words on
        this page and in the box to help you.

`,
        left: 50,
        star: 3,
        color: "#f29046",
        colorStar: "#fdc89c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    // KeyMau: true,
    // isHello: true,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='margin-top:20px;position:relative'>
        <b><span style='border-bottom:2px dotted' >I've sprained my ankle. I was climbing a tree and I fell</span></b>
        <br><br>

        <textarea rows=10 id=0 ></textarea>
          
          

        </div>
      
        `,
        answer: [""],
      },
    ],
  },
};

export default json;

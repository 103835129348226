import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "LFP-Starter",
    id: "SW9-2024-LFP-Starter-P60-E1",
    exerciseKey: "/img/FriendsPlus/Page60/key/e1-key.jpg",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 200,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `  Choose the correct words.

`,
        left: -50,
        star: 0,
        color: "#71699c",
        colorStar: "#574d89",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: [],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "58px",
              left: "232px",
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          // borderBottom: "none",
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
          fontSize: 22,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          // fontSize: 19,
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",

          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "’re&nbspwatching / watch",
          "don’t&nbspunderstand / ’re&nbspnot&nbspunderstanding",
          "never&nbspcheck / ’m&nbspnever&nbspchecking",
          "Are&nbspyou&nbspchatting / Do&nbspyou&nbspchat",
          "does&nbspMark&nbspplay / is&nbspMark&nbspplaying",
          "always&nbspuses / ’s&nbspalways&nbspusing",
        ],
        answers: ["0-0", "1-0", "2-0", "3-4", "4-4", "5-4"],
        initialValue: [],
      },
      Layout: `
        <span>I <b><span style='border:1px solid;border-radius:50%'>upload</span></b> / <b>’m uploading</b> a video once a month.</span>

        <div style='margin-top:20px;'>
           <span style='font-weight:bold;'>1 </span> 
           <span style='margin-left:10px;'>‘Where are Max and Ivan?’  ‘They </span>
           <input id=0 type=Circle />
           that new music video upstairs.’
        </div>

        <div style='margin-top:20px;'>
           <span style='font-weight:bold;'>2 </span> 
           <span style='margin-left:10px;'>We </span>
           <input id=1 type=Circle />
           how to use this app.
        </div>

        <div style='margin-top:20px;'>
           <span style='font-weight:bold;'>3 </span> 
           <span style='margin-left:10px;'>I </span>
           <input id=2 type=Circle />
           my phone when I’m in class.
        </div>

        <div style='margin-top:20px;'>
           <span style='font-weight:bold;'>4 </span> 
           <input id=3 type=Circle />
           to your cousin on Skype once a week?
        </div>

         <div style='margin-top:20px;'>
           <span style='font-weight:bold;'>5 </span> 
           <span style='margin-left:10px;'>What video game </span>
           <input id=4 type=Circle />
           now? It’s really noisy!
        </div>

         <div style='margin-top:20px;'>
           <span style='font-weight:bold;'>6 </span> 
           <span style='margin-left:10px;'>I’m still angry with Julia. She </span>
           <input id=5 type=Circle />
           my tablet.
        </div>
      `,
    },
  },
  2: {
    unit: "LFP-Starter",
    id: "SW9-2024-LFP-Starter-P60-E2",
    exerciseKey: "/img/FriendsPlus/Page60/key/e2-key.jpg",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 20,
      fontWeight: "bold",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#f0ab9c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "alive",
          "fatal",
          "frightened",
          "ridiculous",
          "<s>risky</s>",
          "excited",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: ` Complete the sentences with the correct present
        simple or continuous form of the verbs in brackets.

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                    <span>We <b><span style='border-bottom:2px dotted;'>live</span></b> (live) in the centre of Prague.</span>
                </div>
                <p></p>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>1</span>
                    <span> I <input id=0  /> (download) a film now so that we can
                    watch it tonight </span>
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>2</span>
                    Tina <input id=1  />
                    <span> (not update) her status every day. </span>
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>3</span>
                    Adam usually <input id=2  />
                    <span> (take) the bus to school in the morning. </span>
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>4</span>
                    <input id=3  /> your grandparents
                    <span> Did she learn anything from the unpleasant experience at the supermarket? </span>
                   <input id=4  /> (exercise) once a week?
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>5</span>
                    Get your own social media account! You
                    <input id=5  /> 
                    <span>always </span>
                    <input id=6  /> (use) mine!
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>6</span>
                    Why <input id=7  /> you <input id=8  />
                    <span>(chat) online right now? </span>
                </div>

            
        </div>
      
        `,
        answer: [
          "'m downloading",
          "doesn't update",
          "takes",
          "Do",
          "exercise",
          "'re",
          "using",
          "are",
          "chatting",
        ],
      },
    ],
  },
  3: {
    unit: "LFP-Starter",
    id: "SW9-2024-LFP-Starter-P60-E3",
    exerciseKey: "/img/FriendsPlus/Page60/key/e3-key.jpg",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 20,
      fontWeight: "bold",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#71699c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "trust",
          "invest",
          "book",
          "ask",
          "go",
          "pursue",
          "cook",
          "<s>start</s>",
          "wear",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: ` Complete the sentences with to-infinitive of the verbs in the box.

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
       <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                    <span>When <b><span style='border-bottom:2px dotted;'>to start</span></b> </span>
                      the project depends on various factors.
                </div>
                <p></p>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>1</span>
                    We are still discussing where
                     <input id=0  />
                    <span> on vacation this year. </span>
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>2</span>
                    What <input id=1  />
                    <span> for dinner is always a tough decision </span>
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>3</span>
                    Some children still wonder why <input id=2  />
                    <span> further education </span>
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>4</span>
                    We haven't found out whom <input id=3  />
                    <span> with this secret. </span>
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>5</span>
                    Can you recommend what <input id=4  />
                    <span> to the graduation party? </span>
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>6</span>
                    He needs to make a decision on where <input id=5  />
                    <span> his savings. </span>
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>7</span>
                    Do you know who <input id=6 />
                    <span> for advice in this situation? </span>
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>8</span>
                    My father is considering when <input id=7 />
                    <span> the flights for our trip. </span>
                </div>

              
            
        </div>
      
        `,
        answer: [
          "to go",
          "to cook",
          "to pursue",
          "to trust",
          "to wear",
          "to invest",
          "to ask",
          "to book",
        ],
      },
    ],
  },
  4: {
    unit: "LFP-Starter",
    id: "SW9-2024-LFP-Starter-P60-E4",
    exerciseKey: "/img/FriendsPlus/Page60/key/e4-key.jpg",
    audio: "",
    video: "",
    component: Circle_Write,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 20,
      fontWeight: "bold",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#71699c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "trust",
          "invest",
          "book",
          "ask",
          "go",
          "pursue",
          "cook",
          "<s>start</s>",
          "wear",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: ` Correct the question tags in bold. Two of them are
        correct.

`,
        left: -30,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          textAlign: "center",
          background: "none",
          fontSize: 24,
          // borderBottom: "none",
          borderBottom: "2px dotted",
          width: 200,
          height: 32,
          paddingTop: 2,
        },
        answers: ["has he?", "", "don’t you?", "is there?", "", "am I?"],
        initialValue: [],
      },
      Circle: {
        TickRong: true,
        initialWordStyle: {
          marginLeft: 10,
          padding: "1px 10px",
          border: "2px solid",
          borderRadius: 5,
          color: "transparent",
          borderColor: "black",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          borderRadius: 5,
          // borderRadius: "50%",
          color: "black",
          // textDecoration: "line-through",
          border: "2px solid",
          borderColor: "black",
        },
        limitSelect: 1,
        listWords: ["✓", "✓", "✓", "✓", "✓", "✓", "✓"],
        answers: ["1-0", "4-0"],
        initialValue: [],
      },
      Layout: `

            We can use the GPS, <b>don’t we</b>? <u style='text-decoration-style: dotted'>&emsp;calculator&emsp;</u>
            <div style='line-height:45px;width:27cm'>
              <b>1</b>&ensp;Marek hasn’t got a camera, <b>does he</b>?. # <input id=0 type=Circle />  <br>
              <b>2</b>&ensp;It didn’t rain today, <b>did it</b>?. # <input id=1 type=Circle />  <br>
              <b>3</b>&ensp;You like this film, <b>doesn’t you</b>?. # <input id=2 type=Circle />  <br>
              <b>4</b>&ensp;There isn’t much time, <b>is it</b>?. # <input id=3 type=Circle />  <br>
              <b>5</b>&ensp;They’re eating dinner, <b>aren’t they</b>?. # <input id=4 type=Circle />  <br>
              <b>6</b>&ensp;I’m not too late for the party, <b>aren’t I</b>?. # <input id=5 type=Circle />  <br>
            </div>

  `,
    },
  },
  5: {
    unit: "LFP-Starter",
    id: "SW9-2024-LFP-Starter-P60-E5",
    exerciseKey: "/img/FriendsPlus/Page60/key/e5-key.jpg",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      fontSize: 18,
      fontWeight: "bold",
      width: 250,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#71699c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "come",
          "enjoy",
          "<s>have</s>",
          "not listen",
          "play",
          "not practise",
          "watch",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: ` Complete the blog post with used to and the verbs.

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
       <div style='margin-top:20px;position:relative'>
          <img src='/img/FriendsPlus/Page60/13.jpg' style='width:600px;' />
          <div style='position:absolute;top:141px;left:242px' > <input id=0 /> </div>
          <div style='position:absolute;top:168px;left:167px' > <input id=1 /> </div>
          <div style='position:absolute;top:196px;left:290px' > <input id=2 /> </div>
          <div style='position:absolute;top:255px;left:142px' > <input id=3 /> </div>
          <div style='position:absolute;top:364px;left:3px' > <input id=4 /> </div>
          <div style='position:absolute;top:417px;left:3px' > <input id=5 /> </div>

        </div>
      
        `,
        answer: [
          "used to play",
          "used to come",
          "used to enjoy",
          "didn't use to practise",
          "didn't use to listen",
          "used to watch",
        ],
      },
    ],
  },
  6: {
    unit: "LFP-Starter",
    id: "SW9-2024-LFP-Starter-P60-E6",
    exerciseKey: "/img/FriendsPlus/Page60/key/e6-key.jpg",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 20,
      fontWeight: "bold",
      width: 450,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#71699c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "trust",
          "invest",
          "book",
          "ask",
          "go",
          "pursue",
          "cook",
          "<s>start</s>",
          "wear",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: ` Order the words to make sentences.

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                    <span>TV / watch / time / the / They / all 
                     <b><span style='border-bottom:2px dotted;'> They watch TV all the time.</span></b>
                     </span>
                      
                </div>
                <p></p>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>1</span>
                    on / never / Oleg and Alex / go / that / website
                     <input id=0  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>2</span>
                   always / is / sister / social media / on / My
                     <input id=1 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>3</span>
                   week / chat / a / they / three / times / Do / ?
                     <input id=2  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>4</span>
                   I / stream / usually / films / don’t
                     <input id=3  />
                </div>

        </div>
      
        `,
        answer: [
          "Oleg and Alex never go on that website.",
          "My sister is always on social media.",
          "Do they chat three times a week?",
          "I don't usually stream films.",
        ],
      },
    ],
  },
};

export default json;

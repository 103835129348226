import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P39-E1",
    exerciseKey: "/img/FriendsPlus/Page39/key/e1-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      fontSize: 22,
      width: 150,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "herself (x2)",
          "himself (x2)",
          "itself (x2)",
          "<s>myself</s>",
          "myself",
          "ourselves (x2)",
          "themselves (x2)",
          "yourself (x2)",
          "yourselves (x2)",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `   Complete the table with the words.


`,
        left: 50,
        star: 1,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-left:100px'> <hintbox id=0 ></hintbox>  </div>
        
           <div style='margin-top:20px;position:relative'>
          <img src='/img/FriendsPlus/Page39/9.jpg' style='width:600px;margin-left:100px' />
          <div style='position:absolute;top:112px;left:572px'> <input id=0 /> </div>
          <div style='position:absolute;top:169px;left:222px'> <input id=1 /> </div>
          <div style='position:absolute;top:193px;left:412px'> <input id=2 /> </div>
          <div style='position:absolute;top:251px;left:267px'> <input id=3 /> </div>
          <div style='position:absolute;top:284px;left:136px'> <input id=4 /> </div>
          <div style='position:absolute;top:274px;left:420px'> <input id=5 /> </div>
          <div style='position:absolute;top:274px;left:547px'> <input id=6 /> </div>
          <div style='position:absolute;top:342px;left:219px'> <input id=7 /> </div>
          <div style='position:absolute;top:342px;left:590px'> <input id=8 /> </div>
          <div style='position:absolute;top:449px;left:249px'> <input id=9 /> </div>
          <div style='position:absolute;top:471px;left:412px'> <input id=10 /> </div>
          <div style='position:absolute;top:529px;left:251px'> <input id=11 /> </div>
          <div style='position:absolute;top:553px;left:404px'> <input id=12 /> </div>
          <div style='position:absolute;top:611px;left:262px'> <input id=13 /> </div>
          <div style='position:absolute;top:633px;left:403px'> <input id=14 /> </div>
          
          


        </div>
      
        `,
        answer: [
          "myself",
          "yourself",
          "yourself",
          "himself",
          "herself",
          "himself",
          "herself",
          "itself",
          "itself",
          "ourselves",
          "ourselves",
          "yourselves",
          "yourselves",
          "themselves",
          "themselves",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P39-E2",
    exerciseKey: "/img/FriendsPlus/Page39/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      width: 70,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "herself (x2)",
          "himself (x2)",
          "itself (x2)",
          "<s>myself</s>",
          "myself",
          "ourselves (x2)",
          "themselves (x2)",
          "yourself (x2)",
          "yourselves (x2)",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `   Complete the questions with pronouns
                and then write R (reflexive) or E (emphatic).


`,
        left: 50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        
        <div style='margin-top:20px;'>
          This poem is great! Did you write it
          <span style='border-bottom:2px dotted;'><b>yourself</b></span>?
          <span style='border-bottom:2px dotted;'><b>E</b></span>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 1 </span>
            <span > Shall we design the website 
            <input id=0 /> selves?
            <input id=1 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 2 </span>
            <span > Would you call 
            <input id=2 /> self an extrovert?
            <input id=3 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 3 </span>
            <span > Did you two paint this room 
            <input id=4 /> selves?
            <input id=5 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 4 </span>
            <span > How does Victoria see
            <input id=6 /> self? Does she think that she’s a leader or a follower?
            <input id=7 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 5 </span>
            <span > Shall I talk to the teacher
            <input id=8 /> self or will you talk to her?
            <input id=9 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 6 </span>
            <span > Did Onur hurt
            <input id=10 /> self playing basketball?
            <input id=11 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 7 </span>
            <span > Are your friends going to introduce 
            <input id=12 /> selves to everybody?
            <input id=13 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 8 </span>
            <span > Can that cat open the window 
            <input id=14 /> self?
            <input id=15 /> 
          </div>

          
        </div>
      
        `,
        answer: [
          "our",
          "E",
          "your",
          "R",
          "your",
          "E",
          "her",
          "R",
          "my",
          "E",
          "him",
          "R",
          "them",
          "R",
          "it",
          "E",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P39-E3",
    exerciseKey: "/img/FriendsPlus/Page39/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      width: 150,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "herself",
          "himself",
          "myself",
          "ourselves",
          "themselves",
          "<s>yourself</s>",
          "yourselves",
          "",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `   Complete the dialogue with the pronouns in the box.

`,
        left: 50,
        star: 2,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
           <div style='margin-top:20px;'>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > Ben </span>
                <span > Hey! Nice bike! Did you buy it </span>
                <span style='border-bottom:2px dotted;'><b>yourself</b></span>?
              </div>
              
              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > Leo </span>
                <span > Hey. No, I didn’t. My brother gave it to me. He built <sup><b>1</b></sup></span>
                <input id=0 />
                <span> a racing bike last year <br> and he didn’t need this bike any more.</span>
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > Ben </span>
                <span >  Wow! Your family is so clever. You always
                make things <sup><b>2</b></sup></span>
                <input id=1 />! <br>
                <span> Where have you been today? </span>
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > Leo </span>
                <span > I took Andy and Rose for a bike ride up
                the mountains. They enjoyed <sup><b>3</b></sup></span>
                <input id=2 />
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > Ben </span>
                <span > Cool. Did you go a long way? </span>
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > Leo </span>
                <span >Yeah, about twenty km, and then we
                found <sup><b>4</b></sup></span>
                <input id=3 />
                near an old castle. <br> But on the way home, Rose fell off her bike.
                She hurt <sup><b>5</b></sup>  <input id=4 />
                a bit and her bike was broken.
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > Ben </span>
                <span > Oh no! What did you do? </span>
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > Leo </span>
                <span >She was fine in the end. I repaired the bike
                found <sup><b>6</b></sup> </span>
                <input id=5 /> You should join us next time!
              </div>

          
          
        </div>
      
        `,
        answer: [
          "himself",
          "yourselves",
          "themselves",
          "ourselves",
          "herself",
          "myself",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P39-E4",
    exerciseKey: "/img/FriendsPlus/Page39/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      width: 800,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "herself",
          "himself",
          "myself",
          "ourselves",
          "themselves",
          "<s>yourself</s>",
          "yourselves",
          "",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `   Answer the questions using complete
                sentences.

`,
        left: 50,
        star: 3,
        color: "#24bbd4",
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    KeyMau: true,
    isHello: true,
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
           <div style='margin-top:20px;'>

              <div style='margin-top:20px;'>
              <span > When was the last time that you really enjoyed yourself? </span> <br>
                <span style='border-bottom:2px dotted;'><b> I really enjoyed myself at a huge concert in
                Liverpool with all my friends last month.</b></span>
              </div>
              
              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 1</span>
                <span > Has anyone you know ever taught himself /
                herself to play an instrument or a sport? </span> <br>
                <input id=0 />
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 2</span>
                <span >Would you describe yourself as an optimist or a
                pessimist? Why?</span> <br>
                <input id=1 />
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 3</span>
                <span> Have you and your friends ever made something
                yourselves?</span> <br>
                <input id=2 />
              </div>

              

          
          
        </div>
      
        `,
        answer: [
          "Yes, my brother taught himself to play golf on holiday last year",
          "I'd describe myself as a pessimist because I always think the worst will happen!",
          "Yes, we've made some posters ourselves for our bedrooms",
        ],
      },
    ],
  },
};

export default json;

import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P42-E1",
    exerciseKey: "/img/FriendsPlus/Page42/key/e1-key.png",
    audio: "/Audios/1.14.mp3",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 22,
      width: 50,
      paddingTop: 10,
      maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["in", "that (x2)", "where", "which", "<s>who</s>", "whose"],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=14 colorImg=#f0ab9c></headphone>  Read and listen to the profile. Match
paragraphs A–F with titles 1–6.

`,
        left: 50,
        star: 1,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        
         <div style='margin-top:20px;'>

          <div style='display:flex;'>
            <div>
              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 1 </span>
                <span> Training to be the best </span>
                <span style='border-bottom:2px dotted;font-weight:bold'> C </span>
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 2 </span>
                <span> What’s she really like? </span>
                <input id=0 /> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 3 </span>
                <span> Special achievements </span>
                <input id=1 /> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 4 </span>
                <span> Our verdict </span>
                <input id=2 /> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 5 </span>
                <span> About Ashima </span>
                <input id=3 /> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 6 </span>
                <span> Her sport </span>
                <input id=4 /> 
              </div>
            </div>
          
            <div>
              <img src='/img/FriendsPlus/Page42/9.jpg' style='width:600px'/>
            </div>
          </div>
          
        </div>
      
        `,
        answer: ["D", "E", "F", "A", "B"],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P42-E2",
    exerciseKey: "/img/FriendsPlus/Page42/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 22,
      width: 50,
      paddingTop: 10,
      maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["in", "that (x2)", "where", "which", "<s>who</s>", "whose"],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Which of ideas 1–6 can you find in
        the profile? In which paragraph are they?

`,
        left: 50,
        star: 2,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        
         <div style='margin-top:20px;'>
                <span> Bouldering is the name of a special type of climbing that doesn’t use ropes </span>
                <span style='border-bottom:2px dotted;font-weight:bold'> B </span>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span>
                    <span> Learning to climb is easy </span>
                    <input id=0 /> 
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 2 </span>
                    <span> Bouldering isn’t an activity that suits everybody </span>
                    <input id=1 /> 
                </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 3 </span>
                <span> Bouldering is popular with children </span>
                <input id=2 /> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 4 </span>
                <span> You can’t become a good climber quickly </span>
                <input id=3 /> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 5 </span>
                <span> Young people like Shiraishi are rare </span>
                <input id=4 /> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 6 </span>
                <span> Climbers tend to be sociable </span>
                <input id=5 /> 
              </div>
          
        </div>
      
        `,
        answer: ["-", "B", "-", "C", "F", "-"],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P42-E3",
    exerciseKey: "/img/FriendsPlus/Page42/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 22,
      width: 1000,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["in", "that (x2)", "where", "which", "<s>who</s>", "whose"],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Answer the questions. Write complete sentences.

`,
        left: 50,
        star: 3,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    questions: [
      {
        title: `
        
         <div style='margin-top:20px;'>
                <span> What type of clothing is used for bouldering? </span>
                <span style='border-bottom:2px dotted;font-weight:bold'> Special climbing shoes are used. </span>

                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span>
                    <span> How and when did Shiraishi first learn to climb? </span> <br>
                    <input id=0 /> 
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 2 </span>
                    <span> What do some journalists think when they
                      interview Shiraishi? Why? 
                    </span> <br>
                    <input id=1 /> 
                </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 3 </span>
                <span> What did Shiraishi achieve in Santa Linya? </span> <br>
                <input id=2 /> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 4 </span>
                <span> Which magazine did Shiraishi appear in? Why? </span> <br>
                <input id=3 /> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 5 </span>
                <span> Why do you think the writer of the profile says
                        that we will see more of Shiraishi?  </span> <br>
                <input id=4 /> 
              </div>
          
        </div>
      
        `,
        answer: [
          "She started teaching herself to climb in Central Park, New York, at the age of six",
          "They think that she's an introvert because she gives short answers to their questions.|They think that she is an introvert because she gives short answers to their questions.",
          "She became the first female to climb the dangerous rocks and complete the routes",
          "She appeared in Time magazine as one of the top teenagers of the year",
          "I think the writer says that because Shiraishi is very talented and unusual.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SW9-2024-U6-P42-E4",
    exerciseKey: "/img/FriendsPlus/Page42/key/e4-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 22,
      width: 50,
      paddingTop: 10,
      maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["in", "that (x2)", "where", "which", "<s>who</s>", "whose"],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `VOCABULARY PLUS Match idioms 1–6
          with meanings a–f.

`,
        left: 50,
        star: 2,
        color: "#dd2e3d",
        colorStar: "#f0ab9c",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        
         <div style='margin-top:20px;'>
          <div style='display:flex'>
            <div>
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 1 </span>
                    <span> You’re at the top of your game. </span>
                    <span style='border-bottom:2px dotted;font-weight:bold'> b </span>
                </div>
          
                <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > 2 </span>
                    <span> Throw in the towel. </span>
                    <input id=0 /> 
                </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 3 </span>
                <span> Give it your best shot </span> 
                <input id=1 /> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 4 </span>
                <span> The ball is in your court </span> 
                <input id=2 /> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 5 </span>
                <span> Come along once in a blue moon  </span> 
                <input id=3/> 
              </div>

              <div style='margin-top:20px;'>
                <span style='margin-right:10px;font-weight:bold;' > 6 </span>
                <span> Live on the edge </span> 
                <input id=4/> 
              </div>
            
            </div>

            <div style='margin-left:60px'>
              <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > a </span>
                    <span> Try as hard as you can. </span>
              </div>

              <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > b </span>
                    <span> You’re the best that you can be. </span>
              </div>

              <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > c </span>
                    <span> Be involved in dangerous activities. </span>
              </div>

              <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > d </span>
                    <span> Not happen very often. </span>
              </div>

              <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > e </span>
                    <span> Give up and stop doing something. </span>
              </div>

              <div style='margin-top:20px;'>
                    <span style='margin-right:10px;font-weight:bold;' > f </span>
                    <span> The next decision is your responsibility. </span>
              </div>
            
            
            </div>
          
          
          </div>
                
          
        </div>
      
        `,
        answer: ["e", "a", "f", "d", "c"],
      },
    ],
  },
};

export default json;

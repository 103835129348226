import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 5",
    id: "SW9-2024-U5-P37-E1",
    exerciseKey: "/img/FriendsPlus/Page37/key/e1-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "encourage",
          "equip",
          "investigate",
          "<s>popular</s>",
          "react",
          "similar",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: ` Complete the phrases with the correct form of the verbs in brackets. Then match 1–4 with a–e.

`,
        left: 50,
        star: 1,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
                        
          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > a </span>
            <span > a lot of people suggest
            <input id=0 /> (stop) animal testing.
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > b </span>
            <span > some governments suggest </span> 
            <input id=1 /> (reduce) their space programmes.
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > c </span>
            <input id=2 /> (develop) nuclear power.
          </div>
          
          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > d </span>
            <span > people suggest <span style='border-bottom:2px dotted;'><b>finding</b></span> (find) out solutions   to convert the toxic waste into a form suitable for disposal.
            </span>
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > e </span>
            <input id=3 /> (sign up for) a public speaking course
          </div> <br>

          <span>The problem of radioactive waste is serious;therefore <span style='border-bottom:2px dotted;'><b>d</b></span></span>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 1 </span>
            <span > Space programmes were too expensive; thus, </span>
            <input id=4 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 2 </span>
            <span > Because animal rights are important concerns worldwide, </span>
            <input id=5 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 3 </span>
            <span > Seeing that his daughter hopes to become a diplomat, he suggests </span>
            <input id=6 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 4 </span>
            <span > The need for more energy is on the increase. It makes scientists suggest  </span>
            <input id=7 /> 
          </div>


        </div>
      
        `,
        answer: [
          "stopping",
          "reducing",
          "developing",
          "signing up for",
          "b",
          "a",
          "e",
          "c",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SW9-2024-U5-P37-E2",
    exerciseKey: "/img/FriendsPlus/Page37/key/e2-key.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      width: 900,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#24bbd4",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "encourage",
          "equip",
          "investigate",
          "<s>popular</s>",
          "react",
          "similar",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: ` Order the words to complete the sentences about factory farming.

`,
        left: 50,
        star: 2,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    questions: [
      {
        title: `
        <div style='margin-top:20px;'>
          factory farming / suggests / growing population / building / The <br >
          <span style='border-bottom:2px dotted;'><b>The growing population suggests building factory
          farming.</b></span>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 1 </span>
            <span > meat / The increasing demand / more intensive / suggests /of / now / farming / having <br>
            <input id=0 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 2 </span>
            <span > A lot of food / produced / is / efficiently / invention / due / the / to / factory / of / farming </span> <br>
            <input id=1 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 3 </span>
            <span > don't / The animals / or sunlight / get exercise / inside / live / because / they </span> <br>
            <input id=2 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 4 </span>
            <span > Many / suffer / animals / in factory farms / natural / they / because / eat / food / don't </span> <br>
            <input id=3 /> 
          </div>

          <div style='margin-top:20px;'>
            <span style='margin-right:10px;font-weight:bold;' > 5 </span>
            <span > suggests / factory farming / animals / The poor conditions / my being against / of </span> <br>
            <input id=4 /> 
          </div>


        </div>
      
        `,
        answer: [
          "The increasing demand of meat suggests having more intensive farming now.",
          "A lot of food is efficiently produced due to the invention of factory farming.",
          "The animals don't get exercise or sunlight because they live inside|The animals do not get exercise or sunlight because they live inside",
          "Many animals suffer in factory farms because they don't eat natural food.|Many animals suffer in factory farms because they do not eat natural food.",
          "The poor conditions of animals suggest my being against factory farming.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SW9-2024-U5-P37-E3",
    exerciseKey: "/img/FriendsPlus/Page37/key/e3-key.png",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      width: 175,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#00aa4c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<s>against</s>",
          "because",
          "believe",
          "the first place",
          "if",
          "opinion",
          "summary",
          "unless",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: ` Read and complete the opinion passage with the words in the box.

`,
        left: 50,
        star: 2,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='margin-left:200px'> <hintbox id=0></hintbox>  </div>
        
        <div style='margin-top:20px;position:relative'>
          <img src='/img/FriendsPlus/Page37/9.jpg' style='width:600px;margin-left:200px' />
          <div style='position:absolute;top:259px;left:250px'> <input id=0 /> </div>
          <div style='position:absolute;top:328px;left:220px'> <input id=1 /> </div>
          <div style='position:absolute;top:363px;left:491px'> <input id=2 /> </div>
          <div style='position:absolute;top:465px;left:223px'> <input id=3 /> </div>
          <div style='position:absolute;top:532px;left:300px'> <input id=4 /> </div>
          <div style='position:absolute;top:635px;left:255px'> <input id=5 /> </div>
          <div style='position:absolute;top:635px;left:518px'> <input id=6 /> </div>


        </div>
      
        `,
        answer: [
          "the first place",
          "because",
          "believe",
          "unless",
          "if",
          "summary",
          "opinion",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SW9-2024-U5-P37-E4",
    exerciseKey: "",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      width: 175,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 800,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#00aa4c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<s>against</s>",
          "because",
          "believe",
          "the first place",
          "if",
          "opinion",
          "summary",
          "unless",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `  Write an opinion passage on the question in the box.

`,
        left: 50,
        star: 3,
        color: "#00aa4c",
        colorStar: "#a0d4ae",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    character: ",",
    // KeyMau: true,
    // isHello: true,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        
        <div style='margin-top:20px;position:relative'>
          <img src='/img/FriendsPlus/Page37/10.jpg' style='width:600px;margin-left:100px' />
          <br> <br> 
          <textarea rows=10 id=0 ></textarea>
          


        </div>
      
        `,
        answer: [""],
      },
    ],
  },
};

export default json;

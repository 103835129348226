import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit1",
    id: "SW9-2024-U1-P9-E1",
    exerciseKey: "img/FriendsPlus/Page9/Key/E1answerKey.png",
    audio: "",
    video: "",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 267,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "calculator",
          "games console",
          "HD TV",
          "<s>high-speed broadband</s>",
          "satellite TV",
          "spellchecker",
          "video calling",
          "VR headset",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct words to complete
the table.`,
        color: "#1dbfd6",
        left: -50,
        star: 1,
        colorStar: "#b4e2ef",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // character: ",",
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "2px dotted",
          textAlign: "center",
          width: 200,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          // padding: "1px 10px",
          fontWeight: "bold",
          borderRadius: "50%",
          // color: "#00aeef",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "had / has",
          "remember / remembered",
          "didn’t / hadn’t",
          "forgotten / forget",
          "Had / Has",
          "spend / spent",
          "have / had",
          "hadn’t / hasn’t",
          "have / had",
        ],
        answers: [
          "0-0",
          "2-4",
          "3-0",
          "4-0",
          "5-4",
          "6-4",
          "7-0",
          "8-4",
          "1-4",
        ],
        initialValue: ["0-0"],
      },
      Layout: `
       <div style='position:relative'>
        <div style='width:40mm;position:absolute;top:106px;left:36px'>I / You / He / She / It / We / You / They </div>
        <div style='width:52mm;position:absolute;top:106px;left:490px'>the name of the village.</div>
        <div style='width:52mm;position:absolute;top:264px;left:490px'>everythingabout the past.</div>
        <div style='width:40mm;position:absolute;top:264px;left:36px'>I / You / He / She / It / We / You / They </div>
        <div style='width:49mm;position:absolute;top:429px;left:36px'><sup>4</sup><input id=4 type=Circle /> he</div>
        <div style='width:55mm;position:absolute;top:429px;left:238px'><sup>5</sup><input id=5 type=Circle /> long time looking for the place?</div>
        <div style='width:55mm;position:absolute;top:429px;left:490px'>Yes, he <sup>6</sup><input id=6 type=Circle /> <br> No, he <sup>7</sup><input id=7 type=Circle /></div>
        <div style='width:53mm;position:absolute;top:106px;left:238px'><input id=0 type=Circle /> <sup>1</sup><input id=1 type=Circle /></div>
        <div style='width:61mm;position:absolute;top:264px;left:238px'><sup>2</sup><input id=2 type=Circle /> <sup>3</sup><input id=3 type=Circle /></div>
        <div style='width:61mm;position:absolute;top:588px;left:36px'>What</div>
        <div style='width:61mm;position:absolute;top:588px;left:238px'><sup>8</sup><input id=8 type=Circle /> happened to all the people in the village?</div>
        <div style='width:61mm;position:absolute;top:588px;left:490px'>They had gone.</div>
        <img src="img/FriendsPlus/Page9/E1/1.jpg" style='height:20cm'/>
      </div>
      `,
    },
  },
  2: {
    unit: "Unit1",
    id: "SW9-2024-U1-P9-E2",
    exerciseKey: "img/FriendsPlus/Page9/Key/E2answerKey.png",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences using the past
perfect. Use the affirmative, negative or question
form of the verbs.`,
        color: "#1dbfd6",
        width: "27cm",
        left: 50,
        star: 2,
        colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "break",
          "do",
          "finish",
          "live",
          "<s>leave</s>",
          "not find",
          "read",
          "see",
          "not try",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 200,
      paddingTop: 10,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        Ali missed the flight because the plane <u style='text-decoration-style: dotted'>&emsp;had&emsp;</u> already <u style='text-decoration-style: dotted'>&emsp;left&emsp;</u>
        <div style='width:26cm'>
          <b>1</b>&ensp;We recognised the woman in the photo because she # next door to us.<br>
          <b>2</b>&ensp;We decided not to buy that book for Dad. We thought he # it before.<br>
          <b>3</b>&ensp;By lunchtime, I # the key anywhere.<br>
          <b>4</b>&ensp;After we # the homework, we played the game.<br>
          <b>5</b>&ensp;Tereza looked bored in the cinema. # she # the film before?<br>
          <b>6</b>&ensp;My grandparents liked the new restaurant. They # Indian food before.<br>
          <b>7</b>&ensp;Meryem couldn’t play basketball with us because she # her arm.<br>
          <b>8</b>&ensp;The teacher was very angry with that boy. What # he # wrong?<br>
          
        </div>
        
        `,
        answer: [
          "had lived",
          "had read",
          "hadn't found",
          "had finished",
          "Had",
          "seen",
          "hadn't tried",
          "had broken",
          "had",
          "done",
        ],
      },
    ],
  },

  3: {
    unit: "Unit1",
    id: "SW9-2024-U1-P9-E3",
    exerciseKey: "img/FriendsPlus/Page9/Key/E3answerKey.png",
    audio: "",
    video: "",
    // textAlign:'center',
    stylesTextInput: {
      background: "none",
      // borderBottom: "2px dotted",
      borderBottom: "none",
      width: 240,
      // padding: 0,
      fontSize: 23,
      paddingTop: 10,
      textAlign: "center",
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    hintBox: [
      {
        src: [
          "awesome places to visit",
          "big hotels",
          "cool shops and markets",
          "fun entertainment",
          "historic buildings",
          "nice parks to walk in",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the text using the past simple or
past perfect form of the verbs in brackets.`,
        width: "27cm",
        color: "#1dbfd6",
        left: 50,
        star: 2,
        colorStar: "#b4e2ef",
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        

      <div style='position:relative'>
        <div style='position:absolute;top:281px;left:305px'>#</div>
        <div style='position:absolute;top:318px;left:344px'>#</div>
        <div style='position:absolute;top:351px;left:391px'>#</div>
        <div style='position:absolute;top:466px;left:42px'>#</div>
        <div style='position:absolute;top:580px;left:42px'>#</div>
        <div style='position:absolute;top:776px;left:42px'>#</div>
        
        <img src="img/FriendsPlus/Page9/E3/1.jpg" style='height:25cm'/>
      </div>
          
        `,
        answer: [
          "felt",
          "hadn't been",
          "had always lived",
          "decided",
          "arrived",
          "had left",
        ],
      },
    ],
  },
  4: {
    unit: "Unit1",
    id: "SW9-2024-U1-P9-E4",
    exerciseKey: "img/FriendsPlus/Page9/Key/E4answerKey.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",
    // isHello:true,
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 200,
      // marginLeft: 20,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: {
      width: 700,
      resize: "none",
      paddingTop: 3,
      marginBottom: -23,
      marginLeft: 17,
      marginTop: -3,
    },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["finish", "go", "lose", "not eat", "not fly", "not see"],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `Read and complete the sentences with the
past perfect form of the verbs and your own ideas.
`,
        color: "#1dbfd6",
        width: "27cm",
        left: 50,
        star: 3,
        colorStar: "#b4e2ef",
      },
    ],
    // KeyMau: true,
    // isHello: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        I went to my cousin’s house, but she wasn’t there. <br>
        She <u style='text-decoration-style: dotted'>had gone to the park.&emsp;&emsp;&emsp;&emsp;&emsp;</u>
        <div style='display:flex'>
            <b>1</b> 
            <div style='margin-left:15px'>
              We were very hungry when we arrived at the hotel.<br>
              We <input width=496px />.
            </div>
          </div>
          <div style='display:flex'>
            <b>2</b> 
            <div style='margin-left:15px'>
              My neighbour felt nervous about going on a plane for the first time.<br>
              He <input width=500px />.
            </div>
          </div>
          <div style='display:flex'>
            <b>3</b> 
            <div style='margin-left:15px'>
              I couldn’t call my friends after school yesterday.<br>
              I <input width=524px />.
            </div>
          </div>
          <div style='display:flex'>
            <b>4</b> 
            <div style='margin-left:15px'>
              They were happy to see their friends again after such a long time.<br>
              They <input width=475px />.
            </div>
          </div>
          <div style='display:flex'>
            <b>5</b> 
            <div style='margin-left:15px'>
              She arrived too late for the meeting.<br>
              It <input width=514px />.
            </div>
          </div>





          
        `,
        answer: [
          "hadn't eaten all day",
          "hadn't flown before",
          "had lost my phone",
          "hadn't seen them for two years",
          "had finished five minutes earlier",
        ],
      },
    ],
  },
  5: {
    unit: "Unit1",
    id: "SW9-2024-U1-P9-E5",
    exerciseKey: "img/FriendsPlus/Page9/Key/E5answerKey.png",
    audio: "",
    video: "",
    component: T6,
    // textAlign:'center',
    stylesTextInput: {
      borderBottom: "2px dotted",
      width: 850,
      background: "none",
      paddingTop: 10,
    },
    textareaStyle: { width: 940, resize: "none", paddingTop: 3 },
    styleHint: {
      borderColor: "#1dbfd6",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "brother(s) / sister(s)",
          "live in …",
          "name",
          "used to play / go / like",
        ],
        width: 600,
        borderColor: "#1dbfd6",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `You meet a friend who you haven’t seen
for a long time. Write four or five questions using
question tags. Use the phrases in the box and
exercise 4 for ideas.`,
        color: "#1dbfd6",
        left: 50,
        star: 3,
        colorStar: "#b4e2ef",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <textarea id=0 rows=5></textarea>
        `,
        initialValue: ["Hey, it's Derin, isn't it?"],
        answer: [
          "Can you tell me why to consider studying abroad for a term?",
          "Do you know who to approach for guidance on writing a good essay?",
          "Can you recommend where to celebrate our year-end party next week?",
          "Can you tell me who to ask for permission before using the hall?",
        ],
      },
    ],
  },
};

export default json;

import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "LFP-Starter Unit 1",
    id: "SW9-2024-LFP-Starter-U1-P61-E1",
    exerciseKey: "/img/FriendsPlus/Page61/key/e1-key.jpg",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 20,
      fontWeight: "bold",
      width: 700,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#71699c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "trust",
          "invest",
          "book",
          "ask",
          "go",
          "pursue",
          "cook",
          "<s>start</s>",
          "wear",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: ` Complete the sentences using the correct form of the past perfect.

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                    <span>We knew him. (we / meet / him / twice before) 
                     <b><span style='border-bottom:2px dotted;'> We had met him twice before</span></b>
                     </span>
                      
                </div>
                <p></p>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>1</span>
                    I was happy when they gave me that ring. (it / belong to / my aunt) <br>
                     <input id=0  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>2</span>
                   Eva was worried. (she / not do / the work) <br>
                     <input id=1 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>3</span>
                   The field was dry. (it / not rain / for weeks) <br>
                     <input id=2  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>4</span>
                   Ben’s phone was broken. (he / drop / it again) <br>
                     <input id=3  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>5</span>
                   We were unhappy. (we / lose / the match) <br>
                     <input id=4  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>6</span>
                   I got there late. (I / fall asleep / at home) <br>
                     <input id=5 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>7</span>
                   Sophia was excited. (what / she / find / ?) <br>
                     <input id=6 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>8</span>
                   We were worried. (they / get lost / ?) <br>
                     <input id=7 />
                </div>

        </div>
      
        `,
        answer: [
          "It had belonged to my aunt",
          "She hadn't done the work.",
          "It hadn't rained for weeks.",
          "He had dropped it again.",
          "We had lost the match.",
          "I had fallen asleep at home.",
          "What had she found?",
          "Had they got lost?",
        ],
      },
    ],
  },
  2: {
    unit: "LFP-Starter Unit 1",
    id: "SW9-2024-LFP-Starter-U1-P61-E2",
    exerciseKey: "/img/FriendsPlus/Page61/key/e2-key.jpg",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 20,
      fontWeight: "bold",
      width: 170,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#71699c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "drink",
          "not have",
          "play",
          "hear",
          "leave",
          "<s>not ride</s>",
          "see",
          "not speak",
          "not visit",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: ` Complete the sentences using the correct past
        perfect or past simple form of the verbs in the box.

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
       <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                    <span>My brothers looked nervous because they
                     <b><span style='border-bottom:2px dotted;'> hadn’t ridden</span></b>
                      on a roller coaster before.
                     </span>
                </div>
                <p></p>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>1</span>
                    Cara ate some of her sandwiches and then she
                     <input id=0  />
                    the coffee
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>2</span>
                   I knew that song because I
                     <input id=1 />
                    it last week.
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>3</span>
                   It was nice to meet Igor yesterday. I
                     <input id=2  />
                    to him before
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>4</span>
                   They had studied hard for the exam, so they
                     <input id=3  />
                    any problems with the questions.
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>5</span>
                   When we arrived at the bus station, the bus
                     <input id=4  />
                     two minutes earlier.
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>6</span>
                   First we went for a swim, and after that we
                     <input id=5 />
                     tennis with our friends
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>7</span>
                   I enjoyed going to Rome on holiday. I 
                     <input id=6 />
                     Italy before.
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>8</span>
                   He opened the door and
                     <input id=7 />
                    the postman holding out a letter.
                </div>

        </div>
      
        `,
        answer: [
          "drank",
          "'d heard",
          "hadn't spoken",
          "didn't have",
          "had left",
          "played",
          "hadn't visited",
          "saw",
        ],
      },
    ],
  },
  3: {
    unit: "LFP-Starter Unit 1",
    id: "SW9-2024-LFP-Starter-U1-P61-E3",
    exerciseKey: "/img/FriendsPlus/Page61/key/e3-key.jpg",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 20,
      fontWeight: "bold",
      width: 170,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#71699c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "drink",
          "not have",
          "play",
          "hear",
          "leave",
          "<s>not ride</s>",
          "see",
          "not speak",
          "not visit",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: ` Correct the tenses in bold using the past perfect,
        past simple or past continuous.

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                    <span>I was walking along when I <b>was finding</b> some
                    money on the ground
                     <b><span style='border-bottom:2px dotted;'> found</span></b></span>
                </div>
                <p></p>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>1</span>
                    My cousin <b>didn’t climb</b> a tree before
                     <input id=0  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>2</span>
                   Ali phoned while we <b>had eaten</b>
                     <input id=1 />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>3</span>
                   We <b>had run</b> to the bus stop
                     <input id=2  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>4</span>
                   Selin was very good at cooking on the barbecue. She <b>used</b> it many times before
                     <input id=3  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>5</span>
                   <b>Were</b> you <b>seeing</b>  the accident earlier?
                     <input id=4  />
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>6</span>
                   Jake took a photo of me while I <b>hadn’t smiled.</b>
                     <input id=5 />
                </div>

                

        </div>
      
        `,
        answer: [
          "hadn't climbed",
          "were eating",
          "ran",
          "had used",
          "Did you see",
          "wasn't smiling",
        ],
      },
    ],
  },
  4: {
    unit: "LFP-Starter Unit 1",
    id: "SW9-2024-LFP-Starter-U1-P61-E4",
    exerciseKey: "/img/FriendsPlus/Page61/key/e4-key.jpg",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      // borderBottom: "2px dotted",
      fontSize: 18,
      fontWeight: "bold",
      width: 200,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#71699c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "drink",
          "not have",
          "play",
          "hear",
          "leave",
          "<s>not ride</s>",
          "see",
          "not speak",
          "not visit",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: ` Complete the message with the correct past
        narrative tenses of the verbs in brackets.

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
       <div style='margin-top:20px;position:relative'>
          <img src='/img/FriendsPlus/Page61/11.jpg' style='width:600px;margin-left:200px' />
          <div style='position:absolute;top:80px;left:331px' > <input id=0 /> </div>
          <div style='position:absolute;top:108px;left:365px' > <input id=1 /> </div>
          <div style='position:absolute;top:136px;left:187px' > <input id=2 /> </div>
          <div style='position:absolute;top:163px;left:191px' > <input id=3 /> </div>
          <div style='position:absolute;top:197px;left:288px' > <input id=4 /> </div>
          <div style='position:absolute;top:225px;left:177px' > <input id=5 /> </div>
          <div style='position:absolute;top:252px;left:207px' > <input id=6 /> </div>
          <div style='position:absolute;top:280px;left:213px' > <input id=7 /> </div>
          <div style='position:absolute;top:307px;left:180px' > <input id=8 /> </div>
          <div style='position:absolute;top:335px;left:180px' > <input id=9 /> </div>
          <div style='position:absolute;top:364px;left:338px' > <input id=10 /> </div>
          
        </div>
      
        `,
        answer: [
          "came across",
          "were",
          "'d been",
          "had opened",
          "looked",
          "found",
          "didn't recognise",
          "was looking",
          "came",
          "said",
          "moved",
        ],
      },
    ],
  },
  5: {
    unit: "LFP-Starter Unit 1",
    id: "SW9-2024-LFP-Starter-U1-P61-E5",
    exerciseKey: "/img/FriendsPlus/Page61/key/e5-key.jpg",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      fontSize: 20,
      fontWeight: "bold",
      width: 170,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#71699c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "as",
          "before",
          "end",
          "finally",
          "meanwhile",
          "that",
          "time",
          "until",
          "<s>while</s>",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: ` Complete the sentences with the words in the box.
        There are two extra words. 

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
       <div style='margin-top:20px;'>
                <div style='margin-top:20px;'>
                    <span>Jessie answered the phone
                    <b><span style='border-bottom:2px dotted;'> while</span></b></span>
                     her mum was driving.
                     
                </div>
                <p></p>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>1</span>
                    The moment
                    <input id=0  />
                    I left school it started raining. By the <input id=1  />
                    I got home, I was wet.
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>2</span>
                    They’d read the book
                    <input id=2  />
                    they went to see the film, so they understood the story.
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>3</span>
                    We were swimming in the sea, and
                    <input id=3  />
                    my friend was taking photos.
                </div>

                <div style='margin-top:20px;'>
                    <span style='font-weight:bold;margin-right:10px'>4</span>
                    I went to the gym but just
                    <input id=4  />
                    I arrived it started to close. In the
                    <input id=5  />
                     I went home.
                </div>

                

                

        </div>
      
        `,
        answer: ["that", "time", "before", "meanwhile", "as", "end"],
      },
    ],
  },
};

export default json;

import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "LFP-Starter Unit 3",
    id: "SW9-2024-LF-U3-P63-E1",
    exerciseKey: "/img/FriendsPlus/Page63/Key/E1answerKey.jpg",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontWeight: "bold",
      width: 250,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#71699c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<s>break</s>", "buy", "join", "lose", "play", "read", "wait"],
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: ` Complete the sentences with <i>as / since / because (of).</i>

`,
        left: 50,
        star: 0,
        color: "#71699c",
        colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
        <div style='margin-top:10px;'>
        
        My mother was very angry <u style='text-decoration-style: dotted'>because / as /since&emsp;&emsp;</u> Tommy behaved rudely.<br> 
          <b>1</b>&ensp;#her impatience, Tina will never be successful in her career.<br>
          <b>2</b>&ensp;She came in very quickly #she didn't want to wake her baby up.<br>
          <b>3</b>&ensp;He got bad marks this exam #his incomplete answers.<br>
          <b>4</b>&ensp;#Mr Tuấn stayed up too late last night, he forgot his meeting this morning.<br>
          <b>5</b>&ensp;#the heavy fog, they cancelled the football match yesterday. <br>


        </div>
      
        `,
        answer: [
          "because of",
          "because / as / since",
          "because of",
          "Because / As / Since",
          "Because of",
        ],
      },
    ],
  },
  2: {
    unit: "LFP-Starter Unit 3",
    id: "SW9-2024-LF-U3-P63-E2",
    exerciseKey: "/img/FriendsPlus/Page63/Key/E2answerKey.jpg",
    audio: "",
    video: "",
    component: T6,
    // textAlign: "center",

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 20,
      // fontWeight: "bold",
      width: 270,
      paddingTop: 10,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#71699c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<s>break</s>", "buy", "join", "lose", "play", "read", "wait"],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: ` Rewrite the sentences.`,
        left: 50,
        // star: 0,
        color: "#71699c",
        // colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    character: "/",
    questions: [
      {
        title: `
  
        We missed the train so we came to the party late.<br>
        <u style='text-decoration-style: dotted'>As we missed the train, we came to the party late.&emsp;&emsp;&emsp;&emsp;&emsp;</u>
        <div style='display:flex'>
            <b>1</b> 
            <div style='margin-left:15px'>
              Mai was absent from school so she didn't understand the lessons.<br>
              Because of<input width=736px />.
            </div>
          </div>
          <div style='display:flex'>
            <b>2</b> 
            <div style='margin-left:15px'>
              My mother couldn't sleep last night because she had an awful headache.<br>
              My mother couldn't sleep last night because of<input width=337px />.
            </div>
          </div>
          <div style='display:flex'>
            <b>3</b> 
            <div style='margin-left:15px'>
              My bike broke out so I had to take the bus to work.<br>
              Since<input width=800px />.
            </div>
          </div>
          <div style='display:flex'>
            <b>4</b> 
            <div style='margin-left:15px'>
              Nam walked so slowly as his leg was hurt.<br>
              Nam walked so slowly because of<input width=484px />.
            </div>
          </div>
          <div style='display:flex'>
            <b>5</b> 
            <div style='margin-left:15px'>
              Since the snow was so heavy, I couldn't go to school on time.<br>
              Because of<input width=735px />.
            </div>
          </div>





          
        `,
        answer: [
          "Mai's absence from school, she didn't understand the lessons.",
          "her awful headache.",
          "my bike broke out, I had to take the bus to work.",
          "his hurt leg.",
          "the heavy snow, I couldn't go to school on time.",
        ],
      },
    ],
  },
  3: {
    unit: "LFP-Starter Unit 3",
    id: "SW9-2024-LF-U3-P63-E3",
    exerciseKey: "/img/FriendsPlus/Page63/Key/E3answerKey.jpg",
    audio: "",
    video: "",

    stylesTextInput: {
      // textAlign: "center",
      background: "none",
      // borderBottom: "none",
      borderBottom: "2px dotted",
      // fontSize: 20,
      // fontWeight: "bold",
      width: 600,
      paddingTop: 10,
      marginLeft: 20,
      // maxLength: 1,
    },
    textareaStyle: {
      width: 200,
      resize: "none",
      paddingTop: 3,
    },
    styleHint: {
      borderColor: "#71699c",
      justifyContent: "center",
      width: "16cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<s>break</s>", "buy", "join", "lose", "play", "read", "wait"],
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Write affirmative (+) or negative (–) sentences, or
questions (?).
`,
        left: 50,
        star: 0,
        color: "#71699c",
        // colorStar: "#71699c",
      },
    ],

    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      marginRight: 10,
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,

    selectOptionValues: ["A", "B", "C", "D", "E", "F"],
    // hideBtnFooter: true,
    // KeyMau: true,
    // isHello: true,
    component: T6,
    questions: [
      {
        title: `

        We’ll come to the shopping centre with you. (–)<br>
        <u style='text-decoration-style: dotted'>We won’t come to the shopping centre with you.&emsp;&emsp;</u><br>
        <b>1</b>&ensp;Lily isn’t going to buy those trainers. (?)<br>#<br>
        <b>2</b>&ensp;Danny is not getting married next month. (+)<br>#<br>
        <b>3</b>&ensp;They’ll enjoy that new film. (–)<br>#<br>
        <b>4</b>&ensp;There is a maths exam at 9.00 next Friday. (–)<br>#<br>
        <b>5</b>&ensp;Is John visiting his family this Saturday? (–)<br>#<br>
        <b>6</b>&ensp;The bus arrives in Manchester at 5.30. (?)<br>#<br>
        
        `,
        answer: [
          "Is Lily going to buy those trainers?",
          "Danny is getting married next month.",
          "They won't enjoy that new film.",
          "There isn't a Math exam at 9 a.m. next Friday. | There isn't a Math exam at 9.00 next Friday.",
          "John isn't visiting his family this Saturday.",
          "Does the bus arrive in Manchester at 5.30?",
        ],
      },
    ],
  },
  4: {
    unit: "LFP-Starter Unit 3",
    id: "SW9-2024-LF-U3-P63-E2",
    exerciseKey: "img/FriendsPlus/Page63/Key/E4answerKey.jpg",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences with will or going to and the
verbs in the box.`,
        color: "#4E3E94",
        width: "27cm",
        left: 50,
        // star: 2,
        // colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#4D3C98",
      justifyContent: "center",
      width: "11cm",
      marginBottom: 10,
      marginLeft: 200,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<s>help</s>",
          "not like",
          "live",
          "phone",
          "plan",
          "start",
          "not watch",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 300,
      paddingTop: 10,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width:27cm'>
        ‘I can’t open this door.’  ‘We’ <u style='text-decoration-style: dotted'>&emsp;ll help&emsp;</u> you.’ <br>
        <b>1</b>&ensp;Rosa has decided to learn to play the flute. She # next week.<br>
        <b>2</b>&ensp;Don’t buy that perfume for your mum. She definitely # it.<br>
        <b>3</b>&ensp;‘I feel very ill. I # the doctor.’<br>
        <b>4</b>&ensp;We # a holiday today.<br>
        <b>5</b>&ensp;I think most people # in cities in the year 2050.<br>
        <b>6</b>&ensp;David has changed his mind about the cinema. He # that film later.<br>
        </div>
        
        `,
        answer: [
          "is going to start|'s going to start",
          "will not line|won't like",
          "'ll phone|will phone",
          "'re going to plan|are going to plan",
          "will live",
          "isn't going to watch|is not going to watch",
        ],
      },
    ],
  },
  5: {
    unit: "LFP-Starter Unit 3",
    id: "SW9-2024-LF-U3-P63-E2",
    exerciseKey: "img/FriendsPlus/Page63/Key/E5answerKey.jpg",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences with the present or present
continuous form of the verbs in brackets.`,
        color: "#4E3E94",
        width: "27cm",
        left: 50,
        // star: 2,
        // colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#6fd7e7",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "break",
          "do",
          "finish",
          "live",
          "<s>leave</s>",
          "not find",
          "read",
          "see",
          "not try",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      width: 200,
      paddingTop: 10,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
   

        Peter <u style='text-decoration-style: dotted'>’s cooking&emsp;&emsp;</u> dinner this evening. (cook) <br>
        <b>1</b>&ensp;The show #(start) at 7.30.<br>
        <b>2</b>&ensp;I  #(meet) Ann at 12.30 today.<br>
        <b>3</b>&ensp;Our train #(leave) at 2.00.<br>
        <b>4</b>&ensp;What time #(you / come) to my house this evening?<br>
        <b>5</b>&ensp;What #(you / do) next weekend?<br>
        
        
        `,
        answer: [
          "starts",
          "'m meeting|am meeting",
          "leaves",
          "are you coming",
          "are you doing",
        ],
      },
    ],
  },
  6: {
    unit: "LFP-Starter Unit 3",
    id: "SW9-2024-LF-U3-P63-E6",
    exerciseKey: "img/FriendsPlus/Page63/Key/E6answerKey.jpg",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "6",
        title: `The bold words are in the wrong place in the
sentences. Correct the sentences.`,
        color: "#4E3E94",
        width: "27cm",
        left: 50,
        // star: 2,
        // colorStar: "#b4e2ef",
      },
    ],
    styleHint: {
      borderColor: "#6fd7e7",
      justifyContent: "center",
      width: "13cm",
      marginBottom: 10,
      styleElementHintBox: {
        // color: "#0067b4",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "break",
          "do",
          "finish",
          "live",
          "<s>leave</s>",
          "not find",
          "read",
          "see",
          "not try",
        ],
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      // borderBottom: "none",
      marginLeft: 20,
      width: 650,
      paddingTop: 10,
      // textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
   
        These products are useful and attractive <span style='text-decoration:line-through'>both</span> .<br>
        <u style='text-decoration-style: dotted'>These products are both useful and attractive.&emsp;&emsp;</u><br>
        <b>1</b>&ensp;This car isn’t only great but convenient <b>also</b>.<br>#<br>
        <b>2</b>&ensp;I bought a new coat a pair of jeans <b>as well as</b>.<br>#<br>
        <b>3</b>&ensp;This jacket is available <b>both</b> in blue and red.<br>#<br>
        <b>4</b>&ensp;I went to the shops and played <b>too</b> football.<br>#<br>
        <b>5</b>&ensp;This hat is not cheap <b>only</b> but also practical.<br>#<br>
        <b>6</b>&ensp;She often buys <b>as well</b> shoes <b>as</b> bags.<br>#<br>
        
        
        `,
        answer: [
          "This car isn't only great but also convenient.",
          "I bought a new coat as well as a pair of jeans.",
          "This jacket is available in both blue and red.",
          "I went to the shops and played football, too.",
          "This hat is not only cheap but also practical.",
          "She often buys shoes as well as bags.",
        ],
      },
    ],
  },
};

export default json;
